import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Popper, Stack } from "@mui/material"
import { useEffect, useState } from "react"

const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const formControlLabelStyle = {
    margin: '0px',
    width: '100%',

    '& .MuiTypography-root': {
        width: '100%', textAlign: 'center',
        fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
        userSelect: 'none',
    },

    padding: '10px 12px', height: '36px',
    boxShadow: 'none', textTransform: 'none',
    backgroundColor: '#FFFFFF', color: '#8E8E8E',
    borderRadius: '4px', border: '2px solid #BDBDBD',
    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
}


interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const InspectTypeFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [inspectType, setInspectType] = useState<any>({ 
        'Предрейсовый': false, 'Послерейсовый': false, 'Предсменный': false, 'Послесменный': false, 'Внутридневной': false 
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInspectType(Object.entries(inspectType).forEach(([key]) => {inspectType[key] = false}))
        setInspectType({...inspectType, [event.target.name]: event.target.checked})
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(inspectType).filter((key) => inspectType[key] === true)
        console.log(resultArray)
        
        setFilterRequest((prev:any) => ({...prev, type: { name: resultArray[0] }}))
        setMode(true)
        handleClose()
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.type
            return state
        })
        setMode(false)
        handleClose()
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{alignItems: 'center', gap: '8px', margin: '4px 0px'}}>
                    {Object.keys(inspectType).map((key, index) => (
                        <FormControlLabel key={key}
                            label={key}
                            control={
                                <Checkbox 
                                    sx={{ display: 'none' }}
                                    checked={inspectType[key]} onChange={handleChange} name={key}
                                />
                            }
                            sx={[formControlLabelStyle, 
                                inspectType[key] && {
                                    backgroundColor: '#009FDA',
                                    borderColor: '#009FDA',
                                    color: '#FFFFFF',
                                    "&:hover": { backgroundColor: '#007DB8', borderColor: '#007DB8' },
                                }
                            ]}
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}