export const SearchIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8704_56062)">
            <circle cx="5.25" cy="5.25" r="4.5" stroke="#8E8E8E" stroke-width="1.5"/>
            <path d="M12.303 13.3629C12.5959 13.6558 13.0708 13.6557 13.3636 13.3628C13.6565 13.0699 13.6564 12.595 13.3635 12.3021L12.303 13.3629ZM7.96949 9.03039L12.303 13.3629L13.3635 12.3021L9.03003 7.96961L7.96949 9.03039Z" fill="#8E8E8E"/>
        </g>
            <defs>
                <clipPath id="clip0_8704_56062">
                    <rect width="14" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}