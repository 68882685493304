
import React, {FC} from "react";

import {RegPhoneMailFormProps, RegStages} from "../../reg-types";

import {TabContext, TabList, TabPanel} from "@mui/lab";
import {IconButton, InputAdornment, Tab, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import classes from "./form.module.css"
import { authTabStyle, tabPanelStyle, textFieldStyle } from "../../../../login-styles";
import { tabStyle } from '../../../../../../styles/MaterialCustomStyles';



export const RegPhoneMailForm:FC<RegPhoneMailFormProps>=(
    {
        action,
        handleTabChange,
        handlePhoneChange,
        handleMailChange,
        currentTabValue,
        phone,
        isErrorHidden,
        error,
        toggleErrors
    })=>{

    return(
        <div style={{
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
            maxWidth:"364px",
        }}>

            <TabContext value={currentTabValue}>


                <TabList
                    onChange={handleTabChange}>

                    <Tab
                        sx={authTabStyle}
                        label="Номер телефона" value="phone"/>

                    <Tab
                        sx={authTabStyle}
                        label="Электронная почта" value="email"/>

                </TabList>


                <TabPanel sx={{padding: '24px 0px 24px 0px',}} value="email">

                    <TextField
                        id="standard-helperText"
                        label="Электронная почта"
                        placeholder='Электронная почта'
                        variant='filled'
                        sx={
                            {
                                ...textFieldStyle,
                                backgroundColor: isErrorHidden ? 'white' : '#FFBBBB',
                            }}
                        InputProps={
                            {
                                disableUnderline: true,
                            }
                        }
                        InputLabelProps={{ shrink: false }}

                        onChange={handleMailChange}

                    />
                </TabPanel>

                <TabPanel sx={{padding: '24px 0px 24px 0px',}}
                    value="phone">
                    <TextField
                        id="standard-helperText"
                        label="Телефон"
                        variant='filled'
                        sx={
                            {
                                ...textFieldStyle,
                                backgroundColor: isErrorHidden ? 'white' : '#FFBBBB',
                            }}
                        value={phone}
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                                disableUnderline: true,
                            }}
                        InputLabelProps={{ shrink: false }}

                        onChange={handlePhoneChange}

                    />
                </TabPanel>
            </TabContext>
            {(isErrorHidden) ? null : <p onClick={()=>{toggleErrors()}}  className={classes.error}>{error}</p>}
                <Button
                    fullWidth={true}

                    sx={{
                        color: 'white',
                        textTransform: 'none',
                        fontSize: 16,
                        fontWeight: 700,
                        height: 48,
                        backgroundColor: '#1890FF'
                    }}
                    onClick={() =>{action()}}
                    variant='contained'>Зарегистрироваться</Button>

        </div>
    )
}