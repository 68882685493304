import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, Divider, IconButton, Stack, SwipeableDrawer, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { DocumentAddingForm } from "../../drawers/DocumentAddingForm"
import { DOC_STATUSES, DOC_TYPES, IDocument } from "../../../../../types/documentTypes"
import { $api, $file } from "../../../../../api"
import { useAppDispatch } from "../../../../../store/hooks/redux";
import { setCurrentDocumentType, setFileLocalUrls, setFormFiles } from "../../../DocumentsSlice";
import { getDocumentStatusChip, } from "../../../utils";



interface AccreditationCardProps {
    cardDocument?: IDocument | null,
    isRedacted: boolean
}

export const AccreditationCertificateCard = ({ cardDocument, isRedacted }: AccreditationCardProps) => {

    const dispatch = useAppDispatch();
    const [files, setFiles] = useState<any[]>([])
    const [fileLocalUrls, setFileLocalUrl] = useState<any[]>([])
    console.log(cardDocument)

    const getFiles = async (urls: string[]) => {
        const files = await Promise.all(urls.map((url: string) => {
            return $file.get(url)
        }))
        console.log('async files')
        console.log(files)
        return files;
    }

    useEffect(() => {
        console.log('async files')
        console.log(files)
    }, [files])


    useEffect(() => {
        console.log('async file urls')
        console.log(fileLocalUrls)
    }, [fileLocalUrls])

    useEffect(() => {

        console.log('cardDocument')
        console.log(cardDocument)
        if (!!cardDocument) {

            const filesResult = getFiles(cardDocument.url);
            filesResult.then((response) => {
                console.log(response)
                let newFileLocalUrls: any = [];
                setFiles(response.map((url: any) => {
                    const fileUrl = URL.createObjectURL(url.data)
                    newFileLocalUrls.push(fileUrl)
                    console.log(fileUrl)
                    const fileObj = new File([url], "photo");
                    return fileObj
                }))
                setFileLocalUrl((state: any) => state = newFileLocalUrls)
            })
        }
    }, [cardDocument])

    return (
        <Card sx={{ backgroundColor: isRedacted ? '#D3F8D2' : '#EEEEEE', /*width: '540px'*/ minHeight: '120px', maxHeight: '415px', boxShadow: 'none' }}>
            <CardContent sx={{ padding: 0 }}>
                <Box display={'flex'} padding={'16px'}>
                    <Typography fontSize={'16px'} fontWeight={500}>
                        Аккредитация специалиста
                    </Typography>
                    <Box marginLeft={1}>
                        {
                            !!cardDocument

                                ? getDocumentStatusChip(cardDocument.document_status)

                                : getDocumentStatusChip(DOC_STATUSES.MISSED)
                        }

                    </Box>
                </Box>
                {cardDocument && <Divider></Divider>}
                {cardDocument &&
                    <Stack sx={{ padding: '16px' }} gap={2} width={'100%'}>
                        {
                            cardDocument?.registry_number ? <Box>
                                <Typography fontSize={'16px'}>Серийный номер</Typography>
                                <Typography fontSize={'16px'} fontWeight={500}>{cardDocument?.registry_number}</Typography>
                            </Box> : null
                        }
                        {
                            cardDocument?.issued_at ? <Box>
                                <Typography fontSize={'16px'}>Дата выдачи</Typography>
                                <Typography fontSize={'16px'} fontWeight={500}>{new Date(cardDocument?.issued_at).toLocaleDateString('ru-RU')}</Typography>
                            </Box> : null
                        }
                        {
                            cardDocument?.validity_period ? <Box>
                                <Typography fontSize={'16px'}>Срок действия</Typography>
                                <Typography fontSize={'16px'} fontWeight={500}>{cardDocument?.validity_period} года</Typography>
                            </Box> : null
                        }
                        {
                            cardDocument?.url ? <Box>
                                <Typography fontSize={'16px'}>Прикрепленные файлы</Typography>
                                <Box sx={{ padding: '10px 12px 10px 12px', marginTop: '8px', width: 'fit-content', borderRadius: '4px', backgroundColor: '#D9D9D9' }}>
                                    <Typography sx={{ fontWeight: 500 }} fontSize={'14px'}>Фотокопия ({cardDocument.url.length} шт.) </Typography>
                                </Box>

                                {/* {fileLocalUrls.map((url: any) => {
                                return <Typography fontSize={'16px'} fontWeight={500}>
                                    <a download={'file.png'} href={url} onClick={async (ev) => {
                                        //   URL.revokeObjectURL(url);
                                    }}>Файл</a>
                                </Typography>
                            })} */}

                            </Box> : null
                        }
                    </Stack>
                }

            </CardContent>
            <Divider></Divider>
            <CardActions sx={{ justifyContent: 'space-between', padding: '16px' }}>
                <Box>
                    {
                        cardDocument?.comment
                            ? <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '16px',
                                    letterSpacing: '0px',
                                    textAlign: 'left',
                                    maxWidth: '400px',
                                    color: '#DC4C4C'
                                }}
                            >
                                {cardDocument?.comment}
                            </Typography>
                            : <></>
                    }
                </Box>
                <Button
                    sx={{
                        padding: '10px 12px',
                        textTransform: 'none',
                        backgroundColor: '#009FDA',
                        borderRadius: '4px',
                        color: 'white',

                        fontSize: '14px',
                        fontWeight: 500,
                        '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' }, boxShadow: 'none',
                        height: '32px', lineHeight: '16px'
                    }}
                    disabled={cardDocument?.document_status === DOC_STATUSES.CHECKING}
                    onClick={() => {
                        dispatch(setCurrentDocumentType(DOC_TYPES.ACCREDIATAION_CERTIFICATE))
                        if (files.length > 0) dispatch(setFormFiles(files))
                        dispatch(setFileLocalUrls(fileLocalUrls))
                    }} variant="contained" size="small">{!!cardDocument ? 'Редактировать' : 'Добавить'}</Button>
            </CardActions>

        </Card>
    )

}