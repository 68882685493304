import { useEffect, useState } from "react";
import { DefectFormInputData, IDefect, IMedicalReport, IReportStatus, MedCardStatuses } from "../../../types";
import { useMedDrawerModel } from "../DrawerReportModel";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks/redux";
import { setBase64File, setMedCardInfo, setMedicalReport } from "../../../MedExamsSlice";
import { setConclusion } from "../../../MedCardPageModel";
import { Modal } from "@mui/material";
import { ModalNames } from "../../../../../slices/AppSlice";
import { useModal } from "../../../../../modules/layout/components/modals/useModal";
import { getDefectsRequest } from "../../../../../services/DriverCardService";



export const useDefectFormModel = (onClose: any) => {

    const [isTextFieldRequired, setTextFieldrequired] = useState<boolean>(false)
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true)

    const dispatch = useAppDispatch();
    const { card } = useAppSelector(state => state.examReducer)
    const { medic } = useAppSelector(state => state.mainReducer)
    const { changeCurrentModal } = useModal();

    const [options, setOptions] = useState<IDefect[]>([{id: 0, name: ''}])

    useEffect(() => {
        (async () => {
            try { setOptions( (await getDefectsRequest()).data ) } 
            catch (e) { console.log(e) } 
        })()
    }, [])
    
    const defaultValues: DefectFormInputData = {
        comment: '',
        checkboxes: [],
    }

    const templateModel = useMedDrawerModel(defaultValues);

    const [openModal, setModal] = useState<boolean>(false)
    const handleOnCancel = () => {
        setModal(false)
        const newArray = templateModel.getValues('checkboxes')
        newArray.splice(newArray.length-1, 1) 
        templateModel.setValue('checkboxes', newArray)
    }
    const handleOnConfirm = () => {setModal(false)}
    const watchCheckboxes = templateModel.watch('checkboxes')
    const [checkRepeatIdent, setCheckRepeatIdent] = useState<boolean>()
    useEffect(()=>{
        const subscribtion = templateModel.watch((value) => {
            const hasOption = Boolean(value.checkboxes.find((checkbox:IDefect)=>checkbox.name === "Повторная идентификация личности"))
            setCheckRepeatIdent(!Boolean(hasOption)) //compilation effect?
            if (checkRepeatIdent && hasOption) {setModal(true)}

            if(value.checkboxes.length === 0 && value.comment !== '') {
                setDisableSubmit(true)
                setTextFieldrequired(true)
            } else if (value.checkboxes.find((checkbox: any) => (checkbox.id === 10)) && value.comment === '') {
                setDisableSubmit(true)
                setTextFieldrequired(true)
            } else if (value.checkboxes.length === 0 ) {
                setDisableSubmit(true)
                setTextFieldrequired(false)
            } else {
                setDisableSubmit(false)
                setTextFieldrequired(false)
            }

        })
        return () => subscribtion.unsubscribe()
    },[watchCheckboxes])


    const onSubmit = async (data: DefectFormInputData) => {
        console.log(data)
        let medicalReport:IMedicalReport | null = null;
        const isDataNotEmpty = data.checkboxes && data.checkboxes.length > 0;
        if (isDataNotEmpty) {
            console.log('Valid')
            if(!!medic){
                if(!!medic.id){
                    medicalReport = {
                        defects: data.checkboxes,
                        comment: !!data.comment ? data.comment : undefined,
                        status: IReportStatus.DEFECTED,
                        medicId:medic.id,
                    }
                    if (!checkRepeatIdent) {medicalReport.archive = true} else {delete medicalReport.archive}
                    dispatch(setMedicalReport(medicalReport))
                    changeCurrentModal(ModalNames.CERTIFICATES)
                    dispatch(setBase64File(undefined))
                    onClose();
                }
               
            }
            
           
            // changeCurrentModal(ModalNames.ECP_SIGNING)
            // try {
            //     if(!!medic.id){
            //         const conclusionResult = await setConclusion(card, medicalReport, medic.id)
            //         if (!!conclusionResult) {
            //             changeCurrentModal(ModalNames.SUCCESS)
            //         } else {
            //             changeCurrentModal(ModalNames.ECP_ERROR)
            //         }
            //         console.log('CONCLUSION')
            //         console.log(conclusionResult)
            //         dispatch(setMedCardInfo(null))
            //         dispatch(setBase64File(undefined))
            //         onClose();
            //     }
                
            // } catch (e) {
            //     changeCurrentModal(ModalNames.ERROR)
            //     return
            // }


        } else console.log('Not Valid')
    };

    const submitHandler = templateModel.handleSubmit(onSubmit)

    return {
        control: templateModel.control,
        options,
        submitHandler,
        handleFormValues: templateModel.setValue,
        getValues: templateModel.getValues,
        register: templateModel.register,
        isTextFieldRequired,
        disableSubmit,
        openModal,
        handleOnCancel,
        handleOnConfirm,
        watch: watchCheckboxes,
    }
}