import { LineWeight, Padding } from "@mui/icons-material"
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Popper, Stack, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider, ruRU } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { useEffect, useState } from "react"
import { IReportStatus } from "../../Meds/types"
import { getStatusChip } from "../ReportPageModel"
import moment from "moment-timezone"
import dayjs from "dayjs"

const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const inputStyle = {
    width: '117px', 
    height: '36px',
    '& .MuiInputBase-root': {
        //paddingLeft: '0px',
        //margin: '-4px 0px 4px 0px'
    },
    '& .MuiInputBase-input': {
        padding: '8px 0px 8px 0px',

        textTransform: 'lowercase',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.3',
    },
    '& .MuiButtonBase-root': {
        padding: '0px',
        '&.MuiIconButton-root': {
            color: '#8E8E8E',
            scale: '0.8'
        }
    },
    '& .MuiInputAdornment-root': {
        //margin: '0px',
        padding: '0px',
        width: '20px',
        height: '20px',
        margin: '0px -2px 0px 4px',
    },

    '& .MuiOutlinedInput-root:hover': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #A5E5FD',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #009FDA',
        },
      },
}

const formControlLabelStyle = {
    margin: '0px',
    width: '100%',

    '& .MuiTypography-root': {
        width: '100%', textAlign: 'center',
        fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
        userSelect: 'none',
    },

    padding: '10px 12px', height: '36px',
    boxShadow: 'none', textTransform: 'none',
    backgroundColor: '#FFFFFF', color: '#8E8E8E',
    borderRadius: '4px', border: '2px solid #BDBDBD',
    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
}

const labelStyle = {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#3E3E3E',
    marginBottom: '-6px',
}

interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const ReferralDateFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [dateFrom, setDateFrom] = useState<any>(null)
    const [dateTo, setDateTo] = useState<any>(null)

    const [timeIntervals, setTimeIntervals] = useState<any>({ 'Текущая неделя': false, 'Прошлая неделя': false, 'Текущий месяц': false, 'Прошлый месяц': false });

    const timeValues: any = { 
        'Текущая неделя': {
            gte: moment.tz('Europe/Moscow').startOf('isoWeek'),
            lte: moment.tz('Europe/Moscow')
        }, 
        'Прошлая неделя': {
            gte: moment.tz('Europe/Moscow').subtract(1, 'weeks').startOf('isoWeek'),
            lte: moment.tz('Europe/Moscow').subtract(1, 'weeks').endOf('isoWeek')
        }, 
        'Текущий месяц': {
            gte: moment.tz('Europe/Moscow').startOf('month'),
            lte: moment.tz('Europe/Moscow')
        }, 
        'Прошлый месяц': {
            gte: moment.tz('Europe/Moscow').subtract(1,'months').startOf('month'),
            lte: moment.tz('Europe/Moscow').subtract(1,'months').endOf('month')
        } 
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeIntervals(Object.entries(timeIntervals).forEach(([key]) => {timeIntervals[key] = false}))
        setTimeIntervals({...timeIntervals, [event.target.name]: event.target.checked});

        setDateFrom(timeValues[event.target.name].gte)
        setDateTo(timeValues[event.target.name].lte)  
    };


    const handleConfirmFilter = () => {
    
        if (dateFrom || dateTo) {
            setFilterRequest((prev:any) => {
                let from; let to
                from = (dateFrom ? dateFrom : moment.tz('1970-01-01', 'Europe/Moscow')).startOf('day')
                to = (dateTo ? dateTo : moment.tz('Europe/Moscow')).endOf('day')
                return {...prev, createdAt: { 
                    gte: from.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
                    lte: to.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                }}
            })
        }
        setMode(true)
        handleClose()
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.createdAt
            return state
        })


        setMode(false)
        handleClose()
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <Box sx={{display: 'flex', alignItems: 'center', gap: '12px', margin: '4px 0px'}}>
                <LocalizationProvider dateAdapter={AdapterMoment} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DemoItem label={<Typography sx={labelStyle}>C</Typography>}>
                        <DatePicker
                            sx={inputStyle}
                            slotProps={{
                                field: { clearable: false },
                                inputAdornment: { position: 'start' }
                            }}
                            format={'DD.MM.YYYY'}
                            value={dateFrom}
                            onChange={(e)=>setDateFrom( moment.tz(e, 'Europe/Moscow') )}
                            disableFuture={true}
                        />
                    </DemoItem>

                    <DemoItem label={<Typography sx={labelStyle}>По</Typography>}>
                        <DatePicker
                            sx={inputStyle}
                            slotProps={{
                                field: { clearable: false  },
                                inputAdornment: { position: 'start' }
                            }}
                            format={'DD.MM.YYYY'}
                            value={dateTo}
                            onChange={(e)=>setDateTo( moment.tz(e, 'Europe/Moscow') )}
                            disableFuture={true}
                        />
                    </DemoItem>
                </LocalizationProvider>
            </Box>

            <Divider sx={{margin: '0px -12px'}}/>

            <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{alignItems: 'center', gap: '8px', margin: '4px 0px'}}>
                    {Object.keys(timeIntervals).map((key) => (
                        <FormControlLabel key={key}
                            label={key}
                            control={
                                <Checkbox 
                                    sx={{ display: 'none' }}
                                    checked={timeIntervals[key]} onChange={handleChange} name={key}
                                />
                            }
                            sx={[formControlLabelStyle, 
                                timeIntervals[key] && {
                                    backgroundColor: '#009FDA',
                                    borderColor: '#009FDA',
                                    color: '#FFFFFF',
                                    "&:hover": { backgroundColor: '#007DB8', borderColor: '#007DB8' },
                                }
                            ]}
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}