import { Box, Button, Card, Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer, SxProps, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { activeColor, drawerBoxStyle, optionStyle } from "../../../styles/styles";
import { DrawerProps } from "../../../types";
import { useDefectFormModel } from "./DefectFormModel";
import { buttonStyle } from "../../../../Login/login-styles";
import { ReactComponent as CloseIcon } from '../assets/Close.svg'
import { FormCheckboxes } from "../../FormCheckboxes";
import { FormInputText } from "../../FormInputText";
import { MedFormInputText } from "../../../MedFormInputText";
import { MedFormCheckboxes } from "../../../MedFormCheckboxes";
import { useModal } from "../../../../../modules/layout/components/modals/useModal";
import { ModalRepeatIdent } from "../../../components/ModalRepeatIdent";

export const titleMargin = {
    marginLeft: '10px'
}


// interface IFormInput {
//     textValue: string;
//     radioValue: string;
//     checkboxValue: string[];
//     dateValue: Date;
//     dropdownValue: string;
//     sliderValue: number;
//   }




export const DefectDrawerForm = (props: DrawerProps) => {

    const model = useDefectFormModel(props.onClose);
    //console.log('defect model')
    //console.log(model)


    const isOtherFieldPicked = () => {
        const values = model.getValues()
        return !!values.checkboxes.find((elem: any) => elem.id === 8)

    }



    return (
        <SwipeableDrawer
            anchor={'right'}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            sx={{
                width: '362px',
                height: '100%',
                //backgroundColor:'#EEEEEE',
                '*::-webkit-scrollbar': {
                    width: '24px',
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: '#EEEEEE',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#8E8E8E',
                    border: '6px solid transparent',
                    borderRadius: '10px / 10px',
                    backgroundClip: 'content-box',
                },
            }}
        >
            {/* <Box
                sx={[drawerBoxStyle, { height: '100%' }]}
                role="presentation"
            > */}
                <Box sx={{ position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', /*marginBottom: '17px'*/ }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', }} component={'h1'}>Нарушение требований</Typography>
                        <IconButton onClick={props.onClose} color="secondary" aria-label="add an alarm"
                            sx={{'&.MuiButtonBase-root': {borderRadius: '4px'}, '&:hover': {backgroundColor: '#FFFFFF'}}}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                </Box>
                <Stack sx={{ ...drawerBoxStyle, padding: '0px 24px', height: '100%', overflowY: 'auto', gap: '16px' }}>

                    <MedFormCheckboxes
                        control={model.control}
                        setValue={model.handleFormValues}
                        getValues={model.getValues}
                        name={"checkboxes"}
                        checkboxValues={model.options}
                        watch={model.watch}
                    />
                    <Box>
                        <FormInputText
                            //rules={{ required: model.isTextFieldRequired}}
                            name={'comment'}
                            control={model.control}
                            label={'Комментарий'}
                            //isDisabled = {model.disableSubmit}
                            isRequired={model.isTextFieldRequired}
                        />
                        <span style={{
                            color: 'red',
                        }}>
                            {/* {isOtherFieldPicked() && !model.getValues('comment').trim()  ? 'Обязательно укажите причину' : ''} */}
                            {model.isTextFieldRequired ? 'Обязательно укажите причину' : ''}
                        </span>
                    </Box>

                </Stack>
                <Box sx={{ position: 'sticky', bottom: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px' }}>

                    <Button
                         //disabled={model.getValues().checkboxes.length==0} 
                         disabled={model.disableSubmit}
                        sx={{
                            ...buttonStyle,
                            backgroundColor: activeColor,
                            //marginTop:'24px', 
                            '&:hover': {
                                backgroundColor: '#007DB8',
                                boxShadow: 'none',
                            }
                        }}
                        fullWidth
                        onClick={model.submitHandler}
                        variant="contained">
                        Завершить осмотр
                    </Button>

                </Box>

                <ModalRepeatIdent open={model.openModal} onCancel={model.handleOnCancel} onConfirm={model.handleOnConfirm} />
            {/* </Box> */}
        </SwipeableDrawer>

    )

}
