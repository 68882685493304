import React from "react";
import {LoginErrors} from "../loginConstants";

export interface RegState{
    phone:string|null,
    email:string|null,
    currentTabValue:any,
    isCodeRequested:boolean,
    pinCode:string|undefined,
    currentRegStage:RegStages,
    password:string,
}

export interface RegPhoneMailFormProps{
    action():void,
    handleTabChange(event: React.SyntheticEvent, value:string):void,
    handlePhoneChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    handleMailChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    toggleErrors():void,
    isErrorHidden:boolean,
    error:string,
    isFormValid?:boolean,
    currentTabValue:string,
    mail:string
    phone:string,
}

export interface RegCreatePaswFormProps{
    action():void,
    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    handleConfirmChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    togglePasswordHide():void,
    toggleConfirmHide():void,
    toggleErrors():void,
    isErrorHidden:boolean,
    showPass:boolean,
    showConf:boolean,
    error:string,
    isValid:boolean,
}

export interface RegPinCodeProps{
    isAgreementChecked:boolean,
    handleAgreementChecked(value:boolean):void, 
    action():void,
    timer():void,
    toggleErrors():void,
    repeatConfirmationSend():void,
    handlePinChange(value:string):void
    error:LoginErrors,
    isValid:boolean,
    pinCode:string | undefined,
    mail:string,
    phone:string,
    isErrorHidden:boolean,
    isTimerStarted:boolean,
    timeLeft?:string,
    isConfirmationSended:boolean,
    type:string,
}

export interface RegConfirmProps{
    action():void,
    handleConfirmChange(value:string):void,
}

 export enum RegStages{
    'initReg',
    'pinCode',
    'passwordCreation',
    'passwordConfirm'
}