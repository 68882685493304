
import React, {FC, useEffect} from "react";
import {RecPhoneMailFormProps, RecStages} from "../../rec-types";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {  InputAdornment, Tab, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import classes from "./form.module.css"

export const RecPhoneMailForm:FC<RecPhoneMailFormProps>=(
    {
        currentTabValue,
        handleMailChange,
        handlePhoneChange,
        handleTabChange,
        action,
        toggleErrors,
        isErrorHidden,
        error,
        phone,
    })=>{

        useEffect(()=>{
            console.log(isErrorHidden)
        },[isErrorHidden])
        
    return(
        <div style={{
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
            maxWidth:"360px",
            textAlign:'center',
        }}>

            <h2 >Восстановление пароля</h2>
            <p style={{marginTop:'36px', marginBottom:'48px'}}>Введите номер мобильного телефона или адрес электронной почты, указанный при регистрации</p>

            <TabContext value={currentTabValue}>


                <TabList
                    onChange={handleTabChange}>

                    <Tab
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            fontSize: 16,
                            fontWeight: 700,

                        }}
                        label="Номер телефона" value="phone"/>

                    <Tab
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            fontSize: 16,
                            fontWeight: 700,
                        }}
                        label="Электронная почта" value="email"/>

                </TabList>


                <TabPanel

                    sx={
                        {
                            padding: '24px 0px 24px 0px',

                        }
                    }
                    value="email">



                    <TextField
                        id="standard-helperText"
                        label="Электронная почта"
                        placeholder='Электронная почта'
                        variant='filled'
                        sx={{
                            "&.MuiTextField-root": {
                                padding: 0,
                                backgroundColor:  isErrorHidden ? 'white' : '#FFBBBB',
                                borderRadius: '4px',
                                width: 360,
                                height: 67,
                                border:isErrorHidden ? 'none' : '2px solid red',
                            }
                        }}
                        InputProps={
                            {
                                disableUnderline: true,
                            }}

                        onChange={handleMailChange}

                    />

                </TabPanel>
                <TabPanel

                    sx={
                        {
                            padding: '24px 0px 24px 0px',

                        }
                    }

                    value="phone">
                    {/* <TextInput inputPlaceHolder={'Номер телефона'}  onChange={handlePhoneChange}/> */}
                    <TextField
                        id="standard-helperText"
                        label="Телефон"
                        variant='filled'
                        value={phone}
                        sx={{
                            backgroundColor:  isErrorHidden ? 'white' : '#FFBBBB',
                            borderRadius: '4px',
                            width: 360,
                            height: 67,
                            border:isErrorHidden ? 'none' : '2px solid red',
                        }}
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                                disableUnderline: true,
                              
                            }
                    }

                        onChange={handlePhoneChange}

                    />
                </TabPanel>
                {/* <PasswordInput handler={handlePasswordChange}/> */}

            </TabContext>
            {(isErrorHidden) ? null : <p onClick={()=>{toggleErrors()}}  className={classes.error}>{error}</p>}
            <Button
                fullWidth={true}

                sx={{
                    color: 'white',
                    textTransform: 'none',
                    fontSize: 16,
                    fontWeight: 700,
                    height: 50,
                    backgroundColor: '#1890FF',
                    marginTop:'24px'
                }}
                onClick={() => { action()}}
                variant='contained'>Восстановить</Button>
        </div>
    )
}