import { Box } from "@mui/material"

export const ModalNoneCards = () => {
    
    return (
        <Box sx={{
            //width: '1184px',
            height: '916px', 
            backgroundColor: 'white',

            display: 'flex',
            justifyContent: 'center',
        }}>

            <Box
                sx={{
                    height: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                    
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50px',
                    }}
                >
                    <Box 
                        sx={{
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            fontSize: '30px',
                            lineHeight: '38px',
                            color: '#009FDA',
                        }}
                    >
                        Ожидайте осмотры
                    </Box>
                </Box>            

            </Box>

        </Box>
    )
}