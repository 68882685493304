import { $api } from "../api"

import { Timer } from "../modules/Timer/Timer-types";

// export const getMedicTimerRequest =  ()=> {
//     return  $api.get('/v1/medics/me')
//  }


export interface Medic {
    id?:            number   | null
    lastname?:      string   | null
    firstname?:     string   | null
    middlename?:    string   | null
    roles?:         string[] | null
    current_timer?: Timer    | false
    current_shift?: any      | false
    next_shift?:    any      | false
}

export const getMedicRequest = () => {
    return $api.get('/v1/medics/me')
}