
import classes from "./crPasw.module.css";
import React, {FC} from "react";
import {RecCreatePaswFormProps, RecStages} from "../../rec-types";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import eye from "../../../../../../assets/icon/eye.png";
import eyeOff from "../../../../../../assets/icon/eyeOff.png";


export const RecCreatePasswordForm:FC<RecCreatePaswFormProps>=(
    {
        action,
        handlePasswordChange,
        handleConfirmChange,
        togglePasswordHide,
        toggleConfirmHide,
        showPass,
        showConf,
        error,
        toggleErrors,
        isErrorHidden
    })=>{

    return(
        <div className={classes.main}>
            <h2>Создание пароля</h2>

            <p className={classes.text}>Придумайте пароль (не менее 8 символов).
                Он защищает Ваши данные и используется для входа в приложение</p>

            <div className={classes.form}>

                <TextField
                    id="standard-helperText"
                    label="Придумайте пароль"
                    placeholder='Введите пароль'
                    select={false}
                    variant='filled'
                    error={!isErrorHidden}
                    type={showPass ? "text" : "password"}
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: isErrorHidden ? 'white' : '#FFBBBB',
                        width: 360,
                        height: 67,
                        border:isErrorHidden ? 'none' : '2px solid red',
                      }
                    }

                    InputProps={
                        {
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        // onClick={handlePassVisibilty}
                                        aria-label="toggle password"
                                        edge="end"
                                        onClick={togglePasswordHide}
                                    >
                                        {showPass ? (
                                            <img src={eye}/>
                                        ) : (
                                            <img src={eyeOff}/>
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    }

                    onChange={handlePasswordChange}

                />
                <TextField
                    id="standard-helperText"
                    label="Повторите пароль"
                    placeholder='Введите пароль'
                    select={false}
                    variant='filled'
                    error={!isErrorHidden}
                    type={showConf ? "text" : "password"}
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: isErrorHidden ? 'white' : '#FFBBBB',
                        marginTop:'24px',
                        width: 360,
                        height: 67,
                        border:isErrorHidden ? 'none' : '2px solid red',
                    }
                    }

                    InputProps={
                        {
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password"
                                        edge="end"
                                        onClick={toggleConfirmHide}
                                    >
                                        {showConf ? (
                                            <img src={eye}/>
                                        ) : (
                                            <img src={eyeOff}/>
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    }

                    onChange={handleConfirmChange}

                />

                {(isErrorHidden) ? null : <p onClick={()=>{toggleErrors()}}  className={classes.error}>{error}</p>}


                <Button
                    fullWidth={true}

                    sx={{
                        color: 'white',
                        textTransform: 'none',
                        fontSize: 16,
                        fontWeight: 700,
                        height: 50,
                        backgroundColor: '#1890FF',
                        marginTop:'24px'
                    }}
                    onClick={() => { action()}}
                    variant='contained'>Подтвердить</Button>




            </div>

        </div>
    )
}