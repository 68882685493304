import { Box, Fab } from "@mui/material"
import { TimerModelProps, useTimerModel } from "./TimerModel"
import { fabStyle, timerStyle } from "./styles"
import { PauseIcon } from "./assets/PauseIcon";
import { PlayIcon } from "./assets/PlayIcon";
import { CloseIcon } from "./assets/CloseIcon";
import { TimerStatus } from "./Timer-types";
import { ModalConfirmActivity } from "./components/ModalConfirmActivity";
import { ModalFinishTimer } from "./components/ModalFinishTimer";
import { ModalPauseTimer } from "./components/ModalPauseTimer";
import { PageOverlapTimer } from "./components/PageOverlapTimer";
import { GENERAL_STATUSES } from "../../pages/Documents/utils";

export const Timer = (props: TimerModelProps) => {

    const timerModel = useTimerModel()

    return (
        <div 
            style={{
                color: 'black'
            }}>
            <Box sx={{
                width: '237px',
                height: '44px',
                backgroundColor: timerModel.status !== TimerStatus.RUNNING ? '#EEEEEE' : '#D3F8D2',
                borderRadius: '4px',
                padding: '0px 16px 0px 16px',

                display: 'flex',
                gap: '16px',

                justifyContent: 'space-between',

            }}>
                <Box sx={[timerStyle, {
                    color: (
                            timerModel.status === TimerStatus.OFFLINE
                            || (!timerModel.isDocumentsValid && !timerModel.isECPFlashLoaded)
                        )
                        ? '#8E8E8E' : '#3E3E3E'
                }]}>
                    <span>
                        {timerModel.clockFace.h}
                    </span>
                    <span>
                        :{timerModel.clockFace.m}
                    </span>
                    <span style={{fontSize: '24px', color: '#8E8E8E'}}>
                        :{timerModel.clockFace.s}
                    </span>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '7px',
                        paddingTop: '6px',
                    }}>
                    <Fab
                        onClick={timerModel.handleSetTimer}
                        disabled={
                            timerModel.status === TimerStatus.OFFLINE
                            || !(timerModel.isDocumentsValid && timerModel.isECPFlashLoaded)
                        }
                        sx={[fabStyle, {
                            backgroundColor: '#009FDA',
                            '&:hover': {backgroundColor: '#007FBA'}
                        }]}>
                        {timerModel.status !== TimerStatus.RUNNING ? 
                            <PlayIcon sx={{scale: '0.8', paddingLeft: '5px'}}/> 
                            : 
                            <PauseIcon sx={{scale: '0.7'}}/>
                        }
                    </Fab>
                    <Fab 
                        onClick={timerModel.handleFinishTimer}
                        disabled={
                            timerModel.status === TimerStatus.FINISHED || 
                            timerModel.status === TimerStatus.OFFLINE
                            || (!timerModel.isDocumentsValid && !timerModel.isECPFlashLoaded)
                        }
                        sx={[fabStyle, {
                            backgroundColor: '#FF4D4F',
                            '&:hover': {backgroundColor: '#DD2D2F'}
                        }]}>
                        <CloseIcon sx={{scale: '0.6', color: 'white'}} />
                    </Fab>
                </Box>
            </Box>

            <ModalConfirmActivity 
                open={timerModel.openModalConfirmActive}
                remaining={timerModel.remaining}
                onPause={timerModel.handleOnPause}
                onContinue={timerModel.handleOnContinue}
            />

            <ModalPauseTimer
                open={timerModel.openModalPauseTimer}
                cardsAwait={timerModel.numberOfCreatedCards}
                onPause={timerModel.handlePauseTimer}
                onStay={timerModel.handleContinue}
                counter={timerModel.counter}
            />

            <ModalFinishTimer
                open={timerModel.openModalFinishTimer}
                cardsAwait={timerModel.numberOfCreatedCards}
                onFinish={timerModel.handleRemoveTimer}
                onStay={timerModel.handleContinue}
                counter={timerModel.counter}
            />
            
            { timerModel.location.pathname === '/meds' ?
                <PageOverlapTimer
                    open={ !timerModel.isActive }
                    status={timerModel.status}
                    pauseClockFace={timerModel.pauseClockFace}
                    handleSetTimer={timerModel.handleSetTimer}
                    handleFinishTimer={timerModel.handleFinishTimer}
                    isDocumentsValid={timerModel.isDocumentsValid && timerModel.isECPFlashLoaded}
                />
                :
                null
            }

        </div>
    )
}