import { Box } from "@mui/material"

interface ReportsStatisticsProps {
    general: number
    accepted: number
    rejected: number
}

export const ReportsStatistics = ({general=0, accepted=0, rejected=0}:ReportsStatisticsProps) => {
    return (
        <Box sx={{
                display: 'flex', gap: '12px', alignItems: 'center', padding: '5px 5px 5px 12px', margin: '-10px 0px -10px 0px',
                backgroundColor: '#EEEEEE', color: '#3E3E3E', fontSize: '14px', fontWeight: '500', borderRadius: '4px',
            }}>
            Всего осмотров: {general}
            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                <Box sx={{padding: '4px 12px', borderRadius: '2px', backgroundColor: 'white', color: '#33C341'}}>Допущено: {accepted}</Box>
                <Box sx={{padding: '4px 12px', borderRadius: '2px', backgroundColor: 'white', color: '#FF4D4F'}}>Не допущено: {rejected}</Box>
            </Box>
        </Box>
    )
}