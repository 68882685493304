import { useEffect, useState } from 'react';
import { RecStages } from "./rec-types";
import { useLoginModuleModel } from "../loginModuleModel";
import { restoreRequest, sendPasConfirmationRequest, validateCodeRequest } from "../../../../services/RecoveryService";
import { LoginErrors } from "../loginConstants";


export const useRecModel = () => {

    const templateModel = useLoginModuleModel()


    const [currentRecStage, setRecStage] = useState<RecStages>(RecStages.initRec)
    const [time, setTime] = useState<string>()
    const [isConfirmationSended, setConfSended] = useState<boolean>(false)
    const [isTimerStarted, setTimerStarted] = useState<boolean>(false)
    const [isErrorHidden, setErorrHidden] = useState<boolean>(true)
    const [isPasswordRestored, setPasswordRestore] = useState<boolean>(false)


    useEffect(() => {
        console.log(templateModel.currentTabValue)
    }, [templateModel.currentTabValue])



    const changeRecStage = (value: RecStages) => {
        setRecStage(value)
    }

    const toggleErrors = () => {
        setErorrHidden(!isErrorHidden)
    }

    const timer = () => {
        let count = 1;
        if (isTimerStarted) {
            return
        }
        let start_time = new Date();
        let stop_time = start_time.setMinutes(start_time.getMinutes() + count);
        let countdown = setInterval(function () {
            let now = new Date().getTime();
            let remain = stop_time - now;
            let min = Math.floor((remain % (1000 * 60 * 60)) / (1000 * 60));
            let sec = Math.floor((remain % (1000 * 60)) / 1000);
            let strSec = sec < 10 ? "0" + sec : sec;
            if (remain <= 0) {
                clearInterval(countdown);
                setTimerStarted(false)
            }
            (min >= 0 && sec >= 0) && setTime(min + ":" + strSec)
        }, 1000);
        setTimerStarted(true)
    }



    const sendConfirmation = async () => {
        const clearPhone = templateModel.phone.replace(/\D+/g, '')
        templateModel.setLoading(true)
        try {
            let response;
            templateModel.currentTabValue === 'email'
                ? response = await sendPasConfirmationRequest("EMAIL", templateModel.mail)
                : response = await sendPasConfirmationRequest("PHONE", `7${clearPhone}`)
            // console.log(response)
            templateModel.setFormValid(true)
            if (!!response) {
                console.log('Успешно')
                setConfSended(true)
                setErorrHidden(true)
                timer();
                changeRecStage(RecStages.pinCode)
            }else throw new Error('Запрос провален')

        } catch (e) {
            console.log('Ошибка')
            // console.log(e)
            templateModel.setFormValid(false)
            setConfSended(false);
            setErorrHidden(false)
        }
        templateModel.setLoading(false)
    }

    const validateCode = async () => {
        // changeRecStage(RecStages.passwordCreation)
        const clearPhone = templateModel.phone.replace(/\D+/g, '')

        templateModel.setLoading(true)
        try {
            let response;
            templateModel.currentTabValue === 'email'
                ? response = await validateCodeRequest("EMAIL", templateModel.mail, templateModel.pinCode)
                : response = await validateCodeRequest("PHONE", `+7${clearPhone}`, templateModel.pinCode)
            if (!!response) {
                templateModel.setFormValid(true)
                console.log('Успешно')
                setErorrHidden(true)
                changeRecStage(RecStages.passwordCreation)
            } else throw new Error('Провалено')

        } catch (e) {
            console.log('Ошибка')
            // console.log(e)
            templateModel.setFormValid(false)
            setErorrHidden(false)
        }
        templateModel.setLoading(false)
    }

    const restore = async () => {
        const clearPhone = templateModel.phone.replace(/\D+/g, '')

        templateModel.setLoading(true)
        try {
            let response;
            templateModel.currentTabValue === 'email'
                ? response = await restoreRequest("EMAIL",
                    templateModel.password,
                    templateModel.passwordConfirm,
                    templateModel.mail,
                    templateModel.pinCode)
                : response = await restoreRequest("PHONE",
                    templateModel.password,
                    templateModel.passwordConfirm,
                    `+7${clearPhone}`,
                    templateModel.pinCode)
            if (!!response) {
                templateModel.setFormValid(true)
                setErorrHidden(true)
                console.log('Успешно')
                setPasswordRestore(true)
            } else throw new Error('Провалено')

        } catch (e) {
            console.log('Ошибка')
            // console.log(e)
            templateModel.setFormValid(false)
            setErorrHidden(false)
        }
        templateModel.setLoading(false)
    }

    return {
        handleTabChange: templateModel.handleTabChange,
        handlePhoneChange: templateModel.handlePhoneChange,
        handleMailChange: templateModel.handleMailChange,
        handlePinChange: templateModel.handlePinChange,
        handlePasswordChange: templateModel.handlePasswordChange,
        handlePasswordConfirmChange: templateModel.handlePasswordConfirmChange,
        changeRecStage,
        sendConfirmation,
        validateCode,
        restore,
        toggleConfirmHide: templateModel.toggleConfirmHide,
        togglePasswordHide: templateModel.togglePasswordHide,
        timer,
        toggleErrors,
        isPasswordRestored,
        isConfirmationSended,
        isTimerStarted,
        isErrorHidden,
        time,
        LoginErrors,
        currentTabValue: templateModel.currentTabValue,
        currentRecStage,
        showPass: templateModel.showPass,
        showConf: templateModel.showConf,
        isFormValid: templateModel.isFormValid,
        isLoading: templateModel.isLoading,
        phone: templateModel.phone,
        pinCode: templateModel.pinCode,
        password: templateModel.password,
        mail: templateModel.mail
    }
}
