import styled from "@emotion/styled";
import { TooltipProps, Tooltip, tooltipClasses } from "@mui/material";
import { tooltip } from "../styles";
import { TooltipArrow } from "./TooltipArrow";

export const ToolTip = styled(({ className, color, ...props }: TooltipProps) => (
    <Tooltip 
        arrow 
        placement='bottom-start' 
        slots={{arrow: TooltipArrow}}
        {...props} 
        classes={{ popper: className }} 
    />
  ))(({ theme, color }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        ...tooltip, backgroundColor: color?color:'white'
    },
}));