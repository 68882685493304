import { useEffect } from "react";
import { ModalNames, ModalQueueType, ModalType, setModal, setModalQueue } from "../../../../slices/AppSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux";



export const useModal=()=>{

    const dispatch = useAppDispatch();
    const { currentModal, modalQueue} = useAppSelector(state => state.appReducer)

    // const openModal=(state:ModalType)=>{
    //     dispatch(setModal(state))
    // }

    const changeCurrentModal=(modal:ModalType)=>{
        dispatch(setModal(modal))
    }

    const addModal=(modal:ModalNames)=>{
        console.log('модалки')
        let currentQueue:any[] = modalQueue
        console.log(currentQueue)
        // let pushed = currentQueue.push(modal)
        console.log(currentQueue)
        dispatch(setModalQueue(currentQueue.concat([modal])))
    }
    const removeModal=(modal:ModalNames)=>{
        let currentQueue:any[] = [...modalQueue]
        console.log(currentQueue)
        console.log(currentQueue.indexOf(modal))
        currentQueue.splice(currentQueue.indexOf(modal), 1)
        dispatch(setModalQueue(currentQueue))
    }
    const addModalGroup=(modals:ModalNames[])=>{
        let currentQueue:any[] = modalQueue
        dispatch(setModalQueue(currentQueue.concat(modals)))
    }
    return{
        currentModal,
        // openModal,
        addModal,
        addModalGroup,
        removeModal,
        modalQueue,
        changeCurrentModal,
    }

}