import { arrow } from "../styles"

interface TooltipArrowProps {color?: string}

export const TooltipArrow = ({color}:TooltipArrowProps) => {
    return (
        <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...arrow, color: color?color:'white'}}>
            <path d="M4 1.92491C4 1.11506 4.91233 0.641099 5.57495 1.10672L38.4126 24.1818C39.2118 24.7434 38.8144 26 37.8376 26H5C4.44771 26 4 25.5523 4 25V1.92491Z" 
            fill="currentColor"/>
            <path d="M4 23.5V19.5C4 19.5 4 21.5 3 22.5C2 23.5 0 23.5 0 23.5H4Z" 
            fill="currentColor"/>
            <path d="M34.4459 23.5018L39.9941 23.5035C39.9941 23.5035 37.8864 23.2579 36.8747 22.8222C35.8629 22.3865 34.4614 21.4062 34.4614 21.4062L34.4459 23.5018Z" 
            fill="currentColor"/>
        </svg>
    )
}