import { Box, CardMedia, SxProps } from "@mui/material"
import { IDriver, IdentCard, IdentDriver } from "../types"
import serviceDefaultPic from '../../../assets/Pic.png';
import { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import { IncreasePhotoIcon } from "../assets/IncreasePhotoIcon";

export interface DriverIdentInfoProps {
    driver?: IdentDriver | null //IDriver | null
    statusChip?: any
    coverBgColor?: any
    coverPadding?: any
    textSize?: any 
    textGap?: any
    showPhotoTitle?: boolean
}

export const DriverIdentInfo = ({ driver, statusChip, coverBgColor, coverPadding, textSize, textGap, showPhotoTitle = true }: DriverIdentInfoProps) => {
    const [toggler, setToggler] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>('')
    const dataContainerStyle = {
        backgroundColor: '#FFFFFF',
        border: '1px solid #EEEEEE',
        borderRadius: '4px',
    }
    

    useEffect(() => {
        if (driver?.photo) {

          !!driver?.photo ?  setImageUrl(driver.photo[0]) : setImageUrl(serviceDefaultPic) 
        }

    }, [driver])

    const curImage = <img src={imageUrl}></img>


    const containerHeadingStyle = {
        backgroundColor: '#EEEEEE',
        padding: '4px 12px',
        fontWeight: '500',
        fontSize: '14px',
        color: '#565656',
        lineHeight: '18.2px'
    }

    const containerDataStyle = {
        padding: '4px 12px',
        fontWeight: '400',
        fontSize: '16px',
        color: '#2A2A2A',
        lineHeight: '18.2px',
    }

    return (
        <Box sx={{
            backgroundColor: coverBgColor ?? 'white',
            fontFamily: 'Roboto',
            //width: '556px',
            //width: '100%',
            //height: '340px',
            borderRadius: '4px',
            padding: coverPadding ?? '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: textGap ?? '16px',
        }}
        >

            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <h3 style={{
                    fontSize: textSize ?? '20px',
                    fontWeight: '500',
                    lineHeight: '26px',
                    color: '#3E3E3E'
                }}>
                    Информация о водителе
                </h3>
                <Box>
                    {statusChip}
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
            }}>
                <Box sx={[dataContainerStyle, { overflow: 'hidden', minWidth: '178px', maxHeight: '266px', margin: '0 auto', position: 'relative' }]}>
                    {showPhotoTitle ? 
                        <Box sx={containerHeadingStyle}>
                            Эталонное фото
                        </Box>
                    : null}
                    
                    <CardMedia
                        onClick={() => { setToggler(!toggler) }} 
                        sx={{ minWidth: '178px', minHeight: '240px', cursor:'pointer' }} image={!!driver?.photo ? driver.photo : serviceDefaultPic}>
                            <Box 
                                sx={{
                                    position: 'absolute', right: '0px', bottom: '0px', zIndex: '2', pointerEvents: 'none',
                                    backgroundColor: 'rgb(0,0,0,0.5)', padding: '4px', borderRadius: '4px', 
                                    width: '32px', height: '32px',
                                }}>
                                <IncreasePhotoIcon />
                            </Box>
                        <FsLightbox
                            toggler={toggler}
                            sources={!!imageUrl ? [curImage] : [serviceDefaultPic]}
                        />
                    </CardMedia>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    width: '100%',
                }}>

                    <Box sx={dataContainerStyle}>
                        <Box sx={containerHeadingStyle}>
                            Ф.И.О. Водителя
                        </Box>
                        <Box sx={containerDataStyle}>
                            {
                                (driver?.lastname || '-') + ' ' +
                                (driver?.firstname || '-') + ' ' +
                                (driver?.middlename || '-')
                            }
                        </Box>
                    </Box>

                    <Box sx={dataContainerStyle}>
                        <Box sx={containerHeadingStyle}>
                            Пол
                        </Box>
                        <Box sx={containerDataStyle}>
                            {driver?.gender || '-'}
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                    }}>
                        <Box sx={[dataContainerStyle, {width: '100%'}]}>
                            <Box sx={containerHeadingStyle}>
                                Дата рождения
                            </Box>
                            <Box sx={containerDataStyle}>
                                {driver?.birthdate ? new Date(driver?.birthdate).toLocaleDateString('ru-RU') : '-'}
                            </Box>
                        </Box>

                        <Box sx={[dataContainerStyle, {width: '100%'}]}>
                            <Box sx={containerHeadingStyle}>
                                Возраст
                            </Box>
                            <Box sx={containerDataStyle}>
                                {driver?.age || '-'}
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={dataContainerStyle}>
                        <Box sx={containerHeadingStyle}>
                            Компания
                        </Box>
                        <Box sx={containerDataStyle}>
                            {driver?.company || '-'}
                        </Box>
                    </Box>

                </Box>
            </Box>

        </Box>
    )
}