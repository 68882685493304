import { Modal, Box, Button, ToggleButton, ToggleButtonGroup, Typography, Stack, TableBody, TableContainer, TableHead, TableRow, TableCell, Checkbox } from "@mui/material"
import { column } from "../ReportPageModel"
import React, { useState } from "react"
import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd"
import ReactDOM, { createPortal } from "react-dom"
import { propsToClassKey } from "@mui/styles"
import zIndex from "@mui/material/styles/zIndex"


const btnGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
}

const btnStyle = {
    padding: '8px 16px',

    textTransform: 'none',
    justifyContent: 'flex-start',
    height: '40px',
    borderRadius: '0',
    border: 'none',

    color: '#3E3E3E',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.3',

    "&.Mui-selected": {
        color: '#FFFFFF',
        backgroundColor: '#009FDA',
    },
    "&.Mui-selected:hover": {
        color: '#FFFFFF',
        backgroundColor: '#009FDA',
    }
}


const tableHeadRow = {
    backgroundColor: '#FAFAFA',
    position: 'sticky',
    top: '0px',
    zIndex: '2',
  
    '& .MuiTableCell-root': {
      borderColor: '#EEEEEE',
      position: 'relative',

      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '1.3',
      color: '#222222',
      
      '&::after': {
        padding: '0px',
        content: '""',
        position: 'absolute',
        height: '22px',
        width: '1px',
        backgroundColor: '#EEEEEE',
        marginTop: '-2px',
        right: 0
      },
      "&:last-child": {
        '&::after': {
          display: 'none',
        },
      },
    },
    "& :nth-of-type(2)": {
        width: '100%'
    },
}

const tableBodyRow = {
    '& .MuiTableCell-body': {
        borderColor: '#EEEEEE',
        padding: '16px',
        lineHeight: '1.3',
        "&:nth-of-type(2)": {
            width: '100%'
        },
    },
    '&:hover': {
        backgroundColor: "#E3F2FF !important"
    },
    '&.Mui-selected': {
        backgroundColor: "#E3F2FF !important"
    }
}

const tableCellBox = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#222222',
}

const checkBoxStyle = {
    backGroundColor: '#FFFFFF',
    color: '#BDBDBD', 
    width: '16px', 
    height: '16px', 
    margin: '0px -6px 0px -6px',
    "& .MuiSvgIcon-root": {
        //fontSize: '24px',
    }
}

const draggingStyleFix = {
    backgroundColor: "#E3F2FF !important",
    maxWidth: '448px',
    '& .MuiTableCell-body': {
        borderColor: '#EEEEEE',
        "&:nth-of-type(2)": {
            width: '100%',
        },
    },
}

const portal = document.createElement("div")
document.body.appendChild(portal)
interface PortalAwareItemProps {draggableSnapshot: any, children: React.ReactNode}
const PortalAwareItem = ({draggableSnapshot, children}: PortalAwareItemProps) => {

    if (!draggableSnapshot.isDragging) {
        return <>{children}</>
    } else {
        return ReactDOM.createPortal(children, portal);
    }
}

const getListStyle = (isDragging: boolean) => ({})

const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return isDragging ? {...draggableStyle, ...draggingStyleFix} : {...draggableStyle, ...tableBodyRow,}
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


export interface ViewSettingsModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    columns: column[],
    setColumns: (col: column[]) => void
}
export const ViewSettingsModal = ({ open, setOpen, columns, setColumns }: ViewSettingsModalProps) => {

    const [columnsBuffer, setColumnsBuffer] = useState<column[]>(columns)
    const [allChecked, setAllChecked] = useState<boolean>(columnsBuffer.find((col) => col.visible === false) ? false : true)

    const checkColumns = (index?: number) => {
        let result: column[] = [...columnsBuffer]
        if (index !== undefined) {
            result[index] = {...result[index], visible: !columnsBuffer[index].visible}
            setAllChecked(result.find((col) => col.visible === false) ? false : true)
        } else {
            result = result.map((col) => ({...col, visible: !allChecked}))
            setAllChecked(!allChecked)
        }
        setColumnsBuffer(result)
    }

    const cancelSettings = () => {
        setColumnsBuffer(columns)
        setAllChecked(columns.find((col) => col.visible === false) ? false : true)
        setOpen(false)
    }

    const saveSettings = () => {
        setColumns(columnsBuffer)
        setOpen(false)
    }

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) { return }
        setColumnsBuffer( reorder(columnsBuffer, result.source.index, result.destination.index) )
    }

    return (
        <Modal
            open={open}
            sx={{
                backgroundColor: 'rgb(0,0,0, 0.1)',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'background.paper',
                    //width: '748px',
                    height: '460px',
                    border: 'none',
                    borderRadius: '4px',

                    display: 'flex',

                    '*::-webkit-scrollbar': {
                        width: '12px',
                    },
                      '*::-webkit-scrollbar-track': {
                        backgroundColor: '#EEEEEE',
                    },
                      '*::-webkit-scrollbar-thumb': {
                        backgroundColor: '#BDBDBD',
                        border: '2px solid transparent',
                        borderRadius: '5px/5px',
                        backgroundClip: 'content-box',
                    },
                }}
            >
                
                <Box 
                    sx={{
                        backgroundColor: '#EEEEEE',
                        width: '240px',
                        padding: '48px 0px',
                        borderRadius: '4px 0px 0px 4px'
                    }}
                >
                    <ToggleButtonGroup
                        sx={btnGroupStyle}
                    >
                        <ToggleButton
                            sx={btnStyle}
                            value='general'
                        >
                            Общие настройки
                        </ToggleButton>
                        <ToggleButton
                            sx={btnStyle}
                            value='columns'
                            selected
                        >
                            Настройка колонок
                        </ToggleButton>
                    </ToggleButtonGroup>

                </Box>

                <Box
                    sx={{
                        backgroundColor: '#FFFFFF',
                        width: '508px',
                        padding: '24px',
                        borderRadius: '0px 4px 4px 0px'
                    }}
                >
                    <Stack gap={'24px'} width={'100%'}>

                        <Stack gap={'12px'}>
                            <Typography sx={{
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '1.3',
                            }}>
                                Настройки вида
                            </Typography>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '1.3',
                            }}>
                                Чтобы изменить список и порядок отображаемых колонок, выберите нужный вид и отметьте интересующие галочкой.
                            </Typography>
                        </Stack>

                        <TableContainer sx={{height: '250px', borderRadius: '4px 0px 0px 0px', overflowX: 'hidden'}}>
                            <TableHead>
                                <TableRow sx={tableHeadRow}>
                                    <TableCell>
                                        <Checkbox sx={checkBoxStyle}
                                            checked={allChecked}
                                            onClick={() => checkColumns()}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        Выбрать все
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="tablebody" type="table">
                                    {(droppableProvided) => (
                                        <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                            {columnsBuffer.map((col, index) => (
                                                <Draggable key={col.id} draggableId={`${col.id}`} index={index}>
                                                    {(draggableProvided, draggableSnapshot) => (
                                                        <PortalAwareItem draggableSnapshot={draggableSnapshot}>
                                                            <TableRow 
                                                                ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}
                                                                sx={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                                                            >
                                                                <TableCell>
                                                                    <Checkbox 
                                                                        sx={checkBoxStyle} 
                                                                        checked={col.visible}
                                                                        onClick={() => checkColumns(index)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={tableCellBox}>
                                                                        {col.name}
                                                                        <Box {...draggableProvided.dragHandleProps}
                                                                            sx={{ 
                                                                                height: '20px', '&:hover': {cursor: 'grab'}, 
                                                                                '&:active': {cursor: 'grabbing'}, '&:focus': {cursor: 'grabbing'} 
                                                                            }}
                                                                        >
                                                                            <svg width="14" height="20" viewBox="0 0 14 20">
                                                                                <circle cx="10" cy="4" r="2" fill="#BDBDBD"/>
                                                                                <circle cx="10" cy="10" r="2" fill="#BDBDBD"/>
                                                                                <circle cx="10" cy="16" r="2" fill="#BDBDBD"/>
                                                                                <circle cx="4" cy="4" r="2" fill="#BDBDBD"/>
                                                                                <circle cx="4" cy="10" r="2" fill="#BDBDBD"/>
                                                                                <circle cx="4" cy="16" r="2" fill="#BDBDBD"/>
                                                                            </svg>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        </PortalAwareItem>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {droppableProvided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </TableContainer>

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '16px'}}>
                            <Button
                                sx={{
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    padding: '10px 12px', height: '36px', 
                                    backgroundColor: '#FFFFFF', color: '#009FDA',
                                    borderRadius: '4px', border: '2px solid #009FDA',
                                    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
                                    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
                                }}
                                onClick={() => { cancelSettings() }}
                            >
                                Отменить
                            </Button>
                            <Button
                                sx={{
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    padding: '10px 12px', height: '36px',
                                    backgroundColor: '#009FDA', color: 'white',
                                    borderRadius: '4px',
                                    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
                                    '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' },
                                }}
                                onClick={() => { saveSettings() }}
                            >
                                Сохранить
                            </Button>
                        </Box>

                    </Stack>
                </Box>
                
                
            </Box>
        </Modal>
    )
}