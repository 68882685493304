export const fabStyle = {
    margin: '0px', 
    padding: '0px',

    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',

    boxShadow: 'none',
    '&:hover': {
        borderColor: 'none',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: 'none',
    },
    '&:focus': {
        boxShadow: 'none',
        borderColor: 'none',
    },
    '&:disabled': {
        boxShadow: 'none',
        borderColor: 'none',
        backgroundColor: '#BDBDBD'
    }
}

export const timerStyle = {
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontWeight: '700',
    color: '#3E3E3E'
}