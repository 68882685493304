import { Box, Modal,  Typography } from '@mui/material';
import React from 'react';
import { IModalProps } from './props';



export const modalBoxStyle = {
  border: 'none',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
  width: 312,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  '& h2': {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: 'normal',
    color: '#3E3E3E',
  },

  '& p': {
    color: '#3E3E3E',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '130%',
  },
};

export const ModalCheckingECP = ({ open }: IModalProps) => {

  return (
    <Modal open={open}>
      <Box
        sx={modalBoxStyle}
        padding={'40px 40px 50px 40px'}
        display={'flex'}
        flexDirection={'column'}
        gap={'40px'}>
        <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
          <Typography variant='h6' component='h2'>
            Подождите
          </Typography>
          <Typography component='p'>Идет проверка документов...</Typography>
        </Box>

        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='94'
          height='94'
          viewBox='0 0 64 64'
          fill='none'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM36.0856 27.9132V48.3388C36.0856 50.5949 34.2566 52.4239 32.0005 52.4239C29.7443 52.4239 27.9154 50.5949 27.9154 48.3388V27.9132C27.9154 25.6571 29.7443 23.8281 32.0005 23.8281C34.2566 23.8281 36.0856 25.6571 36.0856 27.9132ZM32.0005 19.7431C34.2566 19.7431 36.0856 17.9142 36.0856 15.658C36.0856 13.4019 34.2566 11.5729 32.0005 11.5729C29.7443 11.5729 27.9154 13.4019 27.9154 15.658C27.9154 17.9142 29.7443 19.7431 32.0005 19.7431Z'
            fill='#519ed6'
          />
        </svg>
      </Box>
    </Modal>
  );
};
