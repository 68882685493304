import React, { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';


import Button from '@mui/material/Button';
import classes from "./Auth.module.css";
import eye from "../../../../assets/icon/eye.png"
import eyeOff from "../../../../assets/icon/eyeOff.png"
import { useAuthModel } from "./AuthModel";

import {
    InputAdornment,
    Tab,
    Tabs,
    TextField,
    IconButton,
    createTheme,
    Typography,
    Box,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { authTabStyle, buttonStyle, inlineBox, linkStyle, registrationButtonStyle, tabPanelStyle, textFieldStyle } from '../../login-styles';






export const Auth = ({ goToReg, goToPasRec, fullFilledData }: any) => {

    const model = useAuthModel();

    useEffect(() => {
        model.updateInputData(fullFilledData)
    }, [fullFilledData])

    return (

        <Box>

            <TabContext value={model.currentTabValue}>


                <TabList
                    onChange={model.handleTabChange}>

                    <Tab
                        sx={authTabStyle}
                        label="Номер телефона" value="phone" />

                    <Tab
                        sx={authTabStyle}
                        label="Электронная почта" value="email" />


                </TabList>


                <TabPanel

                    sx={
                      tabPanelStyle
                    }
                    value={'email'}>

                    <TextField
                        id="standard-helperText"
                        label="Электронная почта"
                        placeholder='Электронная почта'
                        variant='filled'
                        select={false}
                        value={model.mail}
                        sx={{
                            ...textFieldStyle,

                            backgroundColor: model.isErrorHidden ? 'white' : '#FFBBBB',
                        }}
                        InputProps={
                            {
                                disableUnderline: true,

                            }}
                        InputLabelProps={{ shrink: false }}

                        onChange={model.handleMailChange}

                    />

                </TabPanel>
                <TabPanel

                    sx={
                        tabPanelStyle
                    }

                    value={'phone'}>

                    <TextField
                        id="standard-helperText"
                        label="Телефон"
                        variant='filled'
                        select={false}
                        value={model.phone}
                        sx={{
                            ...textFieldStyle,
                            backgroundColor: model.isErrorHidden ? 'white' : '#FFBBBB',
                            border: model.isErrorHidden ? 'none' : '2px solid red',
                        }}
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                                disableUnderline: true,
                            }}
                        InputLabelProps={{ shrink: false }}

                        onChange={model.handlePhoneChange}

                    >
                    </TextField>

                </TabPanel>

                <TextField
                    id="standard-helperText"
                    label="Пароль"
                    placeholder='Пароль'
                    select={false}
                    variant='filled'
                    value={model.password}
                    error={!model.isErrorHidden}
                    type={model.showPass ? "text" : "password"}
                    sx={{
                        ...textFieldStyle,
                        backgroundColor: model.isErrorHidden ? 'white' : '#FFBBBB',
                        border: model.isErrorHidden ? 'none' : '2px solid red',
                    }}
                    InputProps={
                        {
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        // onClick={handlePassVisibilty}
                                        aria-label="toggle password"
                                        edge="end"
                                        onClick={model.togglePasswordHide}
                                    >
                                        {model.showPass ? (
                                            <img src={eye} />
                                        ) : (
                                            <img src={eyeOff} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    }
                    InputLabelProps={{ shrink: false }}

                    onChange={model.handlePasswordChange}

                />
            </TabContext>
            {(model.isErrorHidden) ? null : <Typography sx={{
                 color:'red',
                 cursor:'pointer',
            }} onClick={() => { model.toggleErrors() }} >{model.LoginErrors.AUTH_ERROR}</Typography>}

            <Box sx={inlineBox}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <input onClick={() => {

                    }} id={'remember'} type={'checkbox'} />
                    {/* <label htmlFor={'remember'} style={{color:'white'}}>Запомнить меня</label> */}
                    <Typography sx={{ fontSize: "14px", fontWeight: '400', color: '#BDBDBD', marginLeft: 1 }}>Запомнить меня</Typography>
                </Box>

                <Typography
                    sx={linkStyle}
                    onClick={() => goToPasRec()}
                >
                    Забыли пароль?
                </Typography>
            </Box>

            <Button
                fullWidth={true}

                sx={buttonStyle}
                onClick={() => {
                    model.currentTabValue === 'email' ? model.emailLogin() : model.phoneLogin()
                    // model.loginWithoutAxios()
                }}
                variant='contained'>Вход</Button>
            {/* <hr /> */}
            <Box sx={inlineBox}>

                <Typography sx={linkStyle}
                    onClick={() => goToReg()}
                >
                    Если у вас ещет нет аккаунта
                </Typography>

            </Box>




            <Button
                fullWidth={true}
                sx={registrationButtonStyle} variant={'outlined'} onClick={() => {
                    goToReg()
                }}>Регистрация</Button>
        </Box>

    );
}
