// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.crPasw_main__LFFRm{\n    display: flex;\n    flex-direction: column;\n    max-width: 360px;\n    align-items: center;\n    text-align: center;\n}\nh2{\n    font-size: 24px;\n}\n.crPasw_text__GOOfN{\n    margin-top: 36px;\n}\n.crPasw_form__NgUfU{\n    margin-top: 36px;\n    height: 102px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n.crPasw_codeLink__WVCSv{\n    margin-top: 22px;\n    margin-bottom: 22px;\n}\n.crPasw_error__5q56p{\n    margin-top: 22px;\n    margin-bottom: 22px;\n    color:red;\n    cursor:pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/Login/modules/Recovery/components/RecCreatePasswordForm/crPasw.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,cAAc;AAClB","sourcesContent":["\n.main{\n    display: flex;\n    flex-direction: column;\n    max-width: 360px;\n    align-items: center;\n    text-align: center;\n}\nh2{\n    font-size: 24px;\n}\n.text{\n    margin-top: 36px;\n}\n.form{\n    margin-top: 36px;\n    height: 102px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n.codeLink{\n    margin-top: 22px;\n    margin-bottom: 22px;\n}\n.error{\n    margin-top: 22px;\n    margin-bottom: 22px;\n    color:red;\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "crPasw_main__LFFRm",
	"text": "crPasw_text__GOOfN",
	"form": "crPasw_form__NgUfU",
	"codeLink": "crPasw_codeLink__WVCSv",
	"error": "crPasw_error__5q56p"
};
export default ___CSS_LOADER_EXPORT___;
