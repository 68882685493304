
import { Box, IconButton, List, ListItem, Stack, SwipeableDrawer, Typography } from "@mui/material"
import { ReactComponent as CloseIcon } from '../../../../Meds/modules/Drawers/assets/Close.svg'
import { TextInputControlled } from "../../../components/TextInputControlled"
import { FormSelect } from "../../../../../components/FormSelect"
import { drawerBoxStyle } from "../../../../Meds/styles/styles"
import img1 from '../../../../../assets/ecp-how-get-instruction/1.png'
import img2 from '../../../../../assets/ecp-how-get-instruction/2.png'
import img3 from '../../../../../assets/ecp-how-get-instruction/3.png'
import img4 from '../../../../../assets/ecp-how-get-instruction/4.png'
import img5 from '../../../../../assets/ecp-how-get-instruction/5.png'

export const HowGetECP = ({ open, onClose, onOpen }: any) => {

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={open}
            onClose={
                onClose
            }
            onOpen={onOpen}
            sx={{
                // width: '676px',
                // backgroundColor:'#EEEEEE',
                '*::-webkit-scrollbar': {
                    width: '24px',
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: '#EEEEEE',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#8E8E8E',
                    border: '6px solid transparent',
                    borderRadius: '10px / 10px',
                    backgroundClip: 'content-box',
                },
            }}
        >
            <Box
                sx={{...drawerBoxStyle, width:'724px', overflow: 'hidden'}}
                role="presentation"
                //padding={3}
            >
                <Box sx={{position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', }} component={'h1'}>Инструкция по получению ЭЦП</Typography>
                        <IconButton onClick={onClose} color="default" aria-label="add an alarm"
                            sx={{'&.MuiButtonBase-root': {borderRadius: '4px'}, '&:hover': {backgroundColor: '#FFFFFF'}}}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                
                <Box sx={{padding: '0px 0px 108px 24px', backgroundColor: 'transparent', height: '100%'}}>
                    <Box sx={{padding: '24px', backgroundColor: '#FFFFFF', height: '100%', overflowY: 'scroll'}}>
                        <Stack
                            sx={{gap: '31px', fontFamily: 'Roboto', fontWeight: '400px', fontSize: '16px', lineHeight: '20px', 'a': {color: '#009FDA'},
                            overflowY: 'visible'
                            }}
                        >

                            <Typography sx={{fontFamily: 'Roboto', fontWeight: '500', fontSize: '24px', lineHeight: '32px'}}>1. Оформить заявку</Typography>


                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>1 сертификат = 1 медик</Typography>
                                    <Typography>ЭЦП действует 1 год, далее необходимо продлевать.</Typography>
                                    <Typography>Зайти на сайт Контура:  <a href="https://ca.kontur.ru">https://ca.kontur.ru </a></Typography>
                                    <Typography>Выбрать «Для физических лиц».</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img1} />
                                    </Box>
                                </Stack>

                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Выбрать «Для личных целей».</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img2} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Нажать кнопку «Отправить заявку».</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img3} />
                                    </Box>
                                </Stack>

                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Выбрать дополнительные услуги: «Втроеная лицензия КриптоПРО CSP» и «Защищённый носитель».</Typography>
                                    <Typography>Нажать кнопку «Отправить заявку».</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img4} />
                                    </Box>
                                </Stack>

                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Заполнить заявку и нажать кнопку «Отправить заявку».</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img5} />
                                    </Box>
                                </Stack>
                            </Box>

                            <Typography sx={{fontFamily: 'Roboto', fontWeight: '500', fontSize: '24px', lineHeight: '32px'}}>2. Получить ЭЦП в сервисном центре</Typography>

                            <Box>
                                <Stack gap={'8px'}>
                                    <Typography>После отправки заявки в течение 24 часов с Вами свяжется менеджер и расскажет, что делать дальше, как и где получать (чтобы не ждать сутки, можно сразу позвонить им на горячую линию - побыстрее рассмотрят заявку).</Typography>
                                    <Stack>
                                        <Typography sx={{fontWeight: '500'}}>Документы для получения ЭЦП:</Typography>
                                        <List 
                                            sx = {{
                                                marginTop: '-8px',
                                                fontWeight: '500',
                                                pl: '16px',
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                                '& .MuiListItem-root': {
                                                    display: 'list-item',
                                                    padding: '0px', 
                                                },
                                            }}
                                        >
                                            <ListItem>Паспорт</ListItem>
                                            <ListItem>СНИЛС</ListItem>
                                            <ListItem>ИНН</ListItem>
                                        </List>
                                    </Stack>
                                    <Typography>Как установить ЭЦП смотри в Инструкции «Инструкция по добавлению ЭЦП».</Typography>
                                </Stack>
                            </Box>

                        </Stack>

                    </Box>
                </Box>




            </Box>
        </SwipeableDrawer>
    )

}