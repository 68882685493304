
import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, Divider, Stack, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks/redux"
import { ModalNames, setDocumentsValid, setECPFlashLoaded } from "../../../../../slices/AppSlice"
import { useState } from "react"
import { HowAddECP } from "./HowAddECP"
import { HowGetECP } from "./HowGetECP"
import { checkECPPrivateKey } from "../../../../../services/cryptoService"
import { checkUserValid } from "../../../../../modules/PrivateRoute/PrivateRoute"
import { useModal } from "../../../../../modules/layout/components/modals/useModal"
import { GENERAL_STATUSES, getDocumentStatusChip } from "../../../utils"
import { DOC_STATUSES } from "../../../../../types/documentTypes"



export const ECPCard = () => {


    const { isECPFlashLoaded } = useAppSelector(state => state.appReducer)
    const { medic } = useAppSelector(state => state.mainReducer)
    const dispatch = useAppDispatch();
    const [openHowAdd, setOpenHowAdd] = useState(false)
    const [openHowGet, setOpenHowGet] = useState(false)
    const { addModalGroup, changeCurrentModal, removeModal } = useModal()
    return (
        <Card sx={{ backgroundColor: '#EEEEEE', /*width: '540px',*/ minHeight: '120px', boxShadow: 'none' }}>
            <CardContent sx={{ display: 'flex' }}>
                <Typography fontSize={'16px'} fontWeight={500}>
                    Электронная цифровая подпись (ЭЦП)
                </Typography>
                <Box sx={{ marginLeft: '12px' }}>
                    {isECPFlashLoaded ? getDocumentStatusChip(DOC_STATUSES.CHECKED) : getDocumentStatusChip(DOC_STATUSES.MISSED)}
                </Box>

            </CardContent>

            <Divider></Divider>

            <CardActions sx={{ width: '100%', padding: '16px' }}>

                {isECPFlashLoaded ? <></> :
                    <Stack sx={{ width: '100%', justifyContent: 'flex-end' }} direction={'row'} gap={1} >
                        <Button

                            sx={{
                                padding: '10px 12px',
                                backgroundColor: 'white',
                                border: '2px solid #009FDA',
                                borderRadius: '4px',
                                textTransform: 'none',
                                color: '#009FDA',
                                fontSize: '14px',
                                fontWeight: 500,
                                "&:hover": {
                                    backgroundColor: '#F0F7FF',
                                    boxShadow: 'none',
                                },
                                boxShadow: 'none',
                                height: '32px', lineHeight: '16px'
                            }}
                            variant="contained" onClick={() => setOpenHowGet(true)}>Как получить?</Button>
                        <Button

                            sx={{
                                padding: '10px 12px',
                                textTransform: 'none',
                                backgroundColor: 'white',
                                border: '2px solid #009FDA',
                                borderRadius: '4px',
                                color: '#009FDA',
                                fontSize: '14px',
                                fontWeight: 500,
                                "&:hover": {
                                    backgroundColor: '#F0F7FF',
                                    boxShadow: 'none',
                                },
                                boxShadow: 'none',
                                height: '32px', lineHeight: '16px'
                            }}
                            variant="contained" onClick={() => setOpenHowAdd(true)}>Как добавить?</Button>
                        <Button
                            sx={{
                                padding: '10px 12px',
                                textTransform: 'none',
                                backgroundColor: '#009FDA',
                                borderRadius: '4px',
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 500,
                                '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' }, boxShadow: 'none',
                                height: '32px', lineHeight: '16px'
                            }}
                            variant="contained"
                            onClick={async () => {
                                changeCurrentModal(ModalNames.CHECKING_ECP)
                                const isECP = await checkECPPrivateKey()
                                if (isECP) {
                                    changeCurrentModal(null)
                                } else changeCurrentModal(ModalNames.ECP)

                                dispatch(setECPFlashLoaded(isECP))
                                dispatch(setDocumentsValid(medic.documents_general_status === GENERAL_STATUSES.ACCEPTED && isECP))
                            }} size="small">Проверить ЭЦП</Button>

                    </Stack>
                }


            </CardActions>
            <HowAddECP open={openHowAdd} onOpen={() => setOpenHowAdd(true)} onClose={() => setOpenHowAdd(false)}></HowAddECP>
            <HowGetECP open={openHowGet} onOpen={() => setOpenHowGet(true)} onClose={() => setOpenHowGet(false)}></HowGetECP>
        </Card>
    )

}
