import { Box, IconButton, Stack } from "@mui/material"
import { useConnectionIndicator } from "./ConnectionIndicatorModel"
import { container, iconButton, message } from "./styles"
import { NetworkIcon, NetworkMessage, ServerIcon, ServerMessage } from "./types"
import { ToolTip } from "./components/ToolTip"
import { CloseIcon } from "../Timer/assets/CloseIcon"
import { TooltipArrow } from "./components/TooltipArrow"


export const ConnetcionIndicator = () => {

    const {
        networkConnection, networkTooltipOpen, setNetworkTooltipOpen, handleOpenNetworkMessage, handleCloseNetworkMessage,
        serverConnection, serverTooltipOpen, setServerTooltipOpen, handleOpenServerMessage, handleCloseServerMessage,
    } = useConnectionIndicator()

    const Network = NetworkIcon[networkConnection]
    const Server = ServerIcon[serverConnection]
    const NetworkMsg = NetworkMessage[networkConnection]
    const ServerMsg = ServerMessage[serverConnection]

    return (
        <Stack sx={container}>
            <ToolTip
                open={networkTooltipOpen}
                onOpen={()=>setNetworkTooltipOpen(true)}
                //onClose={()=>setNetworkTooltipOpen(false)}
                disableHoverListener
                disableTouchListener

                slots={{arrow: ()=><TooltipArrow color={networkConnection==='UNSTABLE' ? '#FFF0DD' : networkConnection==='LOST' ? '#FFEAEA' : ''}/>}}
                color={networkConnection==='UNSTABLE' ? '#FFF0DD' : networkConnection==='LOST' ? '#FFEAEA' : ''}
                
                title={
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Box sx={message}>
                            {NetworkMsg && <NetworkMsg/>}
                        </Box>
                        <IconButton onClick={handleCloseNetworkMessage} sx={{alignSelf: 'start', scale: '0.45', margin: '-8px -9px', padding: '10px'}}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                }
            >
                <IconButton sx={iconButton} onClick={()=>NetworkMsg && handleOpenNetworkMessage()}>
                    <Network/>
                </IconButton>
            </ToolTip>
            <ToolTip
                open={serverTooltipOpen}
                onOpen={()=>setServerTooltipOpen(true)}
                //onClose={()=>setServerTooltipOpen(false)}
                disableHoverListener
                disableTouchListener

                slots={{arrow: ()=><TooltipArrow color={serverConnection==='LOST' ? '#FFEAEA' : ''}/>}}
                color={serverConnection==='LOST' ? '#FFEAEA' : ''}

                title={
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Box sx={message}>
                            {ServerMsg && <ServerMsg/>}
                        </Box>
                        <IconButton onClick={handleCloseServerMessage} sx={{alignSelf: 'start', scale: '0.45', margin: '-8px -9px', padding: '10px'}}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                }
            >
                <IconButton sx={iconButton} onClick={()=>ServerMsg && handleOpenServerMessage()}>
                    <Server/>
                </IconButton>
            </ToolTip>
        </Stack>
    )
}