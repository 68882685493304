import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, OutlinedInput, Popper, Stack, Typography } from "@mui/material"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { useEffect, useState } from "react"

export const PulseIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5827 10.0765C12.4285 9.82483 12.2777 9.579 12.131 9.404C11.9752 9.21733 11.7018 8.96067 11.2835 8.97733C10.8652 8.994 10.6135 9.2715 10.4727 9.46984C10.3402 9.65734 10.2102 9.91317 10.0768 10.1765L8.42018 13.4432C8.35768 13.5665 8.30602 13.669 8.25935 13.7573C8.19236 13.6604 8.12625 13.5629 8.06102 13.4648L7.91435 13.2432C7.76927 13.0176 7.6144 12.7985 7.45018 12.5865C7.2963 12.3855 7.10288 12.2182 6.88185 12.0948C6.65637 11.9794 6.40964 11.9113 6.15685 11.8948C5.93018 11.8732 5.65935 11.8732 5.35352 11.8732H4.16602C4.00026 11.8732 3.84128 11.939 3.72407 12.0562C3.60686 12.1734 3.54102 12.3324 3.54102 12.4982C3.54102 12.6639 3.60686 12.8229 3.72407 12.9401C3.84128 13.0573 4.00026 13.1232 4.16602 13.1232H5.32768C5.66685 13.1232 5.87852 13.1232 6.04102 13.139C6.19102 13.1532 6.25268 13.1757 6.29185 13.1973C6.33102 13.2182 6.38435 13.2557 6.47935 13.3732C6.58185 13.4998 6.70018 13.6757 6.88768 13.9582L7.04352 14.1907C7.20018 14.4265 7.35435 14.6573 7.50435 14.8232C7.66435 15.0007 7.93935 15.2373 8.34602 15.2132C8.75102 15.189 8.99602 14.9215 9.13518 14.7265C9.26352 14.544 9.38935 14.2957 9.51768 14.0432L11.1735 10.7782C11.2393 10.649 11.2935 10.5415 11.3427 10.449C11.3985 10.5373 11.4618 10.6407 11.5368 10.7632L12.0818 11.6532C12.2485 11.9257 12.3968 12.1665 12.5402 12.3565C12.6952 12.5623 12.8727 12.7473 13.1168 12.884C13.361 13.0207 13.611 13.0757 13.8677 13.1007C14.1043 13.1232 14.3868 13.1232 14.706 13.1232H15.8327C15.9984 13.1232 16.1574 13.0573 16.2746 12.9401C16.3918 12.8229 16.4577 12.6639 16.4577 12.4982C16.4577 12.3324 16.3918 12.1734 16.2746 12.0562C16.1574 11.939 15.9984 11.8732 15.8327 11.8732H14.7327C14.3793 11.8732 14.1577 11.8732 13.9877 11.8565C13.831 11.8407 13.7677 11.8157 13.7277 11.7932C13.6868 11.7707 13.6327 11.7298 13.5385 11.604C13.4352 11.4682 13.3193 11.279 13.1343 10.9773L12.5818 10.0757L12.5827 10.0765Z" fill="#787878"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.95185 1.04297C8.02768 1.04297 6.52018 1.04297 5.34352 1.2013C4.14018 1.36297 3.19018 1.7013 2.44435 2.4463C1.69852 3.19214 1.36102 4.14214 1.19935 5.3463C1.04102 6.52214 1.04102 8.02963 1.04102 9.9538V10.0488C1.04102 11.973 1.04102 13.4805 1.19935 14.6571C1.36102 15.8605 1.69935 16.8105 2.44435 17.5563C3.19018 18.3021 4.14018 18.6396 5.34435 18.8013C6.52018 18.9596 8.02768 18.9596 9.95185 18.9596H10.0468C11.971 18.9596 13.4785 18.9596 14.6552 18.8013C15.8585 18.6396 16.8085 18.3013 17.5543 17.5563C18.3002 16.8105 18.6377 15.8605 18.7993 14.6563C18.9577 13.4805 18.9577 11.973 18.9577 10.0488V9.9538C18.9577 8.02963 18.9577 6.52214 18.7993 5.34547C18.6377 4.14214 18.2993 3.19214 17.5543 2.4463C16.8085 1.70047 15.8585 1.36297 14.6543 1.2013C13.4785 1.04297 11.971 1.04297 10.0468 1.04297H9.95185ZM3.32852 3.33047C3.80352 2.85547 4.44518 2.58297 5.51102 2.43964C6.59435 2.29464 8.01768 2.29297 9.99935 2.29297C11.981 2.29297 13.4043 2.29464 14.4877 2.43964C15.5535 2.58297 16.196 2.8563 16.671 3.33047C17.1452 3.80547 17.4177 4.44714 17.561 5.51297C17.706 6.5963 17.7077 8.01964 17.7077 10.0013C17.7077 11.983 17.706 13.4063 17.561 14.4896C17.4177 15.5555 17.1443 16.198 16.6702 16.673C16.1952 17.1471 15.5535 17.4196 14.4877 17.563C13.4043 17.708 11.981 17.7096 9.99935 17.7096C8.01768 17.7096 6.59435 17.708 5.51102 17.563C4.44518 17.4196 3.80268 17.1463 3.32768 16.6721C2.85352 16.1971 2.58102 15.5555 2.43768 14.4896C2.29268 13.4063 2.29102 11.983 2.29102 10.0013C2.29102 8.01964 2.29268 6.5963 2.43768 5.51297C2.58102 4.44714 2.85435 3.80547 3.32852 3.33047Z" fill="#787878"/>
        </svg>
    )
}


const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const inputStyle = {
    width: '117px', 
    height: '36px',
    '& .MuiInputBase-root': {
        //paddingLeft: '0px',
        //margin: '-4px 0px 4px 0px'
    },
    '& .MuiInputBase-input': {
        padding: '8px 0px 8px 0px',

        textTransform: 'lowercase',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.3',
    },
    '& .MuiButtonBase-root': {
        padding: '0px',
        '&.MuiIconButton-root': {
            color: '#8E8E8E',
            scale: '0.8'
        }
    },
    '& .MuiInputAdornment-root': {
        //margin: '0px',
        padding: '0px',
        width: '20px',
        height: '20px',
        margin: '0px 8px 0px -4px',
        pointerEvents: 'none',
    },

    '& .MuiOutlinedInput-root:hover': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #A5E5FD',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #009FDA',
        },
      },
}

const formControlLabelStyle = {
    margin: '0px',
    width: '100%',

    '& .MuiTypography-root': {
        width: '100%', textAlign: 'center',
        fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
        userSelect: 'none',
    },

    padding: '10px 12px', height: '36px',
    boxShadow: 'none', textTransform: 'none',
    backgroundColor: '#FFFFFF', color: '#8E8E8E',
    borderRadius: '4px', border: '2px solid #BDBDBD',
    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
}

const labelStyle = {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#3E3E3E',
    marginBottom: '-6px',
}


interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const PulseFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [pulseFrom, setPulseFrom] = useState<string>('')
    const [pulseTo, setPulseTo] = useState<string>('')

    const [pulse, setPulse] = useState<any>({ 
        'До 30 ударов/ мин': false, 'От 30 до 45 ударов/ мин': false, 'От 45 до 60 ударов/ мин': false, 
        'От 60 до 80 ударов/ мин': false, 'От 80 до 120 ударов/ мин': false, 'Свыше 120 ударов/ мин': false 
    });

    const pulseValues: any = {
        'До 30 ударов/ мин': {gte: '0', lte: '30'},
        'От 30 до 45 ударов/ мин': {gte: '30', lte: '45'},
        'От 45 до 60 ударов/ мин': {gte: '45', lte: '60'},
        'От 60 до 80 ударов/ мин': {gte: '60', lte: '80'},
        'От 80 до 120 ударов/ мин': {gte: '80', lte: '120'},
        'Свыше 120 ударов/ мин': {gte: '120', lte: '999'},
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPulse(Object.entries(pulse).forEach(([key]) => {pulse[key] = false}))
        setPulse({...pulse, [event.target.name]: event.target.checked})

        setPulseFrom(pulseValues[event.target.name].gte)
        setPulseTo(pulseValues[event.target.name].lte)  
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(pulse).filter((key) => pulse[key] === true)
        console.log(resultArray)

        if (pulseFrom || pulseTo) {
            setFilterRequest((prev:any) => ({...prev, pulse_rate: { 
                gte: pulseTo ? pulseTo : '0',
                lte: pulseTo ? pulseTo : '999'
            }}))
        }
        setMode(true)
        handleClose()
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.pulse_rate
            return state
        })
        setMode(false)
        handleClose()
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <Box sx={{display: 'flex', alignItems: 'center', gap: '12px', margin: '4px 0px'}}>
                <DemoItem label={<Typography sx={labelStyle}>C, ударов/ мин</Typography>}>
                    <FormControl sx={inputStyle} variant="outlined">
                        <OutlinedInput
                            startAdornment={<InputAdornment position="start"><PulseIcon/></InputAdornment>}
                            inputProps={{
                                placeholder: '000',
                            }}
                            value={pulseFrom}
                            onChange={(e) => setPulseFrom((prev) => {
                                if (!e.target.value.match(/^(\d?\d?\d?)$/)) return prev
                                if (e.target.value.length > 1 && e.target.value[0] === '0') return prev
                                return e.target.value
                            })}
                        />
                    </FormControl>
                </DemoItem>

                <DemoItem label={<Typography sx={labelStyle}>По, ударов/ мин</Typography>}>
                    <FormControl sx={inputStyle} variant="outlined">
                        <OutlinedInput
                            startAdornment={<InputAdornment position="start"><PulseIcon/></InputAdornment>}
                            inputProps={{
                                placeholder: '000',
                            }}
                            value={pulseTo}
                            onChange={(e) => setPulseTo((prev) => {
                                if (!e.target.value.match(/^(\d?\d?\d?)$/)) return prev
                                if (e.target.value.length > 1 && e.target.value[0] === '0') return prev
                                return e.target.value
                            })}
                        />
                    </FormControl>
                </DemoItem>
            </Box>

            <Divider sx={{margin: '0px -12px'}}/>

            <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{alignItems: 'center', gap: '8px', margin: '4px 0px'}}>
                    {Object.keys(pulse).map((key, index) => (
                        <FormControlLabel key={key}
                            label={key}
                            control={
                                <Checkbox 
                                    sx={{ display: 'none' }}
                                    checked={pulse[key]} onChange={handleChange} name={key}
                                />
                            }
                            sx={[formControlLabelStyle, 
                                pulse[key] && {
                                    backgroundColor: '#009FDA',
                                    borderColor: '#009FDA',
                                    color: '#FFFFFF',
                                    "&:hover": { backgroundColor: '#007DB8', borderColor: '#007DB8' },
                                }
                            ]}
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}