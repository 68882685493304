export const container = {
    display: 'flex',
    flexDirection: 'row', 
    alignItems: 'center', 
    gap: '0px'
}

export const iconButton = {
    backgroundColor: 'transparent', 
    padding: '12px',
    '&:hover': {
        backgroundColor: 'transparent', 
    }
}

export const tooltip = {
    backgroundColor: '#FFFFFF', color: '#222222',
    padding: '0px', fontSize: '14px',
    display: 'inline-block',

    //boxShadow: '0px 0px 3px rgba(0, 0, 0, .1)',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, .2)',

    marginLeft: '16px',

    minWidth: '330px',
}

export const arrow = {
    color: '#FFFFFF',
    position: 'absolute', 
    top: '-23px', left:'12px',

    //filter: 'drop-shadow(-0.5px -1.1px 0.5px rgba(120,120,120,.1))',
    filter: 'drop-shadow(-1px -2.2px 0.8px rgba(140,140,140,.2))',
} as React.CSSProperties

export const message = {
    padding: '16px 0px 16px 16px', 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
}