import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import ScrollbarArrowUp from '../modules/Drawers/assets/ScrollbarArrowUp.svg'
import { textFieldOutlineMultilineStyle, textFieldOutlineStyle } from "../styles/styles";


export const FormInputText = ({ name, control, label, rules, isRequired }: any) => {
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          sx={{
            ...textFieldOutlineMultilineStyle,
            borderColor: isRequired ? '#DC4C4C' : '#8E8E8E',
            backgroundColor: isRequired ? '#FEEAEA' : 'white',
          }}
          multiline
          rows={4}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={(e) => { onChange(e.target.value); }}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          // disabled={!isRequired}
          InputLabelProps={{
            shrink: false,
            // sx: {
            //   '&.Mui-focused': {
            //     visibility: 'hidden',
            //   }
            // }
          }}
        />
      )}
    />
  );
};
