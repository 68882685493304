
import { FC, ReactNode, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import classes from './layout.module.css'


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavButton } from "./components/NavButton/NavButton";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Avatar, Badge, Button, Container, IconButton, Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { Location } from "../../modules/LocationDivider/Location";
import { Sidebar } from "../../modules/Sidebar/Sidebar";
import { Timer } from "../Timer/Timer";
import { useAppSelector } from "../../store/hooks/redux";
import { ModalContainer } from "../modalContainer/ModalContainer";
import { ModalDocumentNotFound } from "./components/modals/NotAddedDocsModal";
import { ModalDocumentError } from "./components/modals/ErrorDocsModal";
import { ExpiresDocsModal } from "./components/modals/ExpiresDocsModal";
import { ConnetcionIndicator } from "../ConnectionIndicator/ConnectionIndicator";
import { NotificationIcon } from "./assets/NotificationIcon";
import { SearchIcon } from "./assets/SearchIcon";
//const drawerWidth = 256;


export const Layout = () => {


  const { isECPFlashLoaded } = useAppSelector(state => state.appReducer)

  const [drawerWidth, setDrawerWidth] = useState<number>(256)

  return (
    <Box sx={{
      display: 'flex',
      //  backgroundColor: 'blue',
      flexDirection: 'column'
    }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, color: 'black' }}
        aria-label="mailbox folders"

      >
        <Sidebar setWidth={setDrawerWidth}/>
      </Box>

      <div style={{ marginLeft: `${drawerWidth}px`, transition: 'all 0.3s ease-out' }}>
        <Box sx={{position: 'sticky', top: '0px', zIndex: '99'}}>
          <AppBar position="static"
            sx={{ paddingLeft: '16px', width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: "row", justifyContent: 'space-between', boxShadow: 'none', alignItems: 'center' }}
            >

            <ConnetcionIndicator/>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Timer />

              <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {/* <Button onClick={ async () => {
                  // openModal(ModalNames.ECP)
                  console.log( await checkECPPrivateKey())
                }
                }>open modal</Button> */}
                <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '2px', margin: '0px 15px 0px -5px'}}>
                  <IconButton sx={{width: '42px', height: '42px'}}>
                    <SearchIcon/>
                  </IconButton>
                  <IconButton sx={{width: '42px', height: '42px'}}>
                    <Badge badgeContent={5} color="secondary" sx={{'& .MuiBadge-badge':{backgroundColor: '#FF4D4F'}}}>
                        <NotificationIcon />
                    </Badge>
                  </IconButton>
                </Stack>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar></Avatar>
                  <Box sx={{marginLeft: '14px', color: '#3E3E3E'}}>Тест Тестович</Box>
                </div>
              </Toolbar>
            </Box>
          </AppBar>
          {/* <Location /> */}
        </Box>


        <Box
          component="main"
          sx={{ flexGrow: 1, width: '100%', backgroundColor: '#F0F2F5'}}
        >
          <Outlet />
        </Box>
      </div>
      {/* <ModalDocumentError open={true}/> */}
      {/* <ModalDocumentNotFound open={true}/> */}
      <ModalContainer></ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '3px',
          color: '#EEEEEE',
          zIndex: 99999,
          fontSize: '12px',
        }}
      >
        v3.0 от 3.07.2023
      </Box>
    </Box>
  );
}