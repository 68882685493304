import { NetworkStableIcon } from "./assets/NetworkStableIcon";
import { NetworkLostIcon } from "./assets/NetworkLostIcon";
import { NetworkUnstableIcon } from "./assets/NetworkUnstableIcon";
import { ServerLostIcon } from "./assets/ServerLostIcon";
import { ServerStableIcon } from "./assets/ServerStableIcon";
import { WarningIcon } from "./assets/WarningIcon";
import { AlertIcon } from "./assets/AlertIcon";

export type Connection = 'STABLE' | 'UNSTABLE' | 'LOST' | '';

export enum ConnectionAgent {
    'SERVER',  // 0
    'NETWORK', // 1
    //...
    'MAX', // MAX priority
}

export const NetworkIcon: Record<Connection, React.FC> = {
    'STABLE'  : NetworkStableIcon,
    'UNSTABLE': NetworkUnstableIcon,
    'LOST'    : NetworkLostIcon,
    ''        : ()=>null,
}

export const ServerIcon: Record<Connection, React.FC> = {
    'STABLE'  : ServerStableIcon,
    'UNSTABLE': ()=>null,
    'LOST'    : ServerLostIcon,
    ''        : ()=>null,
}

export const NetworkMessage: Record<Connection, React.FC | null> = {
    'STABLE'  : null,
    'UNSTABLE': ()=>(<><WarningIcon/> Неустойчивое интернет-соединение</>),
    'LOST'    : ()=>(<><AlertIcon/> Отсутствует интернет-соединение</>),
    ''        : null,
}

export const ServerMessage: Record<Connection, React.FC | null> = {
    'STABLE'  : null,
    'UNSTABLE': null,
    'LOST'    : ()=>(<><AlertIcon/> Отсутствует подключение к серверу</>),
    ''        : null,
}