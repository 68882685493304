import { Box, IconButton, List, ListItem, Stack, SwipeableDrawer, Typography } from "@mui/material"
import { ReactComponent as CloseIcon } from '../../../../Meds/modules/Drawers/assets/Close.svg'
import { TextInputControlled } from "../../../components/TextInputControlled"
import { FormSelect } from "../../../../../components/FormSelect"
import { drawerBoxStyle } from "../../../../Meds/styles/styles"
import img1 from '../../../../../assets/ecp-how-add-Instruction/1.png'
import img2 from '../../../../../assets/ecp-how-add-Instruction/2.png'
import img3 from '../../../../../assets/ecp-how-add-Instruction/3.png'
import img4 from '../../../../../assets/ecp-how-add-Instruction/4.png'
import img5 from '../../../../../assets/ecp-how-add-Instruction/5.png'
import img6 from '../../../../../assets/ecp-how-add-Instruction/6.png'
import img7 from '../../../../../assets/ecp-how-add-Instruction/7.png'
import img8 from '../../../../../assets/ecp-how-add-Instruction/8.png'
import img9 from '../../../../../assets/ecp-how-add-Instruction/9.png'
import img10 from '../../../../../assets/ecp-how-add-Instruction/10.png'
import img11 from '../../../../../assets/ecp-how-add-Instruction/11.png'
import img12 from '../../../../../assets/ecp-how-add-Instruction/12.png'
import img13 from '../../../../../assets/ecp-how-add-Instruction/13.png'
import img14 from '../../../../../assets/ecp-how-add-Instruction/14.png'
import img142 from '../../../../../assets/ecp-how-add-Instruction/142.png'
import img15 from '../../../../../assets/ecp-how-add-Instruction/15.png'

export const HowAddECP = ({ open, onClose, onOpen }: any) => {

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={open}
            onClose={
                onClose
            }
            onOpen={onOpen}
            sx={{
                // width: '676px',
                // backgroundColor:'#EEEEEE',
                '*::-webkit-scrollbar': {
                    width: '24px',
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: '#EEEEEE',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#8E8E8E',
                    border: '6px solid transparent',
                    borderRadius: '10px / 10px',
                    backgroundClip: 'content-box',
                },
            }}
        >
            <Box
                sx={{...drawerBoxStyle, width:'724px', overflow: 'hidden'}}
                role="presentation"
                //padding={3}
            >
                <Box sx={{position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', }} component={'h1'}>Инструкция по добавлению ЭЦП</Typography>
                        <IconButton onClick={onClose} color="default" aria-label="add an alarm" 
                            sx={{'&.MuiButtonBase-root': {borderRadius: '4px'}, '&:hover': {backgroundColor: '#FFFFFF'}}}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{padding: '0px 0px 108px 24px', backgroundColor: 'transparent', height: '100%'}}>
                    <Box sx={{padding: '24px', backgroundColor: '#FFFFFF', height: '100%', overflowY: 'scroll'}}>
                        <Stack
                            sx={{gap: '31px', fontFamily: 'Roboto', fontWeight: '400px', fontSize: '16px', lineHeight: '20px', 'a': {color: '#009FDA'},
                            overflowY: 'visible'
                            }}
                        >
                            
                            <Typography sx={{fontFamily: 'Roboto', fontWeight: '500', fontSize: '24px', lineHeight: '32px'}}>1. Установить КриптоПРО</Typography>

                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Зайти на сайт: <a href="https://www.cryptopro.ru/products/csp"> https://www.cryptopro.ru/products/csp </a>.</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img1}/>
                                    </Box>
                                </Stack>
                            </Box>

                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Ввести ФИО, эл. Почту и скачать программу КриптоПро.</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img2} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Выбрать папку и нажать кнопку Сохранить.</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img3} />
                                    </Box>
                                </Stack>

                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Открыть папку, куда сохранился файл. Дважды нажать на него левой кнопкой мыши.</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img4} />
                                    </Box> 
                                </Stack>

                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Во время установки будут появляться сообщения «Нажимайте кнопки» или «Двигайте мышью левее» - это нужно будет сделать.
                                        После установки:</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img5} />
                                    </Box>
                                </Stack>
                            </Box>

                            <Typography sx={{fontFamily: 'Roboto', fontWeight: '500', fontSize: '24px', lineHeight: '32px'}}>2. Установить ЭЦП</Typography>

                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Запустить КриптоПро CSP и подключить к компьютеру носитель с сертификатом.
                                        На вкладке «Сервис», в разделе «Контейнер закрытого ключа» нажать кнопку «Скопировать...».</Typography>
                                        <Box sx={{margin: '0 auto'}}>
                                            <img src={img6} />  
                                        </Box>
                                </Stack>
                            </Box>

                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>В открывшемся окне нажать кнопку «Обзор»</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img7} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Выбрать необходимый контейнер (строчку) с сертификатом и нажать на кнопку «Ок», а затем на кнопку «Далее».</Typography>
                                    <Stack sx={{fontWeight: '500'}}>
                                        <Typography sx={{fontWeight: '500'}}>ВНИМАНИЕ! Контейнер не должен быть пустым. В нём должен быть только ОДИН сертификат, принадлежащий медику.</Typography>
                                        <List
                                            sx = {{
                                                marginTop: '-8px',
                                                fontWeight: '500',
                                                pl: '16px',
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                                '& .MuiListItem-root': {
                                                    display: 'list-item',
                                                    padding: '0px', 
                                                },
                                            }}
                                        >
                                            <ListItem>
                                                ФИО медика должны полностью совпадать с ФИО в системе ReMedical;
                                            </ListItem>
                                            <ListItem>
                                                Название медицинской организации в сертификате (если указано) должно полностью совпадать с названием медицинской организации в системе ReMedical.
                                            </ListItem>
                                        </List>
                                    </Stack>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img8} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>
                                        {'В открывшемся окне указать название нового контейнера и нажать кнопку «Готово». Оно обязательно должно иметь указанный формат: '}
                                        <span style={{fontWeight: '500'}}>{'<номер контейнера>@<дата начала срока действия>-<имя учётной записи медика>'}</span><br/>
                                        {'К примеру:'}
                                    </Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img9} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>
                                        {'Выбрать ключевой носитель — «Директория» и нажать на кнопку «ОК».'}<br/>
                                        <span style={{fontWeight: '500'}}>{'Если ключевой носитель «Директория» недоступен'}</span>
                                        {', то КриптоПро CSP стоит обновить до последней версии.'}
                                    </Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img10} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>В открывшемся окне «Аутентификация» оставить два поля ввода пароля пустыми и нажать на кнопку «ОК».</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img11} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>На компьютере перейти в папку, где user — имя пользователя ОС Windows:
                                        C:\Users\user\AppData\Local\Crypto Pro.</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img12} />
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Внутри папки должны быть следующие файлы.</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img13}/>
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>Папку с контейнером необходимо сжать в zip архив: для этого нажмите правой кнопкой мыши по файлу и выберите пункт «Сжать в ZIP-файл» или «Добавить в архив» и выбрать папку (туда же, где находитесь), куда архив поместить.</Typography>
                                    <Typography><span style={{fontWeight: '500'}}>Если система предлагает выбрать программу</span>, с помощью которой нужно сжать файл, то выбирайте WinRAR или 7-Zip или WinZip.</Typography>
                                    <Stack flexDirection={'row'} gap={'12px'}>
                                        <Box>
                                            <img src={img14} />
                                        </Box>
                                        <Box>
                                        <img src={img142} />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Box>
                                <Stack gap={'12px'}>
                                    <Typography>
                                    Загрузить полученный архив в веб-версию кабинета. Для этого перейдите на адрес <a href="https://remedical.ru">https://remedical.ru</a>  и введите свои авторизационные данные (как для приложения ReDoc).
                                        </Typography>
                                    <Typography>Затем перейдите на вкладку "Сертификат". Далее необходимо выбрать вариант - "В ОБЛАКЕ".</Typography>
                                    <Typography sx={{fontWeight: '500'}}>ВНИМАНИЕ! Система не допускает наличие более одного сертификата для людей с полностью совпадающими ФИО.</Typography>
                                    <Typography>Нажмите кнопку Обзор и выберите созданный архив.</Typography>
                                    <Box sx={{margin: '0 auto'}}>
                                        <img src={img15} />
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </Box>

            </Box>

        </SwipeableDrawer>
    )

}