import React, { useEffect } from 'react';
import { useRecModel } from "./RecoveryModel";
import { RecStages } from "./rec-types";
import { RecPhoneMailForm } from "./components/RecPhoneMailForm/RecPhoneMailForm";
import { RecPinCodeForm } from "./components/RecPinCodeForm/RecPinCodeForm";
import { RecCreatePasswordForm } from "./components/RecCreatePasswordForm/RecCreatePasswordForm";
import { Loader } from "../../../../ui/Loader/Loader";

const Recovery = ({ renderAuth }: any) => {
    const model = useRecModel()

    useEffect(() => {
        model.isPasswordRestored
            && renderAuth({
                email: model.mail,
                phone: model.phone,
                password: '',
                currentTab: model.currentTabValue
            })
    }, [model.isPasswordRestored])

    if (model.isLoading) return <Loader />
    switch (model.currentRecStage) {
        case RecStages.initRec: {
            return <RecPhoneMailForm
                handleTabChange={model.handleTabChange}
                handlePhoneChange={model.handlePhoneChange}
                handleMailChange={model.handleMailChange}
                currentTabValue={model.currentTabValue}
                toggleErrors={model.toggleErrors}
                isErrorHidden={model.isErrorHidden}
                phone={model.phone}
                mail={model.mail}
                error={model.LoginErrors.REC_SEND_CONFIRM_ERROR}
                action={() => {
                    model.sendConfirmation()
                }}
            />
            break;
        }
        case RecStages.pinCode: {
            return <RecPinCodeForm
                pinCode={model.pinCode}
                handlePinChange={model.handlePinChange}
                isValid={model.isFormValid}
                error={model.LoginErrors.PIN_CODE_ERROR}
                phone={model.phone}
                mail={model.mail}
                type={model.currentTabValue}
                timeLeft={model.time}
                timer={model.timer}
                toggleErrors={model.toggleErrors}
                isErrorHidden={model.isErrorHidden}
                repeatConfirmationSend={model.sendConfirmation}
                isConfirmationSended={model.isConfirmationSended}
                isTimerStarted={model.isTimerStarted}
                action={() => {
                    model.validateCode()
                }}
            />
            break;
        }
        case RecStages.passwordCreation: {
            return <RecCreatePasswordForm
                isErrorHidden={model.isErrorHidden}
                toggleErrors={model.toggleErrors}
                handlePasswordChange={model.handlePasswordChange}
                handleConfirmChange={model.handlePasswordConfirmChange}
                togglePasswordHide={model.togglePasswordHide}
                toggleConfirmHide={model.toggleConfirmHide}
                showPass={model.showPass}
                showConf={model.showConf}
                isValid={model.isFormValid}
                error={model.LoginErrors.PASSWORD_CONFIRM_ERROR}
                action={() => {
                    model.restore()
                }
                }
            />
            break;
        }
        default: {
            return null
            break;
        }
    }
    
}

export default Recovery;