
import React, {useState} from "react";
import { phoneMaskValidate } from "../../../utils";
import { TabValues } from "./loginConstants";


export const useLoginModuleModel=()=>{
    const [phone, setPhone] = useState<string>('');
    const [pinCode, setPinCode] = useState<string>('');
    const [mail, setMail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [currentTabValue, setCurrentTabValue] = useState<TabValues>('email')
    const [passwordConfirm, setPasswordConfirm] = useState<string>('')
    const [isLoading, setLoading]= useState<boolean>(false)
    const [ isFormValid, setFormValid] = useState<boolean>(true)
    const [showPass, setShowPass] = useState<boolean>(false)
    const [showConf, setShowConf] = useState<boolean>(false)
    const [axiosError, setAxiosError] = useState<string>('')


    const togglePasswordHide = () => {
        setShowPass(!showPass);
    };
    const toggleConfirmHide = () => {
        setShowConf(!showConf);
    };

    const handleTabChange=(event: React.SyntheticEvent, value:TabValues)=> {
        setCurrentTabValue(value)
        console.log(value)
    }

    const handlePinChange = (value:string) => {
        setPinCode(value);
    };
    const handleMailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMail(event.target.value);
    };
    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhone(phoneMaskValidate(event.target.value));   
    };
    const handlePasswordChange=(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setPassword(event.target.value);
    }
    const handlePasswordConfirmChange=(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setPasswordConfirm(event.target.value);
    }
    return {
        handleTabChange,
        handlePinChange,
        handleMailChange,
        handlePhoneChange,
        handlePasswordChange,
        handlePasswordConfirmChange,
        setMail,
        setPhone,
        setPassword,
        setLoading,
        setFormValid,
        setCurrentTabValue,
        togglePasswordHide,
        toggleConfirmHide,
        setAxiosError,
        axiosError,
        showPass,
        showConf,
        isFormValid,
        isLoading,
        pinCode,
        password,
        passwordConfirm,
        phone,
        mail,
        currentTabValue
    }
}
