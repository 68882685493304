import { Modal, Box, Button, Typography, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { IModalProps } from "./props"
import { useAppSelector } from "../../../../store/hooks/redux"
import { DOC_STATUSES, DOC_TYPES, IDocument } from "../../../../types/documentTypes"
// import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export const ExpiredDocsModal = ({ open, handleClose }: IModalProps) => {

  const { medic } = useAppSelector(state => state.mainReducer)
  const { documents } = medic

  const navigate = useNavigate()
  return (
    <Modal
      open={open}
      sx={{
        backgroundColor: 'rgb(0,0,0, 0.1)',
        zIndex: 999999,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'background.paper',

          width: '414px',
          //maxHeight: '320px',
          borderRadius: '4px',
          border: 'none',
          padding: '24px',

          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '24px',

          '& h2': {
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '28px',
            color: '#3E3E3E',
            //whiteSpace: 'nowrap',
          },

          // '& p': {
          //   fontSize: '16px',
          //   fontWeight: '400',
          //   color: '#3E3E3E',
          //   lineHeight: '28px',
          // },
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <h2>
            Истек срок!
          </h2>


        </Box>


        <Stack gap={2}>
          {documents.map((document: IDocument) => {
            if (document.document_status === DOC_STATUSES.EXPIRED) {
              return <Box sx={{ display: 'flex', /*maxWidth: '332px'*/ }}>
                <ErrorOutlineOutlinedIcon sx={{color: '#DC4C4C'}} />
                <Typography sx={{ fontSize: '16px', fontWeight: '500', marginLeft: 1 }}>
                  {
                    document.type === DOC_TYPES.EDUCATION_DIPLOMA
                      ? 'Диплом основного образования'
                      : <></>
                  }
                  {
                    document.type === DOC_TYPES.EDUCATION_CERIFICATE
                      ? 'Удостоверение основного образования'
                      : <></>
                  }
                  {
                    document.type === DOC_TYPES.QUALIFICATION_CERTIFICATE
                      ? 'Удостоверение о повышении квалификации'
                      : <></>
                  }
                  {
                    document.type === DOC_TYPES.ACCREDIATAION_CERTIFICATE
                      ? 'Аккредитация специалиста'
                      : <></>
                  }
                </Typography>
              </Box>
            }
          })
          }
        </Stack>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '1.3' }}>
            При проверке было обнаружено, что срок действия<br/>
            данного документа истек, пожалуйста,<br/>
            добавьте новый, чтобы приступить к работе.
          </Typography>
        </Box>

        <Button
          fullWidth
          onClick={() => {
            navigate('/documents')
            handleClose()
          }}
          sx={{
            // width: '279px',
            height: '36px',
            backgroundColor: '#009FDA',
            padding: '10px 12px',
            borderRadius: '4px',
            color: '#FFFFFF',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '16px',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: '#007FBA',
            }
          }}
        >
          К документам
        </Button>



      </Box>
    </Modal>
  )
}