import { SvgIcon } from "@mui/material"

export const MedExamIcon = (props: any) => {
    return (    
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5.00033V6.66699M10 6.66699V8.33366M10 6.66699H8.33333M10 6.66699H11.6667M6.66667 11.667H7.5M13.3333 11.667H10M7.5 15.0003H12.5M2.5 11.667V8.33366C2.5 5.19116 2.5 3.61949 3.47667 2.64366C4.4525 1.66699 6.02417 1.66699 9.16667 1.66699H10.8333C13.9758 1.66699 15.5475 1.66699 16.5233 2.64366C17.0683 3.18783 17.3092 3.91699 17.415 5.00033M17.5 8.33366V11.667C17.5 14.8095 17.5 16.3812 16.5233 17.357C15.5475 18.3337 13.9758 18.3337 10.8333 18.3337H9.16667C6.02417 18.3337 4.4525 18.3337 3.47667 17.357C2.93167 16.8128 2.69083 16.0837 2.585 15.0003" 
                stroke="currentColor" 
                stroke-width="1.5" 
                stroke-linecap="round"
                fillOpacity='0'
            />
        </SvgIcon>
    )
}