import { IconButton } from "@mui/material"
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import { playerButton, icon } from "../styles";

interface PlayButtonProps {
    playing: boolean, 
    onClick: ()=>void,
}

export const PlayButton = ({playing, onClick}: PlayButtonProps) => {
    return (
        <IconButton 
            sx={playerButton}
            onClick={onClick}
            //disableRipple
        >
            {playing?<PauseRoundedIcon sx={icon}/>:<PlayArrowRoundedIcon sx={icon}/>}
        </IconButton> 
    )
}