import { Slider, Stack } from "@mui/material"
import { seekSlider } from "../styles"
import { getFormatted } from "../utils"

interface SeekSliderProps {
    loadedSeconds:number
    playedSeconds:number
    handleMoveProgress:(event: Event, newValue: number | number[])=>void
    start?: number
    end?: number
    duration?: number
}
//TODO: Проблемы с точностью и синхронизацией видео со слайдером. Где начинается округление до целого числа, в присвоении, там и нужно округлять setLoadedSeconds и д.р.
export const SeekSlider = ({loadedSeconds, playedSeconds, handleMoveProgress, start = 0, end = 0, duration = 0}:SeekSliderProps) => {
    return (
        <Stack sx={seekSlider}>
            {getFormatted(playedSeconds>start?(playedSeconds-start):0)}
                <Slider
                    min={0} max={Math.round(end?(end-start):start?(duration-start>0?duration-start:0):duration)} 
                    step={1}
                    value={Math.round(playedSeconds-start)}
                    onChange={handleMoveProgress}
                /> 
            {getFormatted(end?(end-start):start?(duration-start>0?duration-start:0):duration)}
        </Stack>
    )
}