import sanitizedConfig from "../Config"
import { $api } from "../api"

export const getEmployeesRequest = (companyId: number = 1, role: string, search?: string, gender?: string) => {
    return $api.get(
        sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL + 
        `/v1/user/company/${companyId}/employee/?` + 
        (`role=${role}`) + 
        (search?`&search=${search}`:``) + (gender?`&gender=${gender}`:``)
    )
}