import sanitizedConfig from "../Config"
import { $api } from "../api"
import { IDocument } from "../types/documentTypes"



export const createDocumentRequest=(document:IDocument)=>{
    return $api.post(`/v1/medics/document`, document)
} 

export const updateDocumentRequest=(document:IDocument)=>{
    return $api.patch(`/v1/medics/${document.id}/document`, document)
}








