import { $api } from "../api"
import { IMedCard } from "../pages/Meds/types"



export const getCardsRequest = (query: any) => {
    return $api.get(`/v1/cards` + `?query=${JSON.stringify(query)}`)
}

export const getReferralsRequest = (query: any) => {
    return $api.get(`/v1/cards/referrals` + `?query=${JSON.stringify(query)}`)
}

export const getCardsCountRequest = (query: any) => {
    return $api.get(`/v1/cards/count` + `?query=${JSON.stringify(query)}`)
}

export const updateCardRequest = (payload: any) => {
    const id = payload.id
    delete payload.id 
    return $api.patch(`/v1/cards/${id}`, {...payload, skip: null})
}

export const getNormsRequest = () => {
    return $api.get('/v1/check-norms')
}

export const getDeviationsRequest = (id?:number) => {
    return $api.get(id ? `/v1/cards/${id}/deviations` : `/v1/deviations`)
}

export const getDefectsRequest = () => {
    return $api.get('/v1/defects')
}

export const getCertificatesRequest = (value?: any) => {
    return $api.get('/v1/cards/certificates' + (value ? `?value=${value}` : '') )
}





export const getDriver = () => {
    return $api.get('v1/drivers')
}

export const getDriverDeviations = () => {
    return $api.get('/v1/drivers/deviations')
}

export const getDocumentsRequest=(status?:string, id?:number, perPage?:number, page?:number) => {
    return  $api.get(`/v1/drivers/documents${!!status ? `/?status=${status}` : ''}${!!id ? `&id=${id}` : ''}${!!perPage ? `&per_page=${perPage}` : ''}${!!page ? `&page=${page}` : ''}`)
}

export const updateDocumentRequest = (data: any) => {
    return $api.patch(`/v1/drivers/${data.id}/documents`, data)
}