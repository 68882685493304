import { isDocument } from "@testing-library/user-event/dist/utils";
import { createDocumentRequest, updateDocumentRequest } from "../../services/DocumentService";
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux"
import { DOC_TYPES, IDocument } from "../../types/documentTypes";
import { useEffect, useState } from "react";
import { setDocuments } from "../../slices/CommonInfoSlice";
import { getMedic } from "../../modules/PrivateRoute/PrivateRoute";


export const createDocument = async (document: IDocument) => {
    try {
        const response = await createDocumentRequest(document);
        return response
        // dispatch(setMainInfo( await getMedic()))
    } catch (error) {
        console.log('ERROR')
        console.log(error)
        return false
    }
}

export const updateDocument = async (document: IDocument) => {
    try {
        const response = await updateDocumentRequest(document);
        return response
        // getMedic()
    } catch (error) {
        console.log('ERROR')
        console.log(error)
        return false
    }
}


export const useDocsModel = () => {

    const dispatch = useAppDispatch();
    const { medic } = useAppSelector(state => state.mainReducer)


    const [documentsObj, setDocumentsObj] = useState<IDocumentsObj>({
        edCertificateDocument: null,
        edDiplomaDocument: null,
        accrCertificateDocument: null,
        qualCertificateDocument: null,
    })

    interface IDocumentsObj {
        edCertificateDocument?: IDocument | null,
        edDiplomaDocument?: IDocument | null,
        accrCertificateDocument?: IDocument | null,
        qualCertificateDocument?: IDocument | null,
    }



    useEffect(() => {
        if(!!medic){
            const { documents } = medic
            setDocumentsObj(
                {
                    edCertificateDocument: documents?.find((document: any) => document.type === DOC_TYPES.EDUCATION_CERIFICATE),
                    edDiplomaDocument: documents?.find((document: any) => document.type === DOC_TYPES.EDUCATION_DIPLOMA),
                    accrCertificateDocument: documents?.find((document: any) => document.type === DOC_TYPES.ACCREDIATAION_CERTIFICATE),
                    qualCertificateDocument: documents?.find((document: any) => document.type === DOC_TYPES.QUALIFICATION_CERTIFICATE),
                }
            )
    
            console.log(documentsObj)
        }
    }, [medic])



    return {
        documentsObj
    }
}