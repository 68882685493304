import { Box, Card, Stack, Typography } from "@mui/material"
import { styleCard } from "../styles/styles"
import { useEffect } from "react"
import { VideoPlayer } from "../../../modules/Player/VideoPlayer"



interface IVdeoDataProps {
    temperatureSrc?: string | null,
    pressureSrc?: string | null,
    fullSrc?: string | null,
    flipping?: boolean
    presStart?: number | null
    presEnd?: number | null
    tempStart?: number | null
    tempEnd?: number | null
}


export const VideoData = ({ temperatureSrc, pressureSrc, fullSrc, flipping, presStart, presEnd, tempStart, tempEnd }: IVdeoDataProps) => {
    const videoStyle = {
        width: '348px',
        height: '192px',
        borderRadius: '4px',
        backgroundColor: 'black',
    }
    const item = {
        display: 'flex',
        //width: '550px',
        width: '100%',
        justifyContent: 'space-between',
    }
    const headerStyle = {
        //maxWidth: '200px'
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '26px',
    }

    useEffect(()=>{
        console.log('video props')
        console.log(temperatureSrc)
        console.log(pressureSrc)
        console.log(fullSrc)
    }, [temperatureSrc, pressureSrc, fullSrc])


    return (
        <Box sx={
            {
                ...styleCard,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <Stack marginTop={1} spacing={'24px'} width={'100%'}>
                <Box sx={item}>
                    <Typography sx={headerStyle}>Измерение давления и пульса</Typography>
                    {/* <VideoPlayer options={{
                        sources: [{
                            src: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4',
                            type: 'video/mp4'
                        }]
                    }
                    }></VideoPlayer> */}

                    {/* <video autoPlay muted controls style={videoStyle}
                     src={!!pressureSrc ? pressureSrc : ''}
                    //  src={"https://file-examples.com/storage/fef431be58652d8e49c225d/2017/04/file_example_MP4_480_1_5MG.mp4"}
                     ></video> */}
                     <VideoPlayer 
                        url={!!pressureSrc ? pressureSrc : ''} autoPlay={true} muted={true} flipping={flipping} style={videoStyle} 
                        start={presStart ? presStart : undefined} end={presEnd ? presEnd : undefined}
                    />

                   
                </Box>
                <Box sx={item}>
                    <Typography sx={headerStyle}>Измерение температуры и паров этанола</Typography>
                    {/* <VideoPlayer options={{
                        sources: [{
                            src: temperatureSrc,
                            type: 'video/mp4'
                        }]
                    }
                    }></VideoPlayer> */}
                    {/* <video autoPlay muted style={videoStyle} controls src={!!temperatureSrc ? temperatureSrc : ''}></video> */}
                    <VideoPlayer 
                        url={!!temperatureSrc ? temperatureSrc : ''} autoPlay={true} muted={true} flipping={flipping} style={videoStyle} 
                        start={tempStart ? tempStart : undefined} end={tempEnd ? tempEnd : undefined}
                    />
                </Box>
                <Box sx={item}>
                    <Typography sx={headerStyle}>Полное видео осмотра</Typography>
                    {/* <video autoPlay muted style={videoStyle} controls src={!!fullSrc ? fullSrc : ''}></video> */}
                    <VideoPlayer url={!!fullSrc ? fullSrc : ''} autoPlay={true} muted={true} flipping={flipping} style={videoStyle}/>
                </Box>
            </Stack>
        </Box>
    )
}