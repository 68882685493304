import React from "react";
import {LoginErrors, TabValues} from "../loginConstants";
import { EnumDeclaration } from "typescript";

export interface RecoveryState{
    phone:string|null,
    mail:string|null,
    currentTabValue:any,
}

export enum RecStages{
    'initRec',
    'pinCode',
    'passwordCreation',
    'passwordConfirm'}

export interface RecPhoneMailFormProps{
    action():void,
    handleTabChange(event: React.SyntheticEvent, value:string):void,
    handlePhoneChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    handleMailChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    toggleErrors():void
    isErrorHidden:boolean,
    error:string,
    currentTabValue:string,
    phone:string,
    mail:string,
}


export interface RecCreatePaswFormProps{
    action():void,
    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    handleConfirmChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void,
    togglePasswordHide():void,
    toggleConfirmHide():void,
    toggleErrors():void,
    isErrorHidden:boolean,
    isValid:boolean,
    error:string,
    showPass:boolean,
    showConf:boolean,
}

export interface RecConfirmPasswordProps{
    action():void,
    handleConfirmChange(value:string):void,
}

export interface RecPinCodeProps{
    action():void,
    timer():void,
    toggleErrors():void,
    repeatConfirmationSend():void,
    pinCode:string | undefined,
    isErrorHidden:boolean,
    error:LoginErrors,
    isValid:boolean,
    isTimerStarted:boolean,
    mail:string,
    phone:string,
    type:string,
    timeLeft?:string,
    isConfirmationSended:boolean,
    handlePinChange(value:string):void
}