import { LineWeight, Padding } from "@mui/icons-material"
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Popper, Stack, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider, ruRU } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { useEffect, useState } from "react"
import { IReportStatus } from "../../Meds/types"
import { getStatusChip } from "../ReportPageModel"
import moment from "moment-timezone"
import dayjs from "dayjs"
import { getEmployeesRequest } from "../../../services/CompanyService"

const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const inputStyle = {
    width: '117px', 
    height: '36px',
    '& .MuiInputBase-root': {
        //paddingLeft: '0px',
        //margin: '-4px 0px 4px 0px'
    },
    '& .MuiInputBase-input': {
        padding: '8px 0px 8px 0px',

        textTransform: 'lowercase',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.3',
    },
    '& .MuiButtonBase-root': {
        padding: '0px',
        '&.MuiIconButton-root': {
            color: '#8E8E8E',
            scale: '0.8'
        }
    },
    '& .MuiInputAdornment-root': {
        //margin: '0px',
        padding: '0px',
        width: '20px',
        height: '20px',
        margin: '0px -2px 0px 4px',
    },

    '& .MuiOutlinedInput-root:hover': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #A5E5FD',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        opacity: 1,
        '& fieldset': {
          border: '2px solid #009FDA',
        },
      },
}

const formControlLabelStyle = {
    margin: '0px',
    width: '100%',

    '& .MuiTypography-root': {
        width: '100%', textAlign: 'center',
        fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
        userSelect: 'none',
    },

    padding: '10px 12px', height: '36px',
    boxShadow: 'none', textTransform: 'none',
    backgroundColor: '#FFFFFF', color: '#8E8E8E',
    borderRadius: '4px', border: '2px solid #BDBDBD',
    "&:hover": { backgroundColor: '#F0F7FF', boxShadow: 'none', },
}

const labelStyle = {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#3E3E3E',
    marginBottom: '-6px',
}

interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const DriverBirthdateFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [dateFrom, setDateFrom] = useState<any>(null)
    const [dateTo, setDateTo] = useState<any>(null)

    const role = 'Водитель'

    const handleConfirmFilter = async () => {
        if (dateFrom || dateTo) {
            let from:any; let to:any
            from = (dateFrom ? dateFrom : moment.tz('1970-01-01', 'Europe/Moscow')).startOf('day').unix()
            to = (dateTo ? dateTo : moment.tz('Europe/Moscow')).endOf('day').unix()
            const response = await getEmployeesRequest(1, role)
            const resultArray = response.data.data.filter((driver: any) => {
                const birthdateTime = moment.tz(driver.birthdate, 'Europe/Moscow').unix()
                if (from && to) {return (birthdateTime > from && birthdateTime < to)}
                else if (from) {return (birthdateTime > from)}
                else if (to) {return (birthdateTime < to)}
                else {return false}
            }).map((driver: any)=>driver.id)

            setFilterRequest((prev:any) => ({...prev, driverId: { in: resultArray } }))

            setMode(true)
            handleClose()
        }
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.driverId
            return state
        })
        setMode(false)
        handleClose()
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <Box sx={{display: 'flex', alignItems: 'center', gap: '12px', margin: '4px 0px'}}>
                <LocalizationProvider dateAdapter={AdapterMoment} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DemoItem label={<Typography sx={labelStyle}>C</Typography>}>
                        <DatePicker
                            sx={inputStyle}
                            slotProps={{
                                field: { clearable: false },
                                inputAdornment: { position: 'start' }
                            }}
                            format={'DD.MM.YYYY'}
                            value={dateFrom}
                            onChange={(e)=>setDateFrom( moment.tz(e, 'Europe/Moscow') )}
                            disableFuture={true}
                        />
                    </DemoItem>

                    <DemoItem label={<Typography sx={labelStyle}>По</Typography>}>
                        <DatePicker
                            sx={inputStyle}
                            slotProps={{
                                field: { clearable: false  },
                                inputAdornment: { position: 'start' }
                            }}
                            format={'DD.MM.YYYY'}
                            value={dateTo}
                            onChange={(e)=>setDateTo( moment.tz(e, 'Europe/Moscow') )}
                            disableFuture={true}
                        />
                    </DemoItem>
                </LocalizationProvider>
            </Box>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}