import { Button } from "@mui/material"
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { mainIcon, playButtonArea, playButtonAreaOnPause } from "../styles";

interface PlayButtonAreaProps {
    playing: boolean, 
    onClick: (e:any)=>void,
}

export const PlayButtonArea = ({playing, onClick}: PlayButtonAreaProps) => {
    return (
        <Button 
            sx={[playButtonArea, !playing?playButtonAreaOnPause:null]}
            onClick={onClick}
            //disableRipple
        >
            {!playing&&<PlayArrowRoundedIcon sx={mainIcon}/>}
        </Button> 
    )
}