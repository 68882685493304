export const videoWrapper = {
    position: 'relative',
    backgroundColor: '#000',
    overflow: 'hidden',

    fontSize: '14px',
}

export const videoPlayer = {
    position: 'absolute', //'relative',
    top: '0', left: '0',
} as React.CSSProperties

export const onFlipping = {
    transform: 'rotate(-90deg)',
    //scale: '1.33',
} as React.CSSProperties


export const videoControls = {
    position: 'absolute',
    bottom: '0',
    right: '0',
    left: '0',

    padding: '0.4em',

    height: '2.1em',

    backgroundColor: 'rgba(0,0,0,0.4)',
    color: '#FFFFFF',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1em',
}


// Icons & Buttons

export const icon = {
    color: '#FFFFFF',
    width: '1.3em',
    height: '1.3em',
}
export const mainIcon = {
    color: '#FFFFFF',
    width: '3em',
    height: '3em',
}

export const playerButton = {
    width: '1.5em',
    height: '1.5em',
    borderRadius: '50%',
}

export const playButtonArea = {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',

    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',

    backgroundColor: 'none',

    '& .MuiTouchRipple-child': { backgroundColor: 'rgba(0,0,0,0.2)' },
    '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' }
}
export const playButtonAreaOnPause = {
    backgroundColor: 'rgba(0,0,0,0.2) !important',
    '&:hover': {backgroundColor: 'none !important'},
    '& .MuiTouchRipple-child': { backgroundColor: 'rgba(0,0,0,0.1)' }
}


// Seek slider

export const seekSlider = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap:'1.5em',

    '& .MuiSlider-root': {
        color: '#FFFFFF',
    },
    '& .MuiSlider-thumb': {
        height: '17px',
        width: '17px',
        transition: 'all ease-out 0.15s'
    },
}