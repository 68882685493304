export const WarningIcon: React.FC = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_13950_359460)">
                <path d="M12 0.5C5.37321 0.5 0 5.87321 0 12.5C0 19.1268 5.37321 24.5 12 24.5C18.6268 24.5 24 19.1268 24 12.5C24 5.87321 18.6268 0.5 12 0.5ZM12 22.4643C6.49821 22.4643 2.03571 18.0018 2.03571 12.5C2.03571 6.99821 6.49821 2.53571 12 2.53571C17.5018 2.53571 21.9643 6.99821 21.9643 12.5C21.9643 18.0018 17.5018 22.4643 12 22.4643Z" fill="#F0901E"/>
                <path d="M12 6.5V13.5" stroke="#F0901E" stroke-width="2" stroke-linecap="round"/>
                <rect x="11" y="16.5" width="2" height="2" rx="1" fill="#F0901E"/>
            </g>
            <defs>
                <clipPath id="clip0_13950_359460">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}