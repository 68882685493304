import { Navigate, useLocation } from "react-router"
import { getMedicRequest } from "../../services/MedicService"
import { setMainInfo } from "../../slices/CommonInfoSlice"
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux"
import { getCardsRequest } from "../../services/DriverCardService"
import { setMedCardInfo } from "../../pages/Meds/MedExamsSlice"

import { useEffect, useMemo, useState } from "react"
import { formToJSON } from "axios"
import { ModalNames, setDocumentsValid, setECPFlashLoaded } from "../../slices/AppSlice"
import { DOC_STATUSES, IDocument } from "../../types/documentTypes"
import { useModal } from "../layout/components/modals/useModal"
import { checkECPPrivateKey } from "../../services/cryptoService"
import { useNavigate } from "react-router-dom"
import { GENERAL_STATUSES } from "../../pages/Documents/utils"


export const getMedic = async () => {
  try {
    const response = await getMedicRequest()
    console.log('MEDIC')
    console.log(response)
    return response.data
  } catch (e) {
    console.log('MEDIC')
    console.log(e)
  }
}


export const checkNotAdddedDocuments = (documents: IDocument[]) => {
  console.log('documents on check')
  console.log(documents)
  console.log(documents.length === 4)
  return documents.length === 4
}

export const checkDocumentsByStatus = (documents: IDocument[], status: DOC_STATUSES) => {
  return !!documents.find((document: IDocument) => document.document_status === status)
}


export const checkUserValid = async (documents: IDocument[] | []) => {


  const errorModals: any = [];
  let isValid = true;
  // const isECP = await checkECPPrivateKey()
  // // const isECP = false
  // if (!isECP) {
  //   errorModals.push(ModalNames.ECP)
  // }
  // dispatch(setECPFlashLoaded(isECP))
  if (!checkNotAdddedDocuments(documents)) {
    errorModals.push(ModalNames.NOT_ADDED_DOCUMENTS)
  }
  if (checkDocumentsByStatus(documents, DOC_STATUSES.EXPIRES)) {
    errorModals.push(ModalNames.EXPIRES_DOCUMENTS)
  }
  if (checkDocumentsByStatus(documents, DOC_STATUSES.EXPIRED)) {
    errorModals.push(ModalNames.EXPIRED_DOCUMENTS)
  }
  if (checkDocumentsByStatus(documents, DOC_STATUSES.NEW)) {
    isValid = false;
  }
  if (errorModals.length > 0) {
    if (errorModals.length === 1 && errorModals.includes(ModalNames.EXPIRES_DOCUMENTS)) {
      isValid = true
    } else isValid = false
  }
  console.log(errorModals)


  return {
    errorModals: errorModals,
  }
  // if (errorModals.length > 0) {
  //   console.log('Проверка')

  //   console.log(false)
  //   if (errorModals.length === 1 && errorModals[0] === ModalNames.EXPIRES_DOCUMENTS) {
  //     return true
  //   } else return false

  // } else {
  //   console.log('Проверка')
  //   console.log(true)
  //   return true
  // }
}




export const PrivateRoute = ({ children }: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAuth = localStorage.getItem("isAuth")
  const dispatch = useAppDispatch();
  const { isECPFlashLoaded, isDocumentsValid } = useAppSelector(state => state.appReducer)
  const { changeCurrentModal, removeModal } = useModal()
  const { medic } = useAppSelector(state => state.mainReducer)
  // const { documents, id } = medic;


  const { addModal, addModalGroup } = useModal()





  useEffect(() => {
    // if (isAuth && !!medic.id) {
    (async () => {
      const profile = await getMedic()
      dispatch(setMainInfo(profile))
      if (!!profile.id) {
        // dispatch(setDocumentsValid(await isUserValid(profile.documents)))
        //changeCurrentModal(ModalNames.CHECKING_ECP)
        const isECP = await checkECPPrivateKey()
        const userValidInfo = await checkUserValid(profile.documents)
        const errorArray = userValidInfo.errorModals
        if (!isECP) errorArray.push(ModalNames.ECP)
        dispatch(setECPFlashLoaded(!errorArray.includes(ModalNames.ECP)))
      console.log('статус медика', profile.documents_general_status)
      console.log('нужный энам статус', GENERAL_STATUSES.ACCEPTED)
      console.log(profile.documents_general_status===GENERAL_STATUSES.ACCEPTED)
        const isUserValid = profile.documents_general_status===GENERAL_STATUSES.ACCEPTED && isECP
        dispatch(setDocumentsValid(isUserValid))
        isUserValid ? navigate('/meds') : navigate('/documents')
        addModalGroup(errorArray)
      }

    })();
    // }
  }, [isAuth]);


  // useEffect(() => {
  //   console.log('Проверка документов при входе', isDocumentsValid)
  //   isDocumentsValid && isECPFlashLoaded ? navigate('/meds') : navigate('/documents')
  // }, [isDocumentsValid])

  if (isAuth !== 'true') {

    return <Navigate to='/login' state={{ from: location }}></Navigate>
  }
  // if (!isDocumentsValid) {
  //   //  return <Navigate to='/documents' state={{ from: location }}></Navigate>
  // }
  else return children;
}

export { checkECPPrivateKey }
