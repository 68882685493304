import {$api} from "../api";
import { TimeUnit, Timer } from "../modules/Timer/Timer-types";


export interface getTimersRequestParams 
{
    page?:     number
    per_page?: number
    id?:       number
}
export const getTimersRequest = (params?: getTimersRequestParams) => {
    return $api.get('/v1/timers', {params})
}

export const createTimerRequest = (timer: Timer) => {
    return $api.post('/v1/timers', timer)
}

export const updateTimerRequest = (timer: Timer) => {
    return $api.patch(`/v1/timers/${timer.id}`, timer)
}

export const createTimerTimeUnitRequest = (timerId: number, timeUnit: TimeUnit) => {
    return $api.post(`/v1/timers/${timerId}/units`, timeUnit)
}