import { SvgIcon } from "@mui/material"

export const EmployeesIcon = (props: any) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.625 4.375C3.5625 4.375 1.875 6.0625 1.875 8.125C1.875 9.365 2.48562 10.4688 3.41812 11.1525C1.94758 11.8819 0.877575 13.3099 0.664073 15.0015C0.620849 15.3439 0.904822 15.625 1.25 15.625C1.59518 15.625 1.86954 15.3431 1.92597 15.0026C2.22081 13.2234 3.75965 11.875 5.625 11.875C7.49035 11.875 9.02919 13.2234 9.32403 15.0026C9.38046 15.3431 9.65482 15.625 10 15.625C10.3452 15.625 10.6195 15.3431 10.676 15.0026C10.9708 13.2234 12.5097 11.875 14.375 11.875C16.2403 11.875 17.7792 13.2234 18.074 15.0026C18.1305 15.3431 18.4048 15.625 18.75 15.625C19.0952 15.625 19.3792 15.3439 19.3359 15.0015C19.1225 13.3095 18.0525 11.8819 16.5819 11.1525C17.0591 10.8048 17.4475 10.3494 17.7156 9.82336C17.9838 9.29731 18.124 8.71544 18.125 8.125C18.125 6.0625 16.4375 4.375 14.375 4.375C12.3125 4.375 10.625 6.0625 10.625 8.125C10.625 9.365 11.2356 10.4688 12.1681 11.1525C11.255 11.6015 10.4991 12.3164 10 13.2031C9.5009 12.3164 8.74504 11.6015 7.83187 11.1525C8.30907 10.8048 8.6975 10.3494 8.96563 9.82336C9.23376 9.29731 9.37401 8.71544 9.375 8.125C9.375 6.0625 7.6875 4.375 5.625 4.375ZM5.625 5.625C7.01437 5.625 8.125 6.73563 8.125 8.125C8.125 9.51437 7.01437 10.625 5.625 10.625C4.23563 10.625 3.125 9.51437 3.125 8.125C3.125 6.73563 4.23563 5.625 5.625 5.625ZM14.375 5.625C15.7644 5.625 16.875 6.73563 16.875 8.125C16.875 9.51437 15.7644 10.625 14.375 10.625C12.9856 10.625 11.875 9.51437 11.875 8.125C11.875 6.73563 12.9856 5.625 14.375 5.625Z" 
                fill="currentColor"
            />
        </SvgIcon>
    )
}