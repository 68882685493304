import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { IMain, IMedic } from "./types";
import { IDocument } from "../types/documentTypes";
import { IMedCard } from "../pages/Meds/types";
import { GENERAL_STATUSES } from "../pages/Documents/utils";

const initialShift = {
    id: null,
    date: null,
    from: null,
    to: null,
    isWeekend: null,
    employeeId: null
}

const initialState: IMain = {
    medic: {
        id: null,
        firstname: null,
        lastname: null,
        middlename: null,
        roles: null,
        current_shift: initialShift,
        next_shift: initialShift,
        current_timer: null,
        documents_general_status: GENERAL_STATUSES.NOT_ACCEPTED,
        number_of_created_cards: 0,
        documents: []
    },
    isActive: null,
}



export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setMainInfo(state, action: PayloadAction<IMedic>) {
            state.medic = action.payload
            console.log(state)
        },
        setDocuments(state, action: PayloadAction<IDocument[]>) {
            if (!!state.medic.documents) state.medic.documents = action.payload
        },
        setMedicInfo(state, action: PayloadAction<IMedic>) {
            state.medic = action.payload
            console.log(state.isActive)
        },
        setIsActive(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload
            //console.log(state.isActive)
        },
    },
})

export const {
    setMainInfo,
    setDocuments,
    setMedicInfo,
    setIsActive,
} = mainSlice.actions

export default mainSlice.reducer