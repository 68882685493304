
export const phoneMaskValidate=(num:string)=>{
    const value = num.replace(/\D+/g, "");
  const numberLength = 10;

  let result='';
  for (let i = 0; i < value.length && i < numberLength; i++) {
    switch (i) {
      case 0:
        result += '(';
        break;
      case 3:
        result += ") ";
        break;
      case 6:
        result += "-";
        break;
      case 8:
        result += "-";
        break;
      default:
        break;
    }
    result += value[i];
  }
  return result;
}

export const parseLocationPathName=(pathName:string)=>{
    let parsed = pathName.split('/');
    parsed.shift();
    return parsed;
}

export const getPathLinks=(paths:string[])=>{
  let pathLinks:any =[];
  paths.forEach((el:string, key:number)=>{
      switch(el){
          case 'main':{
              pathLinks.push({
                  label:'Главная',
                  to:'main' 
              })
              break;
          }
          case 'meds':{
              pathLinks.push({
                  label:'Медосмотры',
                  to:'meds' 
              })
              break;
          }
          case 'reports':{
              pathLinks.push({
                  label:'Отчеты',
                  to:'reports' 
              })
              break;
          }
          case 'employees':{
              pathLinks.push({
                  label:'Сотрудники',
                  to:'employees' 
              })
              break;
          }
          case 'shifts':{
              pathLinks.push({
                  label:'График смен',
                  to:'shifts' 
              })
              break;
          }
          case 'documents':{
            pathLinks.push({
                label:'Документы',
                to:'documents' 
            })
            break;
        }
          default:{
              pathLinks.push({
                  label:el,
              })
              break;
          }
      }
  })
  return pathLinks; 
}