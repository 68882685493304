import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
  } from "@mui/material";
  import React from "react";
  import { Controller } from "react-hook-form";
  
  interface Option {
    label: string,
    value: string
  }
  
  export const ControlledRadioGroup = ({
    name,
    control,
    label,
    options,
  }:any) => {
    const generateRadioOptions = () => {
      return options.map((singleOption: Option) => (
        <FormControlLabel
          value={singleOption.value}
          label={singleOption.label}
          control={<Radio />}
        />
      ));
    };
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <RadioGroup value={value} onChange={onChange}>
              {generateRadioOptions()}
            </RadioGroup>
          )}
        />
      </FormControl>
    );
  };