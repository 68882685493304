import { Modal, Box, Button } from '@mui/material'

export const ModalPauseTimer = (props: {
    open: boolean
    cardsAwait: number
    onPause: () => void
    onStay: () => void
    counter: number
}) => {

    return (
        <Modal
            open={props.open}
            sx={{
                backgroundColor: 'rgb(0,0,0, 0.1)',
                zIndex: 999999,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'background.paper',
                    width: '312px',
                    //maxHeight: '320px',
                    borderRadius: '4px',
                    border: 'none',
                    padding: '24px',

                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '24px',

                    '& h2': {
                        fontSize: '24px',
                        fontWeight: '600',
                        lineHeight: '28px',
                        color: '#3E3E3E',
                        //whiteSpace: 'nowrap',
                    },

                    '& p': {
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#3E3E3E',
                        lineHeight: '22px',
                    },
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                    }}
                >
                    <h2>
                    { props.cardsAwait === 0 ? 
                            `Поставить смену на\n
                            паузу?`
                            :
                            `Внимание!`
                    }
                    </h2>

                    <p>
                        { props.cardsAwait === 0 ? 
                            null
                            : 
                            `Необходимо задержаться на смене\n
                            на 10 минут, пока поток\n
                            пациентов не уменьшится - на\n
                            очереди ${props.cardsAwait} чел.`
                        }
                    </p>
                    <p>
                        Смена будет поставлена на паузу<br/>
                        автоматически через {props.counter} сек.
                    </p>
                </Box>

                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M64.5 32C64.5 49.6731 50.1731 64 32.5 64C14.8269 64 0.5 49.6731 0.5 32C0.5 14.3269 14.8269 0 32.5 0C50.1731 0 64.5 14.3269 64.5 32ZM36.5856 27.9132V48.3388C36.5856 50.5949 34.7566 52.4239 32.5005 52.4239C30.2443 52.4239 28.4154 50.5949 28.4154 48.3388V27.9132C28.4154 25.6571 30.2443 23.8281 32.5005 23.8281C34.7566 23.8281 36.5856 25.6571 36.5856 27.9132ZM32.5005 19.7431C34.7566 19.7431 36.5856 17.9142 36.5856 15.658C36.5856 13.4019 34.7566 11.5729 32.5005 11.5729C30.2443 11.5729 28.4154 13.4019 28.4154 15.658C28.4154 17.9142 30.2443 19.7431 32.5005 19.7431Z" 
                        fill="#FF4D4F"
                    />
                </svg>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '24px'
                    }}    
                >
                    <Button
                        onClick={props.onPause}
                        sx={{
                            width: '120px',
                            height: '36px',
                            backgroundColor: '#FF4D4F',
                            padding: '10px 12px',
                            borderRadius: '4px',
                            color: '#FFFFFF',
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '16px',
                            whiteSpace: 'nowrap',
                            '&:hover': {
                                backgroundColor: '#DD2B2D'
                            }
                        }}
                    >
                        {/* {props.cardsAwait === 0 ? 'На паузу' : 'Всё равно уйти'} */}
                        На паузу
                    </Button>
                    <Button
                        onClick={props.onStay}
                        sx={{
                            width: '120px',
                            height: '36px',
                            backgroundColor: '#33C341',
                            padding: '10px 12px',
                            borderRadius: '4px',
                            color: '#FFFFFF',
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '16px',
                            whiteSpace: 'nowrap',
                            '&:hover': {
                                backgroundColor: '#11A120',
                            }
                        }}
                    >
                        Остаться
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}