export const getFormatted = (seconds:number) => {

    const m = Math.floor(seconds / 60)
    const s = Math.round(seconds-m*60)
    return (
        <span>
            {m>0||s===60?m+':':'0:'}
            {s>0&&s!==60?(s.toString().length>1?s:'0'+s):'00'}
        </span>
    )
}