import { useEffect, useState } from "react";
import { useReportsPageModel } from "./ReportPageModel"
import { CustomTable } from "./CustomTable";
import { Autocomplete, Box, Button, CircularProgress, Divider, Pagination, PaginationItem, Paper, Popper, Tab, TextField, Typography } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { activeColor, buttonStyle } from "../Meds/styles/styles";
import { GearIcon } from "../../modules/Sidebar/assets/GearIcon";
import { DownloadIcon } from "./assets/DownloadIcon";
import { MedCardStatuses } from "../Meds/types";
import { downloadFile } from "./drawers/ReportCard";
import { makeStyles } from '@mui/styles'
import { excelArgs, getExcelReportTableRequest } from "../../services/fileService";
import { ViewSettingsModal } from "./components/ViewSettingsModal";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useReferralModel } from "./ReportReferralModel";
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const tabStyle = {
    fontWeight: '500',
    fontSize: '16px',
    color: '#BDBDBD',
    textTransform: 'none',
    padding: '0px 4px 0px 4px',
    marginTop: '16px',
    minHeight: '32px',
    '&.Mui-selected': {
        color: '#009FDA',
    },
}

const autocompleteNumberStyle = {
    maxWidth: '60px', maxHeight: '32px', 
    '& .MuiInputBase-root': {padding: '4px 0px 0px 8px', fontSize: '16px', '& .MuiInputBase-input': {padding: '2px 4px 6px 4px', color: '#565656'}}, 
    '& .MuiAutocomplete-popupIndicator': {margin: '2px -6px 0px 0px', color: '#BDBDBD', scale: '1.3', '&.MuiAutocomplete-popupIndicatorOpen': {transform: 'rotate(-90deg)', transition: '0.1s ease-out',}},
    '& .MuiButtonBase-root': {padding: '0px'}, '& :not(focused)': { transition: 'all 0.1s ease-out' },
    '& .MuiOutlinedInput-root': {'&.Mui-focused': {transition: '0.1s ease-out', boxShadow: '0px 0px 0px 1px #FFF inset', borderColor: '#FFF'}}
} 
const autocompletePaper = {
    //maxHeight: '200px',
}
const autocompletePopper = {
    '*::-webkit-scrollbar': {
        width: '0.5em'
    },
    '*::-webkit-scrollbar-track': {
        backgroundColor: '#EEEEEE',
    },
    '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#BFBFBF',
        borderRadius: '2px',
    },
}


export const ReportPage = () => {


    const reportModel = useReportsPageModel()
    const referralModel = useReferralModel()

    const [exelLoading, setExelLoading] = useState<boolean>(false)

    const getExcelReportTable = async () => {
        try {
            setExelLoading(true)
            const response = await getExcelReportTableRequest({...reportModel.filterRequest, ...reportModel.sortingRequest})
            setExelLoading(false)
            console.log('excel')
            console.log(response)
            const localUrl = URL.createObjectURL(response.data)
            downloadFile(localUrl, 'table.xlsx')
        } catch (e) {

        }

        return {

        }
    }

    const useStyles: any = makeStyles((theme: any) => ({
        selected: {
            backgroundColor: 'blue',
            color: 'white',
        },
    }),
    );
    const classes = useStyles();

    return (
        <Box sx={{paddingBottom: '1px'}}>
            {/* <Divider sx={{color:'#EEEEEE'}}></Divider>
             <Box sx={{ backgroundColor: 'white', padding: '16px 24px' }}>
            <   Typography sx={{ fontFamily: 'Roboto', fontSize: '24px', fontWeight: '500', lineHeight: '32px' }}>Отчеты</Typography>
            </Box> */}

            <TabContext value={reportModel.tabValue}>

            <Box sx={{ backgroundColor: 'white', padding: '16px 24px 0px 24px', position: 'sticky', top: '65px', zIndex: '9', boxShadow: '0px -1px 0px 0px #EEEEEE'}}>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '24px', fontWeight: '500', lineHeight: '32px' }}>Отчеты</Typography>
                <TabList 
                    sx={{marginTop: '-10px', '& .MuiTabs-indicator': {backgroundColor: '#009FDA'}, '& .MuiTabs-flexContainer': {gap: '16px'}}} 
                    onChange={(_, value) => reportModel.setTabValue(value)} 
                    >
                        <Tab sx={tabStyle} label='Медицинский журнал' value={'MED_LOG'} />
                        <Tab sx={tabStyle} label='Направления к врачу' value={'MED_REFERRAL'} />
                </TabList>
            </Box>

            <TabPanel value={'MED_LOG'} sx={{width: '100%', height: '100%', margin: '0px', padding: '0px'}}>

                <Paper
                    sx={{
                        margin:'17px 16px 15px 16px',
                        boxShadow: 'none',
                        padding: '16px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px'
                    }}
                >


                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px', lineHeight: '23px' }}>
                            Медицинский журнал
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '16px' }}>
                            <Button
                                onClick={() => { getExcelReportTable(/*{ status: MedCardStatuses.FINISHED }*/) }}
                                variant="contained"
                                startIcon={exelLoading ? <CircularProgress sx={{color: '#FFFFFF'}} size={'20px'}/> : <DownloadIcon />}
                                sx={{
                                    ...buttonStyle, backgroundColor: activeColor,
                                    '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' },
                                    fontFamily: 'Roboto',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '23px',
                                    padding: '8px 12px',
                                }}
                            >
                                Загрузить весь журнал
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    ...buttonStyle, backgroundColor: activeColor,
                                    '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' },
                                    fontFamily: 'Roboto',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '23px',
                                    padding: '8px 12px',
                                    minWidth: '0px',
                                }}
                                onClick={() => reportModel.setOpenViewSettings(!reportModel.openViewSettings)}
                            >
                                <GearIcon />
                            </Button>
                        </Box>
                    </Box>


                    <CustomTable openDrawer={true} data={reportModel.reports} columns={reportModel.columns} setFilterRequest={reportModel.setFilterRequest} reportsStatistics={reportModel.reportsStatistics}></CustomTable>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', color: '#565656' }}>
                            Показывать
                            <Autocomplete
                                onInputChange={(_, value) => {reportModel.setPerPage((prev:number)=>{
                                    if (!value.match(/^(\s*|\d+)$/)) return prev
                                    if (value.length > 1 && value[0] === '0') return prev
                                    if (Number(value) > 50) return prev
                                    return Number(value)
                                })}}
                                inputValue={reportModel.perPage ? reportModel.perPage.toString() : ''}
                                sx={autocompleteNumberStyle}
                                freeSolo forcePopupIcon disableClearable
                                filterOptions={(opt)=>opt}
                                popupIcon={<KeyboardArrowRightRoundedIcon />}
                                PaperComponent={(props) => (<Paper elevation={2} {...props} sx={autocompletePaper} />)}
                                PopperComponent={(props) => ( <Popper {...props} sx={autocompletePopper} />)}
                                options={Array.from(new Array(50)).map((e, i)=>i+1)}
                                renderInput={(params)=><TextField {...params} placeholder="0"/>}
                            />
                            из {50}
                        </Box>
                        <Pagination
                            count={reportModel.pageQty}
                            page={reportModel.page}
                            onChange={(_, num) => reportModel.changePage(num)}
                            className={classes.root}
                            variant="outlined" shape="rounded"
                            sx={{
                                //marginY: 3, marginX: "auto",
                                '&  .Mui-selected': {
                                    backgroundColor: '#009FDA !important',
                                    color: 'white !important'
                                },
                                '& .Mui-selected:hover': {
                                    backgroundColor: 'rgb(0,159,218) !important',
                                    color: 'white !important'
                                },
                            }}
                            renderItem={(item) => (
                                <PaginationItem

                                    {...item}

                                    component={NavLink}
                                    to={`/reports/${item.page}`}
                                // to={'/organisations'}

                                />
                            )}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', color: '#565656' }}>
                            Страница
                            <Autocomplete
                                onInputChange={(_, value) => {reportModel.changePage((prev:number)=>{
                                    if (!value.match(/^(\s*|\d+)$/)) return prev
                                    if (value.length > 1 && value[0] === '0') return prev
                                    if (Number(value) > reportModel.pageQty) return prev
                                    return Number(value)
                                })}}
                                inputValue={reportModel.page ? reportModel.page.toString() : ''}
                                sx={autocompleteNumberStyle}
                                freeSolo forcePopupIcon disableClearable
                                filterOptions={(opt)=>opt}
                                popupIcon={<KeyboardArrowRightRoundedIcon />}
                                PaperComponent={(props) => (<Paper elevation={2} {...props} sx={autocompletePaper} />)}
                                PopperComponent={(props) => ( <Popper {...props} sx={autocompletePopper} />)}
                                options={Array.from(new Array(reportModel.pageQty)).map((e, i)=>i+1)}
                                renderInput={(params)=><TextField {...params} placeholder="0"/>}
                            />
                            из {reportModel.pageQty}
                        </Box>
                    </Box>

                    <ViewSettingsModal 
                        open={reportModel.openViewSettings} 
                        setOpen={reportModel.setOpenViewSettings} 
                        columns={reportModel.columns}
                        setColumns={reportModel.setColumns}
                    />

                </Paper>

            </TabPanel>

            <TabPanel value={'MED_REFERRAL'} sx={{width: '100%', height: '100%', margin: '0px', padding: '0px'}}>

                <Paper
                    sx={{
                        margin:'17px 16px 15px 16px',
                        boxShadow: 'none',
                        padding: '16px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px'
                    }}
                >


                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px', lineHeight: '23px' }}>
                            Направления к врачу
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '16px' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    ...buttonStyle, backgroundColor: activeColor,
                                    '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' },
                                    fontFamily: 'Roboto',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '23px',
                                    padding: '8px 12px',
                                    minWidth: '0px',
                                }}
                                onClick={() => referralModel.setOpenViewSettings(!referralModel.openViewSettings)}
                            >
                                <GearIcon />
                            </Button>
                        </Box>
                    </Box>

                    <CustomTable openDrawer={false} data={referralModel.referrals} columns={referralModel.columns} setFilterRequest={referralModel.setFilterRequest}></CustomTable>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', color: '#565656' }}>
                            Показывать
                            <Autocomplete
                                onInputChange={(_, value) => {referralModel.setPerPage((prev:number)=>{
                                    if (!value.match(/^(\s*|\d+)$/)) return prev
                                    if (value.length > 1 && value[0] === '0') return prev
                                    if (Number(value) > 50) return prev
                                    return Number(value)
                                })}}
                                inputValue={referralModel.perPage ? referralModel.perPage.toString() : ''}
                                sx={autocompleteNumberStyle}
                                freeSolo forcePopupIcon disableClearable
                                filterOptions={(opt)=>opt}
                                popupIcon={<KeyboardArrowRightRoundedIcon />}
                                PaperComponent={(props) => (<Paper elevation={2} {...props} sx={autocompletePaper} />)}
                                PopperComponent={(props) => ( <Popper {...props} sx={autocompletePopper} />)}
                                options={Array.from(new Array(50)).map((e, i)=>i+1)}
                                renderInput={(params)=><TextField {...params} placeholder="0"/>}
                            />
                            из {50}
                        </Box>
                        <Pagination
                            count={referralModel.pageQty}
                            page={referralModel.page}
                            onChange={(_, num) => referralModel.changePage(num)}
                            className={classes.root}
                            variant="outlined" shape="rounded"
                            sx={{
                                //marginY: 3, marginX: "auto",
                                '&  .Mui-selected': {
                                    backgroundColor: '#009FDA !important',
                                    color: 'white !important'
                                },
                                '& .Mui-selected:hover': {
                                    backgroundColor: 'rgb(0,159,218) !important',
                                    color: 'white !important'
                                },
                            }}
                            renderItem={(item) => (
                                <PaginationItem

                                    {...item}

                                    component={NavLink}
                                    to={`/reports/${item.page}`}
                                // to={'/organisations'}

                                />
                            )}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', color: '#565656' }}>
                            Страница
                            <Autocomplete
                                onInputChange={(_, value) => {referralModel.changePage((prev:number)=>{
                                    if (!value.match(/^(\s*|\d+)$/)) return prev
                                    if (value.length > 1 && value[0] === '0') return prev
                                    if (Number(value) > referralModel.pageQty) return prev
                                    return Number(value)
                                })}}
                                inputValue={referralModel.page ? referralModel.page.toString() : ''}
                                sx={autocompleteNumberStyle}
                                freeSolo forcePopupIcon disableClearable
                                filterOptions={(opt)=>opt}
                                popupIcon={<KeyboardArrowRightRoundedIcon />}
                                PaperComponent={(props) => (<Paper elevation={2} {...props} sx={autocompletePaper} />)}
                                PopperComponent={(props) => ( <Popper {...props} sx={autocompletePopper} />)}
                                options={Array.from(new Array(referralModel.pageQty)).map((e, i)=>i+1)}
                                renderInput={(params)=><TextField {...params} placeholder="0"/>}
                            />
                            из {referralModel.pageQty}
                        </Box>
                    </Box>

                    <ViewSettingsModal 
                        open={referralModel.openViewSettings} 
                        setOpen={referralModel.setOpenViewSettings} 
                        columns={referralModel.columns}
                        setColumns={referralModel.setColumns}
                    />

                </Paper>

            </TabPanel>

            </TabContext>

        </Box>
    )
}