import { Box, Button, Fab, Stack } from "@mui/material"
import LicenceCheckAccepted from '../assets/LicenceCheckAccepted.png'
import LicenceCheckDeclined from '../assets/LicenceCheckDeclined.png'


interface DriverIdentResultProps {
    approve: () => void
    decline: React.ReactEventHandler<{}>;
}

export const DriverIdentResult = (props: DriverIdentResultProps) => {

    const buttonStyle = {
        //width: '250px',
        width: '100%',
        height: '48px',
        borderRadius: '4px',
        padding: '5px 16px',
        color: 'white',
        boxShadow: 'none',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '22px',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            borderColor: 'none',
        },
        '&:focus': {
            boxShadow: 'none',
            borderColor: 'none',
        },
        '&:disabled': {
            boxShadow: 'none',
            borderColor: 'none',
            backgroundColor: '#8E8E8E'
        },
        '& .MuiTouchRipple-child': {
            backgroundColor: 'black'
        }
    }

    return (
        <Box sx={{
            backgroundColor: 'white',
            fontFamily: 'Roboto',
            //width: '556px',
            width: '100%',
            height: '360px',
            borderRadius: '4px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        }}>

            <h3 style={{
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '26px',
                color: '#3E3E3E',
            }}>
                Результаты
            </h3>

            <Stack direction={'column'} spacing={'16px'}>
                <Stack direction={'row'} justifyContent={'space-around'} >
                    <img src={LicenceCheckDeclined} alt="Подтверждено" />
                    <img src={LicenceCheckAccepted} alt="Подтверждено" />
                </Stack>

                <Stack direction={'row'} justifyContent={'space-around'} gap={'24px'}>
                    <Button 
                        onClick={props.decline}
                        sx={[buttonStyle, {
                            backgroundColor: '#FF4D4F',
                            '&:hover': {backgroundColor: '#DD2D2F'}
                        }]}>
                        Отклонить
                    </Button>
                    <Button 
                        onClick={props.approve}
                        sx={[buttonStyle, {
                            backgroundColor: '#33C341',
                            '&:hover': {backgroundColor: '#11A12D'}
                        }]}>
                        Подтвердить
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}