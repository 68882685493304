import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../store/hooks/redux';
import {setReg, setPasRec, setAuth, setRegistrationComplete, setRecoveryComplete} from './LoginSlice';
import {FullfilledData } from './login-types';


export const useLoginModel=()=>{

    const { isNeedToReg, isNeedToPasRecovery, isNeedToAuth, isRecoveryComplete, isRegistrationComplete, fullfilledData}= useAppSelector(state => state.logReducer)
    const dispatch = useAppDispatch();

    const renderReg=() =>  {dispatch(setReg())}
    const renderPasRec=()=>  {dispatch(setPasRec())}
    const renderAuth=()=>{dispatch(setAuth())}
    const setRegistrationSuccess=(data:FullfilledData)=>{dispatch(setRegistrationComplete(data))}
    const setRecoverySuccess=(data:FullfilledData)=>{dispatch(setRecoveryComplete(data))}
    

    const setAuthOnRecoverySuccess=(data:FullfilledData)=>{
        renderAuth()
        setRecoverySuccess(data)
    }
    const setAuthOnRegistrationSuccess=(data:FullfilledData)=>{
        renderAuth()
        setRegistrationSuccess(data)
    }
    return {
        isNeedToReg,
        isNeedToPasRecovery,
        isNeedToAuth,
        isRecoveryComplete,
        isRegistrationComplete,
        fullfilledData,
        renderPasRec,
        renderReg,
        renderAuth,
        setRegistrationSuccess,
        setRecoverySuccess,
        setAuthOnRecoverySuccess,
        setAuthOnRegistrationSuccess
    }
}