import { $api, $file } from "../api"
import { MedCardStatuses } from "../pages/Meds/types"





export const createFileRequest = async (file: any) => {
    return await $api.post(`/v1/files`, file)
}

export const getBase64FileRequest = (name: string) => {

    return $api.get(`/v1/files/${name}/base64`)
}

export const createFileFromBase64 = (data: string) => {

    return $api.post(`/v1/files/base64`, { data: data })
}


export const getBufferFileRequest = (name: string) => {

    return $api.get(`/v1/files/${name}/buffer`)
}



export interface excelArgs {
    to?: string,
    from?: string,
    status?: MedCardStatuses,
    id?: number,
    per_page?: number,
    page?: number,
}

export const getExcelReportTableRequest = (query: any) => {
    return $file.get('/v1/cards/excel' + `?query=${JSON.stringify(query)}`)
}