import Moment from "react-moment";



export enum DOC_STATUSES{
    NEW = 'Новый',
    CHECKING = "На проверке",
    CHECKED = 'Проверен',
    ERROR = 'Ошибка',//'Ошибка в данных',
    EXPIRES = 'Истекает срок',
    EXPIRED = 'Просрочен',
    MISSED = ' Документ отсутствует',
}

export enum DOC_TYPES{
    EDUCATION_CERIFICATE = "Удостоверение об основном образовании",
    EDUCATION_DIPLOMA = "Диплом об основном образовании",
    ACCREDIATAION_CERTIFICATE = "Аккредитация специалиста",
    ECP = "Электронная подпись (ЭЦП)",
    QUALIFICATION_CERTIFICATE = "Удостоверение о повышении квалификации"

}

export enum DOC_GENERAL_STATUSES{
    ACCEPTED ='Допущен', 
}


export interface IDocument{
    isValidityPeriodChecked?: boolean,
    id?: number,
    registry_number?:string,
    url:string[] ,
    expires_at?:any,
    issued_at?:any,
    validity_period?:number | null ,
    type:DOC_TYPES ,
    status?:string ,
    comment?:string,
    document_status?:DOC_STATUSES , 
}


