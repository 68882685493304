import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { IMedCard } from "../Meds/types";







interface Initial {
  reports:IMedCard[] | null
}

const initialState: Initial = {
    reports:null
};




export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports(state, action: PayloadAction<IMedCard[] | null>) {
      state.reports = action.payload;
    },

  },
})

export const {
  setReports
} = reportsSlice.actions

export default reportsSlice.reducer