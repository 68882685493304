import { Modal, Box, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import {IModalProps } from "./props"


export const ModalDocumentProcessing = ({open, handleClose}:IModalProps) => {
    const navigate = useNavigate()
    return (
        <Modal
            open={open}
            sx={{
                backgroundColor: 'rgb(0,0,0, 0.1)',
                zIndex: 999999,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'background.paper',

                    width: '327px',
                    //maxHeight: '320px',
                    borderRadius: '4px',
                    border: 'none',
                    padding: '24px',

                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '24px',

                    '& h2': {
                        fontSize: '24px',
                        fontWeight: '600',
                        lineHeight: '28px',
                        color: '#3E3E3E',
                        //whiteSpace: 'nowrap',
                    },

                    '& p': {
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#3E3E3E',
                        lineHeight: '22px',
                    },
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                    }}
                >
                    <h2>
                        Документы в обраотке
                    </h2>

                    <p>
                        Все документы успешно<br/> 
                        отправлен на проверку старшему<br/>
                        медику, ожидайте изменение<br/>
                        статуса личного кабинета, чтобы<br/>
                        приступить к исполнению
                    </p>
                </Box>

                <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M64.5 32C64.5 49.6731 50.1731 64 32.5 64C14.8269 64 0.5 49.6731 0.5 32C0.5 14.3269 14.8269 0 32.5 0C50.1731 0 64.5 14.3269 64.5 32ZM32.5 23.9147C34.7091 23.9147 36.5 25.7055 36.5 27.9147V48.3402C36.5 50.5494 34.7091 52.3402 32.5 52.3402C30.2909 52.3402 28.5 50.5494 28.5 48.3402V27.9147C28.5 25.7055 30.2909 23.9147 32.5 23.9147ZM32.418 19.5762C34.6271 19.5762 36.418 17.7853 36.418 15.5762C36.418 13.367 34.6271 11.5762 32.418 11.5762C30.2088 11.5762 28.418 13.367 28.418 15.5762C28.418 17.7853 30.2088 19.5762 32.418 19.5762Z" fill="#509ED6"/>
                </svg>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '24px'
                    }}    
                >
                    <Button
                        onClick={()=>{
                            navigate('/documents')
                            handleClose()
                        }}
                        sx={{
                            width: '279px',
                            height: '36px',
                            backgroundColor: '#009FDA',
                            padding: '10px 12px',
                            borderRadius: '4px',
                            color: '#FFFFFF',
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '16px',
                            whiteSpace: 'nowrap',
                            '&:hover': {
                                backgroundColor: '#007FBA',
                            }
                        }}
                    >
                        К документам
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}