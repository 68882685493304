import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import {IModalProps } from "./props"
import { useNavigate } from 'react-router-dom';

const ModalECPSigningError = ({open, handleClose}:IModalProps) => {

  const navigate = useNavigate()

  const modalBoxStyle = {
    border: 'none',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    width: 312,
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',

    '& h2': {
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: 'normal',
      color: '#3E3E3E',
    },

    '& p': {
      color: '#3E3E3E',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '130%',
    },
  };

  return (
    <Modal
    disableEscapeKeyDown
    open={open}>
      <Box
        sx={modalBoxStyle}
        //padding={'50px 30px 30px 30px'}
        padding={'24px'}
        display={'flex'}
        flexDirection={'column'}
        gap={'20px'}>
        <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
          <Typography variant='h6' component='h2'>
            Ошибка
          </Typography>
          <Typography component='p'>
            При проверке сертификата произошла ошибка. <br /> Информация об
            ошибке уже передана Старшему медику
          </Typography>
        </Box>

        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='64'
          height='64'
          viewBox='0 0 64 64'
          fill='none'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM36.0856 27.9132V48.3388C36.0856 50.5949 34.2566 52.4239 32.0005 52.4239C29.7443 52.4239 27.9154 50.5949 27.9154 48.3388V27.9132C27.9154 25.6571 29.7443 23.8281 32.0005 23.8281C34.2566 23.8281 36.0856 25.6571 36.0856 27.9132ZM32.0005 19.7431C34.2566 19.7431 36.0856 17.9142 36.0856 15.658C36.0856 13.4019 34.2566 11.5729 32.0005 11.5729C29.7443 11.5729 27.9154 13.4019 27.9154 15.658C27.9154 17.9142 29.7443 19.7431 32.0005 19.7431Z'
            fill='#FF4D4F'
          />
        </svg>

        <Button
          variant='contained'
          disableElevation
          fullWidth
          sx={{
            textTransform: 'none',
            //backgroundColor: '#33C341',
            backgroundColor: '#009FDA',
            '&:hover': {
              //backgroundColor: '#33C341',
              backgroundColor: '#007FBA',
            },
          }}
          onClick={()=>{
            navigate('/documents')
            handleClose()
          }}>
          {/* Перейти в документы */}
          К документам
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalECPSigningError;
