import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { getReferralsRequest } from "../../services/DriverCardService";
import { $api, $file } from "../../api";
import { PDFIcon } from "./assets/PDFIcon";
import { Stack } from "@mui/material";
import { ReferralDateFilter } from "./components/ReferralDateFilte";
import { DriverBirthdateFilter } from "./components/DriverBirthdateFilter";
import { DriverFioFilter } from "./components/DriverFioFilter";

export interface column {
    id: number,
    name?: string,
    sort?: any | false,
    sortMode?: string,
    filter?: any | false,
    value?: any,
    visible?: boolean,
}

const downloadFile = (url: string, filename: string) => {

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
const download = async (url?: string) => {
  console.log(url)
  if (!!url) {
      const fileUrl = await $file.get(url);
      const localUrl = URL.createObjectURL(fileUrl.data)
      downloadFile(localUrl, 'documents.pdf')
  }
}


export const useReferralModel = () => {

    const [referrals, setRefferals] = useState<any[] | null>([])

    const { curPage } = useParams();
    const [page, setPage] = useState<number>(1)
    const [pageQty, setPageQty] = useState(0);
    const [perPage, setPerPage] = useState<number>(50)

    const [filterRequest, setFilterRequest] = useState<Object>({})
    const [sortingRequest, setSortingRequest] = useState<Object>({})

    const getReferralReports = async (query: any) => {
        try {
          const response = await getReferralsRequest(query)
          console.log('MEDREPORTS')
          console.log(response)
          return response
        } catch (e) {
          console.log(e)
        }
    }

    useEffect(() => {
        if (!!curPage) {
            setPage(Number(curPage))
         } else setPage(1);
    }, [curPage])

    useEffect(() => {
      if (page && perPage) {
        const resp = getReferralReports({page: page, per_page: perPage, ...filterRequest, ...sortingRequest})
        resp.then((result) => {
            setRefferals(result?.data.data)
            setPageQty(result?.data.last_page)
        })
      }
    }, [page, perPage, filterRequest, sortingRequest])

    const changePage=(num:any)=>{
        setPage(num)
    }




    const setSortMode = (id: number, mode: string) => {
        setColumns((prev) => prev.map((col) => col.id === id ? {...col, sortMode: mode} : {...col, sortMode: ''} ))
      }

      const [openViewSettings, setOpenViewSettings] = useState<boolean>(false)

      const [columns, setColumns] = useState<column[]>([
          { 
            id: 1,
            name: '№ направления', 
            sort: (cc: number) => {setSortingRequest({orderBy: { id: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(1, cc%2===0 ? 'asc' : 'desc')},
            sortMode: '',
            filter: false,
            value: (row: any) => (row.id),
            visible: true,
          },
          { 
            id: 2,
            name: 'Дата направления', 
            sort: (cc: number) => {setSortingRequest({orderBy: { createdAt: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(2, cc%2===0 ? 'asc' : 'desc')},
            sortMode: '',
            filter: (handleClose: any, setMode: any) => (<ReferralDateFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
            value: (row: any) => (row?.createdAt ? row.createdAt.substring(0,10).split('-').reverse().join('.') : '-'),
            visible: true,
          },
          { 
            id: 3,
            name: 'Участок', 
            sort: false,
            sortMode: '',
            filter: false,
            value: (row: any) => (row.department),
            visible: true,
          },
          {
            id: 4,
            name: 'ФИО',
            sort: (cc: number) => {setSortingRequest({orderBy: { driver: { lastname: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(4, cc%2===0 ? 'asc' : 'desc')},
            sortMode: '',
            filter: (handleClose: any, setMode: any) => (<DriverFioFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
            value: (row: any) => (
                <span>
                  {!!row.driver?.lastname ? `${row.driver?.lastname} ` : <></>}
                  {!!row.driver?.firstname ? `${row.driver?.firstname} ` : <></>}
                  {!!row.driver?.middlename ? `${row.driver?.middlename} ` : <></>}
                </span>
                ),
            visible: true,
          },
          { 
            id: 5,
            name: 'Дата рождения', 
            sort: (cc: number) => {setSortingRequest({orderBy: { driver: { birthdate: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(5, cc%2===0 ? 'asc' : 'desc')},
            sortMode: '',
            filter: (handleClose: any, setMode: any) => (<DriverBirthdateFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
            value: (row: any) => (row?.driver?.birthdate ? row.driver.birthdate.substring(0,10).split('-').reverse().join('.') : '-'),
            visible: true,
          },
          { 
            id: 6,
            name: 'Направление', 
            sort: false,
            sortMode: '',
            filter: false,
            value: (row: any) => (
              <Stack style={{cursor: 'pointer', flexDirection: 'row', alignItems: 'center', gap: '3px'}}
                onClick={()=>{download(row?.referral_url)}} 
              >
                <PDFIcon/><a href='javascript:;' style={{color: '#009FDA'}}>Скачать</a>
              </Stack>
            ),
            visible: true,
          },
    ])


    return {
        referrals,
        page,
        pageQty,
        changePage,

        openViewSettings,
        setOpenViewSettings,
        columns,
        setColumns,
        setFilterRequest,

        filterRequest,
        sortingRequest,
        
        perPage,
        setPerPage,
    }
}