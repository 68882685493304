import { Box } from "@mui/material";
import { keyframes } from "@mui/styled-engine"

export const MedCardLoadingAnimation = () => {

    const spin = keyframes`0%{ transform: rotate(0deg); } 100%{ transform: rotate(360deg); }`
    const circle = {width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#009FDA', position: 'absolute'}

    return (
        <Box sx={{position: 'relative', width: '32px', height: '32px', animation: `${spin} 2s linear infinite`}}>
            <Box sx={[circle, {top:    '0px', left: '11px'}]}/>
            <Box sx={[circle, {bottom: '0px', left: '11px'}]}/>
            <Box sx={[circle, {right:  '0px', top:  '11px'}]}/>
            <Box sx={[circle, {left:   '0px', top:  '11px'}]}/>
        </Box>
    )
}