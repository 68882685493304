import { SwipeableDrawer, Box, Typography, Button, IconButton, Stack } from "@mui/material"
import { buttonStyle } from "../../../../Login/login-styles"
import { drawerBoxStyle, activeColor } from "../../../styles/styles"
import { FormCheckboxes } from "../../FormCheckboxes"
import { FormInputText } from "../../FormInputText"
import { DrawerProps } from "../../../types"
import { useDeclineIdentDrawerFormModel } from "./DeclineIdentDrawerFormModel"
import { setSelectionRange } from "@testing-library/user-event/dist/utils"
import { ReactComponent as CloseIcon } from '../assets/Close.svg'


export const DeclineIdentDrawerForm = (props: DrawerProps) => {

    const model = useDeclineIdentDrawerFormModel(props.onClose);

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            sx={{
                width: '362px',
                height: '100%',
                // backgroundColor:'#EEEEEE',
                '*::-webkit-scrollbar': {
                    width: '24px',
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: '#EEEEEE',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#8E8E8E',
                    border: '6px solid transparent',
                    borderRadius: '10px / 10px',
                    backgroundClip: 'content-box',
                },
            }}
        >
            {/* <Box
                sx={[drawerBoxStyle, {height: '100%' }]}
                role="presentation"
            //padding={3}
            > */}
                <Box sx={{ position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            component={'h1'}
                            sx={{
                                fontSize: '20px',
                                fontWeight: '500',
                            }}
                        >
                            Нарушение требований
                        </Typography>
                        <IconButton onClick={props.onClose} color="secondary" aria-label="add an alarm"
                            sx={{'&.MuiButtonBase-root': {borderRadius: '4px'}, '&:hover': {backgroundColor: '#FFFFFF'}}}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                </Box>
                <Stack sx={{ ...drawerBoxStyle, padding: '0px 24px', height: '100%', overflowY: 'auto', gap: '16px' }}>

                    <FormCheckboxes
                        control={model.control}
                        setValue={model.handleFormValues}
                        name={"checkboxes"}
                        checkboxValues={model.options}
                    />
                    <Box>
                        <FormInputText
                            name={'comment'}
                            control={model.control}
                            label={'Комментарий'}
                            isRequired={model.isTextFieldRequired}
                        />
                        <span style={{
                            color: 'red',
                        }}>
                            {model.isTextFieldRequired ? 'Обязательно укажите причину' : ''}
                        </span>
                    </Box>

                </Stack>
                <Box sx={{ position: 'sticky', bottom: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px' }}>

                    <Button
                        onClick={model.submitHandler}
                        sx={{
                            ...buttonStyle,
                            backgroundColor: activeColor,
                            //marginTop:'24px', 
                            '&:hover': {
                                backgroundColor: '#007DB8',
                                boxShadow: 'none',
                            }
                        }}
                        fullWidth
                        variant="contained"
                        disabled={model.disableSubmit}
                    >
                        Завершить осмотр
                    </Button>

                </Box>

            {/* </Box> */}
        </SwipeableDrawer>
    )
}