

export const tabStyle = {
    textTransform: 'none',
    fontSize: 20,
    fontWeight: 700,
}

export const blueContainedButtonStyle = {
    height: '28px',
    backgroundColor: '#009FDA',
    borderRadius: '2px',
    border:'none',
    padding:'5px, 16px, 5px, 16px',
    color: 'white',
    fontSize:'14px',
    textTransform:'none',
    '&:hover': {
        backgroundColor: '#00739C',
    },
}


export const blueContainedBigButtonStyle = {
    height: '48px',
    backgroundColor: '#009FDA',
    borderRadius: '4px',
    border:'none',
    padding:'5px 16px',
    color: '#FFFFFF',
    fontSize:'20px',
    textTransform:'none',
    '&:hover': {
        backgroundColor: '#00739C',
    },
    "&.Mui-disabled": {
        background: "#8E8E8E",
        color: "#FFFFFF"
    }
}

export const whiteOutlinedButtonStyle =  {
    height: '28px',
    backgroundColor:'#FFFFFF',
    borderRadius: '2px',
    border: '1px solid #D9D9D9',
    padding:'5px, 16px, 5px, 16px',
    color: '#000000',
    fontSize:'14px',
    textTransform:'none',
    boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
}

export const pageTitleStyle={
    fontSize:'30px',
    fontWeight:'500'
   }
export const pageSubtitleStyle={
    fontSize:'20px',
    fontWeight:'500'
}

export const tableHeaderText={
    FontFamily:'Roboto',
    fontSize:'14px',
     fontWeight:'400',
     fontColor:'black'
}

export const textFieldFilledStyle = {
    '.MuiInputBase-root': {
        height: '67px',
    },
    '& .MuiFormLabel-root': {
        pointerEvents: 'none',
        color: '#8E8E8E',
        opacity: 1,
        margin: '4px 0px 0px -1px',
        '&.Mui-focused': {
            color: '#8E8E8E',
            transition: '0.1s ease-out',
            opacity: 0,
            visibility: 'hidden',
        },
    },
    '& .MuiFilledInput-root': {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        border: '1px solid #8E8E8E',
        '& input': {
            margin: '0px 0px -5px -2px',
        },
        '& input::placeholder': {
            color: '#8E8E8E',
            opacity: 1,
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&.Mui-focused': {
            transition: '0.1s ease-out',
            boxShadow: '0px 0px 0px 1px #009FDA inset',
            borderColor: '#009FDA',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            '& input': {
                transition: '0.1s ease-out',
                transform: 'translate(0px, -10px)',
            }
        },
    },
    '& :not(focused)': {
        transition: 'all 0.1s ease-out',
    },
}


export const autocompleteStyle = {
    '& .MuiAutocomplete-popupIndicator': {
        scale: '0.85',
        color: '#787878',
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
        transition: '0.1s ease-out',
        opacity: 0.6,
        transform: 'rotate(-90deg) scale(0.85)',
    },
    '& .MuiAutocomplete-clearIndicator': {
        scale: '0.85',
    },
    '& :not(focused)': {
        transition: 'all 0.1s ease-out',
    },
}

export const popperFromAutocompleteStyle = {
    minWidth: '314px',
    '*::-webkit-scrollbar': {
        width: '0.5em'
    },
    '*::-webkit-scrollbar-track': {
        backgroundColor: '#EEEEEE',
    },
    '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#BFBFBF',
        borderRadius: '2px',
    },
}

export const paperFromAutocompleteStyle = {
    margin: '2px 0px 2px 0px',
    borderRadius: '2px',
    //boxShadow: '2px 2px 2px  rgba(0, 0, 0, 0.2)',
    shadow: '10px',
    '& .MuiAutocomplete-listbox': {
        padding: '3px 0px',
        '& .MuiAutocomplete-option': {
            margin: '0px -4px',
            paddingTop: '4px',
            paddingBottom: '4px',
            '&:hover': {
                backgroundColor: '#E6F7FF',
            },
        },
        "& .MuiAutocomplete-option[aria-disabled='true']": {
            backgroundColor: "none",
            opacity: 1,
            '&.Mui-focused': {
                backgroundColor: '#FFFFFF'
            }
        },
        overflowX: 'hidden',
        overflowY: 'visible', 
    },
}

export const switchVerticalStyle = {
    height: '47px',
    width: '22px',
    padding: '0px',
    margin: '0px 8px 0px 8px ',
    '& .MuiSwitch-track': {
        height: '47px',
        width: '22px',
        borderRadius: 18 / 2,
        backgroundColorL: '#8E8E8E',
        boxSizing: 'border-box',
    },
    '& .MuiSwitch-thumb': {
        margin: '18px 0px 0px -7px',
        //boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 18,
        height: 18,
        borderRadius: 6,
        transition: '0.1s ease-out'
    },
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            transform: 'translateY(-25px)',
            color: '#fff',
        '& + .MuiSwitch-track': {
            backgroundColor: '#009FDA',
            opacity: 1,
        },
        },
    }
}
