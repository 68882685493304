import { Box, Button, IconButton, Stack, SwipeableDrawer, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { activeColor, drawerBoxStyle } from "../../../Meds/styles/styles";
import { ReactComponent as CloseIcon } from "../../../../pages/Meds/modules/Drawers/assets/Close.svg";
import { DatePickerControlled } from "../../components/DatePickerControlled";

import { FormSelect } from "../../../../components/FormSelect";
import { DropzoneArea } from "@pandemicode/material-ui-dropzone";
import { buttonStyle } from "../../../Login/login-styles";
import { useMedDrawerModel } from "../../../Meds/modules/Drawers/DrawerReportModel";
import { DrawerProps } from "../../../Meds/types";
import { DropzoneControlled } from "../../components/DropzoneControlled";
import { createDocument, updateDocument } from "../../DocumentsPageModel";
import { DOC_TYPES, IDocument } from "../../../../types/documentTypes";
import moment from "moment";
import { TextInputControlled } from "../../components/TextInputControlled";
import { useAppDispatch } from "../../../../store/hooks/redux";
import { setMainInfo } from "../../../../slices/CommonInfoSlice";
import { getMedic } from "../../../../modules/PrivateRoute/PrivateRoute";
import Dropzone from "react-dropzone-uploader";
import { createFileRequest } from "../../../../services/fileService";
import { ErrorMessage } from "@hookform/error-message"
import { setDocumentRedacted } from "../../DocumentsSlice";
import { switchVerticalStyle } from "../../../../styles/MaterialCustomStyles";


export const DocumentAddingForm = ({ open, onOpen, onClose, initData, initFiles, documentType }: any) => {

    const dispatch = useAppDispatch();


    const getInputLabel = (type: any) => {
        if (type === DOC_TYPES.EDUCATION_CERIFICATE) return 'Cерийный номер'
        if (type === DOC_TYPES.ACCREDIATAION_CERTIFICATE) return 'Уникальный номер реестровой записи'
    }
    const getIssuedDateLabel = (type: any) => {

        if (type === DOC_TYPES.ACCREDIATAION_CERTIFICATE) return 'Дата аккредитации специалиста'
        if (type === DOC_TYPES.EDUCATION_DIPLOMA) return 'Дата выдачи'
        else return 'Дата начала'
    }



    const getFormDefaultData = (documentType: DOC_TYPES) => {
        switch (documentType) {
            case DOC_TYPES.EDUCATION_CERIFICATE:
                return {
                    registry_number: ' ',
                    validity_period: null,
                    expires_at: moment(' '),
                    issued_at: moment(' '),
                    url: [],
                    isValidityPeriodChecked: false
                }

            case DOC_TYPES.ACCREDIATAION_CERTIFICATE:
                return {
                    registry_number: ' ',
                    issued_at: moment(' '),
                    url: [],
                }

            case DOC_TYPES.EDUCATION_DIPLOMA:
                return {
                    issued_at: moment(' '),
                    url: [],
                }

            case DOC_TYPES.QUALIFICATION_CERTIFICATE:
                return {
                    expires_at: moment(' '),
                    issued_at: moment(' '),
                    isValidityPeriodChecked: false,
                    url: ["https://api-gw01.tetron.cloud/menu-service-api/v1/files/61383ed8-da50-4eb2-88ee-228f366ec242"],
                }
            default: return {}
        }
    }

    const defaultValues: any = getFormDefaultData(documentType)
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        setError,
        errors,
        clearErrors,
        formState,
    } = useMedDrawerModel(defaultValues)

    useEffect(() => {
        console.log('кнопка')
        console.log(isSaveButtonDisabled())
    })

    useEffect(() => {
        console.log('init form files')
        console.log(initFiles)
    }, [initFiles])

    useEffect(() => {
        if (open) {
            console.log('init form data')
            console.log(initData)
            console.log(initData === DOC_TYPES.EDUCATION_DIPLOMA)
            if (!!initData) {
                let defaultInitData = { ...initData, is_replaced:true };
                if (initData.type === DOC_TYPES.EDUCATION_DIPLOMA || initData.type === DOC_TYPES.ACCREDIATAION_CERTIFICATE) {
                    delete defaultInitData.expires_at
                } else defaultInitData.expires_at = moment(initData.expires_at)

                defaultInitData.issued_at = moment(initData.issued_at)
                reset({
                    // ...initData, expires_at: initData.type === DOC_TYPES.EDUCATION_DIPLOMA
                    //     ? undefined
                    //     : moment(initData.expires_at), issued_at: moment(initData.issued_at)
                    ...defaultInitData
                })
            } else {
                console.log(defaultValues)
                reset(defaultValues)
            }
            // if (!!initData) reset({ ...initData, expires_at: moment(initData.expires_at), issued_at: moment(initData.issued_at) })


            console.log(getValues())

        } else {
            reset({})
        }

    }, [open])


    const getFileLink = async (file: any) => {
        let formData = new FormData()
        formData.append('file', file)

        try {
            let link = await createFileRequest(formData);
            console.log(link)
            return link
        } catch (error) {
            console.log('ERROR')
            console.log(error)
        }
    }

    const createLinks = async (urls: any[]) => {
        console.log('urls')
        console.log(urls)
        let links: any = []
        links = urls.map(async (file: any) => {
            const fileLink = getFileLink(file)
            return fileLink
        })
        return await Promise.all(links)
    }

    const isSaveButtonDisabled = () => {

        console.log('isDirty', formState.isDirty)
        console.log('dirtyFields', formState.dirtyFields)
        console.log('defaultValues', defaultValues)
        console.log('initData', initData)
        if (formState.isDirty) {
            if (!!initData) {
                return false
            } else {
                if (defaultValues.isValidityPeriodChecked !== undefined) {
                    return Object.keys(formState.dirtyFields).length < Object.keys(defaultValues).length - 2
                }
                // let fields:any = formState.dirtyFields
                //  let defaultFields = defaultValues
                // delete fields.isValidityPeriodChecked
                // delete defaultFields.isValidityPeriodChecked
                return Object.keys(formState.dirtyFields).length !== Object.keys(defaultValues).length
            }
        } else return true

    }


    const isStringUrls = (data: any[]) => {
        return data.find((element: any) => typeof element === 'string')
    }
    const onSubmit = async (data: IDocument) => {
        console.log(defaultValues)
        console.log('chchchchc')
        console.log('данные формы')
        console.log(data)
        console.log(errors)
        if (data.url && !isStringUrls(data.url)) {
            await createLinks(data.url).then((result: any) => {
                console.log(result)
                data.url = result.map((response: any) => {
                    return response.data.link
                })
            })
        }

        console.log(defaultValues)
        console.log(data)
        // data.expires_at='';
        if (!!data.expires_at) {
            data.expires_at = data.expires_at?.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
        }
        if (!!data.issued_at) {
            data.issued_at = data.issued_at?.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
        }
        console.log(data)
        console.log(initData)
        delete data.document_status
        const mergedData = { ...defaultValues, ...data }
        if (mergedData.isValidityPeriodChecked) {
            delete mergedData.expires_at
            mergedData.validity_period = Number(mergedData.validity_period)
        } else delete mergedData.validity_period

        delete mergedData.isValidityPeriodChecked
        console.log('mergedData')
        console.log(mergedData)
        let response: any = await createDocument({ ...mergedData, type: documentType })
        // ?? await updateDocument({ ...mergedData, type: documentType })
        // let response = await createDocument({ ...data, type: documentType })
        console.log(response)
        if (!!response) {
            dispatch(setDocumentRedacted(documentType))
            dispatch(setMainInfo(await getMedic()))
        }
        reset({})
        onClose()
    }

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={open}
            onClose={
                onClose
            }
            onOpen={onOpen}
            sx={{
                width: '362px',
                //backgroundColor:'#EEEEEE',
            }}
        >
            <Box
                sx={{ ...drawerBoxStyle, backgroundColor: '#EEEEEE', height: '100%' }}
                role="presentation"
            //padding={3}         
            >
                <Box sx={{ position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', }} component={'h1'}>
                            {documentType}
                        </Typography>
                        <IconButton onClick={onClose} color="secondary" aria-label="add an alarm"
                            sx={{ '&.MuiButtonBase-root': { borderRadius: '4px' }, '&:hover': { backgroundColor: '#FFFFFF' }, marginRight: '2px' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ padding: '0px 24px', backgroundColor: '#EEEEEE' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '1.3', color: '#787878', marginBottom: '7px' }}>
                        Поля для обязательного заполнения *
                    </Typography>
                    <Stack gap={'24px'}>

                        {
                            !!defaultValues.issued_at ?
                                // console.log(defaultData.issued_at)

                                <DatePickerControlled
                                    clearErrors={clearErrors}
                                    setError={setError}
                                    rules={{ required: true }}
                                    name={'issued_at'}
                                    control={control}
                                    label={<p>{getIssuedDateLabel(documentType)} <span style={{ color: '#DC4C4C' }}>*</span></p>} />

                                : null
                        }

                        {
                            !!defaultValues.registry_number
                                // ? console.log( defaultData.registry_number)
                                ? <TextInputControlled
                                    name={'registry_number'}
                                    control={control}
                                    label={<p>{getInputLabel(documentType)} <span style={{ color: '#DC4C4C' }}>*</span></p>}
                                    rules={{ required: true, minLength: 4 }}
                                />
                                : null
                        }


                        {
                            !!defaultValues.validity_period
                                || !!defaultValues.expires_at
                                || defaultValues.validity_period === null ?

                                <Box sx={
                                    {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        gap: '24px',
                                    }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextInputControlled
                                            name={'validity_period'}
                                            control={control}
                                            disabled={!getValues('isValidityPeriodChecked')}
                                            label={<p>Срок действия, лет <span style={{ color: '#DC4C4C' }}>*</span></p>}
                                            rules={{ required: getValues('isValidityPeriodChecked') }}
                                            type='number'
                                        />
                                        <Switch
                                            checked={getValues('isValidityPeriodChecked')}
                                            onClick={() => {
                                                let oldData = getValues()
                                                let newData = oldData
                                                newData.isValidityPeriodChecked = !oldData.isValidityPeriodChecked
                                                reset(newData, { keepDirtyValues: true })
                                                // setValue('isValidityPeriodChecked', !getValues('isValidityPeriodChecked'))
                                                console.log(getValues())
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={switchVerticalStyle} />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <DatePickerControlled
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            rules={{ required: !getValues('isValidityPeriodChecked') }}
                                            name={'expires_at'}
                                            control={control}
                                            disabled={getValues('isValidityPeriodChecked')}
                                            label={<p>Дата окончания <span style={{ color: '#DC4C4C' }}>*</span></p>}
                                        />
                                        {<Switch
                                            checked={!getValues('isValidityPeriodChecked')}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onClick={() => {
                                                let oldData = getValues()
                                                let newData = oldData
                                                newData.isValidityPeriodChecked = !oldData.isValidityPeriodChecked
                                                reset(newData, { keepDirtyValues: true })
                                                // setValue('isValidityPeriodChecked', !getValues('isValidityPeriodChecked'))
                                                console.log(getValues())
                                            }}
                                            sx={switchVerticalStyle} />}
                                    </Box>

                                </Box>
                                : null
                        }


                        {/* {
                            !!defaultValues.expires_at ?
                                // console.log(defaultData.expires_at)
                                <Box sx={{display: 'flex', width: '100%', gap: '8px', alignItems: 'center'}}>
                                    <DatePickerControlled
                                        setError={setError}
                                        clearErrors={clearErrors}
                                        rules={{ required: true }} 
                                        name={'expires_at'} 
                                        control={control} 
                                        label={<p>Дата окончания <span style={{color: '#DC4C4C'}}>*</span></p>}
                                    />
                                    {!!defaultValues.validity_period || defaultValues.validity_period === null ? <Switch sx={switchVerticalStyle}/> : null}
                                </Box>
                                : null
                        } */}
                        {
                            !!defaultValues.url && documentType !== DOC_TYPES.QUALIFICATION_CERTIFICATE ?
                                // console.log(defaultData.url)
                                <DropzoneControlled
                                    rules={{ required: true }}
                                    initUrls={initFiles}
                                    setValue={setValue}
                                    name='url'
                                    control={control}></DropzoneControlled>
                                : null
                        }

                    </Stack>

                </Box>

                <Box sx={{ position: 'sticky', bottom: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px' }}>
                    <Stack gap={'12px'}>
                        <Button onClick={handleSubmit(onSubmit)}
                            // disabled={getValues().checkboxes.length==0}
                            // disabled={!!initData ? false : Object.keys(getValues()).length !== Object.keys(defaultValues).length}
                            disabled={isSaveButtonDisabled()}
                            sx={{
                                ...buttonStyle, backgroundColor: activeColor,
                                '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' }, boxShadow: 'none',
                            }}
                            fullWidth variant="contained">Сохранить</Button>
                        <Button onClick={onClose}
                            variant="outlined"
                            sx={
                                {
                                    ...buttonStyle,
                                    border: '2px solid #009FDA',
                                    backgroundColor: '#FFFFFF',
                                    color: activeColor,
                                    '&:hover': {
                                        border: '2px solid #009FDA',
                                        color: activeColor,
                                        backgroundColor: '#F0F7FF',
                                        boxShadow: 'none',
                                    },
                                    boxShadow: 'none',
                                }
                            }
                            fullWidth>Отмена</Button>
                    </Stack>
                </Box>




            </Box>
        </SwipeableDrawer>
    )
}


// export const DocumentAddingForm=({}:any)=>{
//     return(
//         <Box></Box>
//     )
// }