export const SearchIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_15572_28458)">
                <path d="M15.1855 15.1876C15.4905 14.8831 15.4907 14.389 15.186 14.0843L10.7588 9.65713C11.5646 8.61543 12.0005 7.3418 12.0005 6.00218C12.0005 4.39864 11.3746 2.89508 10.243 1.7614C9.11129 0.627727 7.60372 0.00390625 6.00218 0.00390625C4.40064 0.00390625 2.89308 0.629727 1.7614 1.7614C0.627727 2.89308 0.00390625 4.39864 0.00390625 6.00218C0.00390625 7.60372 0.629727 9.11129 1.7614 10.243C2.89308 11.3766 4.39864 12.0005 6.00218 12.0005C7.3418 12.0005 8.61343 11.5646 9.65514 10.7608L14.0833 15.1872C14.3876 15.4914 14.8809 15.4916 15.1855 15.1876ZM9.16928 9.16928C8.32152 10.015 7.19784 10.4809 6.00218 10.4809C4.80653 10.4809 3.68285 10.015 2.83509 9.16928C1.98934 8.32152 1.52347 7.19784 1.52347 6.00218C1.52347 4.80653 1.98934 3.68085 2.83509 2.83509C3.68285 1.98934 4.80653 1.52347 6.00218 1.52347C7.19784 1.52347 8.32352 1.98734 9.16928 2.83509C10.015 3.68285 10.4809 4.80653 10.4809 6.00218C10.4809 7.19784 10.015 8.32352 9.16928 9.16928Z" fill="#3E3E3E"/>
            </g>
            <defs>
                <clipPath id="clip0_15572_28458">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}