import { SxProps } from "@mui/material";

export const styleCard: SxProps = {
    //minWidth: '600px',
    width: '100%',
    height:'400px',
}

export const drawerBoxStyle={
    // height:'100%',
    width:'362px',
    backgroundColor:'#EEEEEE',
    position:'relative'
}
export const activeColor = '#009FDA'
export const optionStyle = {

    "&": {
        color: '#AFAFAF',
        textTransform: 'none',
        justifyContent: 'flex-start',
        height: 'auto',
    },
    "&:hover": {
        cursor: 'pointer',
    },
    "&.Mui-selected": {
        color: 'white',
        backgroundColor: 'blue',
    },
    "&.Mui-selected:hover": {
        color: 'white',
        backgroundColor: 'blue',
    }
}

export const buttonStyle = {
    borderRadius: '4px',
    padding: '5px 16px',
    color: 'white',
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '20px',
    '&:hover': {
        boxShadow: 'none',
        borderColor: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: 'none',
    },
    '&:focus': {
        boxShadow: 'none',
        borderColor: 'none',
    },
    '&:disabled': {
        boxShadow: 'none',
        borderColor: 'none',
        backgroundColor: '#8E8E8E'
    },
    '& .MuiTouchRipple-child': {
        backgroundColor: 'black'
    }
}

export const textFieldOutlineMultilineStyle = {
    backgroundColor:'white',
    borderRadius: '4px',
  
    '&.MuiInputLabel-root.Mui-focused': {
  
      visibility:'hidden !important',
    },

    '& .MuiInputBase-root': {
  
      height: '86px',
      paddingTop: '40px',
      paddingRight: '0px',
      paddingBottom: '0px',
  
      '*::-webkit-scrollbar': {
        width: '10px',
        //height: '10px',
      },
      // '*::-webkit-scrollbar-button': {
      //   //border: '1px solid blue',
      //   backgroundColor: '#8E8E8E',
      //   // //display: 'block',
      //   // border: '1px solid black',
      //   // borderRight: '4px solid black',
      //   // borderLeft: '4px solid black',
  
      //   //borderRadius: '10px/10px 10px 0px 0px',
      //   borderRadius: '4px',
      //   height: '10px',
      // },
      '*::-webkit-scrollbar-track': {
          //backgroundColor: '#EEEEEE',
      },
      '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#8E8E8E',
          border: '1px solid transparent',
          borderRadius: '12px / 12px',
          backgroundClip: 'content-box',
      },
    },
    '& .MuiInputBase-input': {
      
      height: '50px !important',
      marginLeft: '-2px',
      paddingRight: '50px',

      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '18px',

      "&.Mui-disabled": {
        WebkitTextFillColor: "#000000",
      },
    },

    '& .MuiFormLabel-root': {
      pointerEvents: 'none',
      userSelect: 'none',
  
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '16px',
  
      marginTop: '4px',
      marginLeft: '-2px',
  
      '&.Mui-focused': {
        color: '#787878'
      },
      '&.Mui-disabled': {
        color: '#787878'
      },
    },


    '& .MuiOutlinedInput-root': {
        opacity: 1,
        '& fieldset': {
            borderColor: '#8E8E8E',
        },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        opacity: 1,
        '& fieldset': {
            borderColor: '#8E8E8E',
        },
        '&:hover': {
          '& fieldset': {
            border: '1px solid #8E8E8E',
          },
        }
    },

    '& .MuiOutlinedInput-root:hover': {
      opacity: 1,
      '& fieldset': {
        border: '2px solid #A5E5FD',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      opacity: 1,
      '& fieldset': {
        border: '2px solid #009FDA',
      },
    },

  }

  export const textFieldOutlineStyle: SxProps = {
    backgroundColor:'white',
    borderRadius: '4px',
  
    // '& .MuiInputLabel-root .Mui-focused': {
  
    //   visibility:'hidden !important',
    // },

    '& .MuiInputBase-root': {
  
      height: '67px',
      paddingTop: '26px',
      paddingRight: '0px',
      paddingBottom: '0px',
  
    //   '*::-webkit-scrollbar': {
    //     width: '10px',
    //     //height: '10px',
    //   },
    //   '*::-webkit-scrollbar-button': {
    //     //border: '1px solid blue',
    //     backgroundColor: '#8E8E8E',
    //     // //display: 'block',
    //     // border: '1px solid black',
    //     // borderRight: '4px solid black',
    //     // borderLeft: '4px solid black',
  
    //     //borderRadius: '10px/10px 10px 0px 0px',
    //     borderRadius: '4px',
    //     height: '10px',
    //   },
    //   '*::-webkit-scrollbar-track': {
    //       //backgroundColor: '#EEEEEE',
    //   },
    //   '*::-webkit-scrollbar-thumb': {
    //       backgroundColor: '#8E8E8E',
    //       border: '1px solid transparent',
    //       borderRadius: '12px / 12px',
    //       backgroundClip: 'content-box',
    //   },
    // },

    '& .MuiInputBase-input': {

      //height: '50px !important',
      marginLeft: '-2px',
      //paddingRight: '50px',

      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '18px',

      "&.Mui-disabled": {
        WebkitTextFillColor: "#000000",
      }
    },

    '& .MuiFormLabel-root': {
      pointerEvents: 'none',
      userSelect: 'none',
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '16px',
      marginTop: '4px',
      marginLeft: '-2px',
  
      '&.Mui-focused': {
        color: '#787878',
        
      },
      '&.Mui-disabled': {
        color: '#787878'
      },
    },


    '& .MuiOutlinedInput-root': {
        opacity: 1,
        '& fieldset': {
            borderColor: '#8E8E8E',
        },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        opacity: 1,
        '& fieldset': {
            borderColor: '#8E8E8E',
        },
    },

    '&.MuiOutlinedInput-root:hover': {
      opacity: 1,
      '& fieldset': {
        border: '2px solid #A5E5FD',
      },
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      opacity: 1,
      '& fieldset': {
        border: '2px solid #009FDA',
      },
    },

  }}