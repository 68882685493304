import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";

import { ruRU } from '@mui/x-date-pickers/locales';
import { textFieldOutlineStyle } from "../../Meds/styles/styles";
import moment from "moment";
import { Box, TextField, Typography } from "@mui/material";






export const DatePickerControlled = ({
   name,
   disabled,
    control, label, rules, setError, clearErrors }: any) => {




  return (


    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,

      }}

      render={({ field: { onChange, value, ref, }, fieldState: { error, } }) => {

        return (
          <Box sx={{width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker format="DD.MM.YYYY"

                disabled={disabled}
                label={label} value={value}
                onChange={(e) => {
                  if (!!e) {
                    if (name === 'issued_at') {
                      console.log('issued error')
                      if (e.isAfter(moment())) {
                        console.log('issued error')
                        setError('issued_at', { type: 'date', message: 'Дата выдачи не может быть позже текущей' })
                      } else clearErrors('issued_at')
                    } else if (name === 'expires_at') {
                      console.log('expires error')
                      if (e.isBefore(moment())) {
                        console.log('expires error')
                        setError('expires_at', { type: 'date', message: 'Дата окончания не может быть раньше текущей' })
                      } else clearErrors('expires_at')
                    }
                  } else if (name === 'issued_at') {
                    setError('issued_at', { type: 'date', message: 'Дата не может быть пустой' })
                  } else if (name === 'expires_at') {
                    setError('expires_at', { type: 'date', message: 'Дата не может быть пустой' })
                  }


                  onChange(e)
                }}

                slotProps={{ textField: { error: !!error, InputLabelProps: { shrink: false }, variant: 'outlined', } }}



                sx={{
                  ...textFieldOutlineStyle,
                  width: '100%',
                  '& .MuiInputAdornment-root': {
                    marginTop: '-25px',
                    paddingLeft: '30px',

                    marginLeft: '-86px',
                  },

                  '& .MuiInputBase-input': {
                    minHeight: '32px',
                    paddingTop: '18px',

                    marginLeft: '-2px',

                    fontFamily: 'Roboto',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '18px',
                    textTransform: 'lowercase',
                  },
                  '& .MuiFormLabel-root': {
                    pointerEvents: 'none',
                    userSelect: 'none',

                    fontFamily: 'Roboto',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '16px',

                    marginTop: '-4px',
                    marginLeft: '-2px',

                    '&.Mui-focused': {
                      color: '#787878'
                    },
                    '&.Mui-disabled': {
                      color: '#787878'
                    },
                  },

                  '& .MuiIconButton-root': {
                    color: '#8E8E8E',
                    fontSize: '12px'
                  },

                }}

              />

              {!!error && <Box
                sx={{
                  marginTop: 1,
                }}
              >
                <Typography sx={{
                  color: 'red',
                  fontSize: '13px'
                }}>{error.message}</Typography>
              </Box>}
            </LocalizationProvider>
          </Box>

        )
      }}
    />

  );
};