export const IncreasePhotoIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5375_64600)">
                <path d="M13.6103 9.32598C13.3174 9.61887 13.3174 10.0937 13.6103 10.3866C13.9032 10.6795 14.3781 10.6795 14.671 10.3866L13.6103 9.32598ZM21.3192 3.42773C21.3192 3.01352 20.9834 2.67773 20.5692 2.67773H13.8192C13.405 2.67773 13.0692 3.01352 13.0692 3.42773C13.0692 3.84195 13.405 4.17773 13.8192 4.17773H19.8192V10.1777C19.8192 10.5919 20.155 10.9277 20.5692 10.9277C20.9834 10.9277 21.3192 10.5919 21.3192 10.1777V3.42773ZM14.671 10.3866L21.0995 3.95806L20.0389 2.8974L13.6103 9.32598L14.671 10.3866Z" 
                    fill="white"
                />
                <path d="M10.8194 14.2455C11.1123 13.9526 11.1123 13.4777 10.8194 13.1848C10.5265 12.8919 10.0516 12.8919 9.75873 13.1848L10.8194 14.2455ZM2.68192 20.5723C2.68192 20.9865 3.0177 21.3223 3.43192 21.3223L10.1819 21.3223C10.5961 21.3223 10.9319 20.9865 10.9319 20.5723C10.9319 20.1581 10.5961 19.8223 10.1819 19.8223L4.18192 19.8223L4.18192 13.8223C4.18192 13.4081 3.84613 13.0723 3.43192 13.0723C3.0177 13.0723 2.68192 13.4081 2.68192 13.8223L2.68192 20.5723ZM9.75873 13.1848L2.90159 20.0419L3.96225 21.1026L10.8194 14.2455L9.75873 13.1848Z" 
                    fill="white"
                />
            </g>
        </svg>
    )
}