import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {FullfilledData, LoginState} from "./login-types";
import {loginRequest} from "../../services/AuthService";


const initialState: LoginState = {
    isNeedToPasRecovery:false,
    isNeedToReg:false,
    isNeedToAuth:true,
    isAuthInProgress:false,
    isRecoveryComplete:false,
    isRegistrationComplete:false,
    fullfilledData:{
        email:'',
        phone:'',
        password:'',
        currentTab:'email',
    }
}

export const emailLogin= createAsyncThunk(
    'login/emailLog',
    async (thunkAPI)=>{
        const response = await loginRequest("huy", 'her')
        console.log(response)
        return response.data
    }
)

export const logSlice = createSlice({
    name:'log',
    initialState,
    reducers:{
        setReg(state){
            state.isNeedToPasRecovery = false ;
            state.isNeedToAuth=false;
            state.isNeedToReg = true;
        },
        setPasRec(state){
            state.isNeedToPasRecovery = true;
            state.isNeedToReg = false;
            state.isNeedToAuth=false;
        },
        setAuthInProgress(state, action:PayloadAction<boolean>){
            state.isAuthInProgress = action.payload
        },
        setAuth(state){
            state.isNeedToPasRecovery=false;
            state.isNeedToReg=false;
            state.isNeedToAuth=true;
        },
        setRegistrationComplete(state, action:PayloadAction<FullfilledData>){
            state.isRegistrationComplete=true;
            state.fullfilledData = action.payload
        },
        setRecoveryComplete(state, action:PayloadAction<FullfilledData>){
            state.isRecoveryComplete=true;
            state.fullfilledData = action.payload
        }
    },
})

export const {
    setReg,
    setPasRec,
    setAuth,
    setAuthInProgress,
    setRegistrationComplete,
    setRecoveryComplete
} = logSlice.actions

export default logSlice.reducer