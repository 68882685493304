import { Avatar, Box, Chip, Divider, Stack, Typography } from "@mui/material"
import { EducationCertificateCard } from "./modules/cards/educationCertificate/EducationCertificateCard"
import { EducationDiplomaCard } from "./modules/cards/educationDiploma/EducationDiplomaCard"
import { QualificationCertificateCard } from "./modules/cards/qualification/QualificationCertificateCard"
import { AccreditationCertificateCard } from "./modules/cards/accreditation/AccreditationCard"
import { ECPCard } from "./modules/cards/ecp/ECPCard"
import { useDocsModel } from "./DocumentsPageModel"
import { DocumentAddingForm } from "./modules/drawers/DocumentAddingForm"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux"
import { setCurrentDocumentType, setFileLocalUrls, setInitDocumentData } from "./DocumentsSlice"
import { DOC_TYPES } from "../../types/documentTypes"
import { getStatusChip } from "../Reports/ReportPageModel"
import { IReportStatus } from "../Meds/types"
import { GENERAL_STATUSES, getGeneralStatusChip, getGeneralStatusComment } from "./utils"





export const DocumentsPage = () => {

    const { documentsObj } = useDocsModel();

    const dispatch = useAppDispatch();
    const { currentDocumentType, formFiles, fileLocalUrls, redactedDocuments } = useAppSelector(state => state.docReducer)
    const { medic } = useAppSelector(state => state.mainReducer)
    const { isECPFlashLoaded} = useAppSelector(state => state.appReducer)
    // const { documents, firstname, middlename, lastname, documents_general_status } = medic
    const [isOpen, setOpen] = useState(false)

    return (<Box sx={{
        //minHeight: '100vh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: 'white'

        '*::-webkit-scrollbar': {
            width: '12px',
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#BDBDBD',
            border: '2px solid transparent',
            //borderRadius: '10px / 4px',
            borderRadius: '4px/4px',
            backgroundClip: 'content-box',
          },
          '*::-webkit-scrollbar-corner': {
            backgroundColor: '#EEEEEE',
            borderRadius: '2px',
          },
    }}>
        {/* <Divider sx={{color:'#EEEEEE'}}></Divider>
        <Box sx={{ backgroundColor: 'white', padding: '16px 24px' }}>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '24px', fontWeight: '500', lineHeight: '32px' }}>Мои документы</Typography>
        </Box> */}

        <Box sx={{ backgroundColor: 'white', padding: '16px 24px', position: 'sticky', top: '65px', zIndex: '9', boxShadow: '0px -1px 0px 0px #EEEEEE' }}>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '24px', fontWeight: '500', lineHeight: '32px' }}>Мои документы</Typography>
        </Box>

        <Box sx={{
            backgroundColor: "white", margin: '16px', marginBottom: '-15px', padding: '16px 24px',  position: 'relative',
            borderRadius: '4px 4px 0px 0px', 
            display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Avatar sx={{ boxShadow: '0 0 0 6px #009FDA', margin: '0px 7px 0px 7px', width: '56px', height: '56px' }}></Avatar>
                    {!!medic
                        ? <Stack sx={{
                            marginLeft: 2,
                            gap: '12px'
                        }}>
                            <Typography fontSize={'20px'} fontWeight={500}>{medic.firstname} {medic.middlename} {medic.lastname}</Typography>
                            <Box>
                                {getGeneralStatusChip(medic.documents_general_status, !!isECPFlashLoaded)}
                            </Box>

                        </Stack>
                        : null}
                </Box>
                {
                    !!medic
                        ? getGeneralStatusComment(medic.documents_general_status, !!isECPFlashLoaded)
                        : <></>
                }
        </Box>

        <Box
            sx={{
                position: 'relative',
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                //minHeight: '900px',
                backgroundColor: 'white',
                padding: '16px 12px 16px 24px',
                margin:'16px',

                overflow: 'auto', height: '692px'
            }}>

            <Box
                sx={{ display: 'flex', width: '100%' }}
            >
                <Stack gap={'24px'} width={'100%'}>
                    <EducationCertificateCard isRedacted ={redactedDocuments.find((item:any)=> item ===DOC_TYPES.EDUCATION_CERIFICATE)} cardDocument={documentsObj.edCertificateDocument} />
                    <EducationDiplomaCard isRedacted ={redactedDocuments.find((item:any)=> item ===DOC_TYPES.EDUCATION_DIPLOMA)} cardDocument={documentsObj.edDiplomaDocument} />
                </Stack>
                <Stack marginLeft={'24px'} gap={'24px'} width={'100%'}>
                    <QualificationCertificateCard isRedacted ={redactedDocuments.find((item:any)=> item ===DOC_TYPES.QUALIFICATION_CERTIFICATE)} cardDocument={documentsObj.qualCertificateDocument} />
                    <ECPCard />
                    <AccreditationCertificateCard isRedacted ={redactedDocuments.find((item:any)=> item ===DOC_TYPES.ACCREDIATAION_CERTIFICATE)} cardDocument={documentsObj.accrCertificateDocument} />
                </Stack>
                <DocumentAddingForm
                    initFiles={fileLocalUrls}
                    onOpen={() => dispatch(setCurrentDocumentType(currentDocumentType))}
                    initData={!!medic ? medic.documents?.find((document: any) => document.type === currentDocumentType) : undefined}
                    open={!!currentDocumentType}
                    documentType={currentDocumentType}
                    onClose={() => {
                        dispatch(setCurrentDocumentType(null))
     
                        dispatch(setInitDocumentData([]))
                        dispatch(setFileLocalUrls([]))
                    }} />
            </Box>

        </Box>

    </Box>)
}