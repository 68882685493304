import { combineReducers, configureStore} from "@reduxjs/toolkit";
import logReducer from '../pages/Login/LoginSlice';
import mainReducer from '../slices/CommonInfoSlice';
import examReducer from '../pages/Meds/MedExamsSlice';
import appReducer from '../slices/AppSlice';
import docReducer from '../pages/Documents/DocumentsSlice'
import reportsReducer from '../pages/Reports/ReportsSlice'
const rootReducer = combineReducers({
    logReducer,
    mainReducer,
    examReducer,
    appReducer,
    docReducer,
    reportsReducer
})

export const setupStore = ()=>{
    return configureStore({
        reducer: rootReducer
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']