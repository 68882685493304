import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { DOC_TYPES } from "../../types/documentTypes";








const initialState:any = {
  formFiles:[],
  fileLocalUrls:[],
  currentDocumentType:null,
  initDocumentData:[],
  redactedDocuments:[]
};




export const docSlice = createSlice({
  name: 'doc',
  initialState,
  reducers: {
    setFormFiles(state, action: PayloadAction<any[]>) {
      state.files = action.payload;
    },
    setFileLocalUrls(state, action: PayloadAction<any[]>) {
      state.fileLocalUrls = action.payload;
    },
    setCurrentDocumentType(state, action: PayloadAction<DOC_TYPES | null>) {
      state.currentDocumentType = action.payload;
    },
    setInitDocumentData(state, action: PayloadAction<any>) {
      state.initDocumentData = action.payload;
    },
    setDocumentRedacted(state, action: PayloadAction<DOC_TYPES>){
        state.redactedDocuments.push(action.payload)
    }
  },
})

export const {
    setFormFiles,
    setFileLocalUrls,
    setCurrentDocumentType,
    setInitDocumentData,
    setDocumentRedacted
} = docSlice.actions

export default docSlice.reducer