export const DownloadIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8704_69205)">
            <path d="M5 16H14M5.5 9.21739L9.5 12.8696M9.5 12.8696L13.5 9.21739M9.5 12.8696V4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_8704_69205">
                <rect width="20" height="20" fill="currentColor"/>
            </clipPath>
        </defs>
        </svg>
    )
}