// import { Button, createMuiTheme } from "@mui/material";
import { DropzoneArea } from "@pandemicode/material-ui-dropzone"
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import classes from './dropzone.module.css'

import './Dropzone.css'
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { $api, $file, } from "../../../api";
import { setFileLocalUrls } from "../DocumentsSlice";
import { Box } from "@mui/material";
import axios from "axios";

export const DropzoneControlled = ({ name, control, setValue, initUrls, rules }: any) => {


    const [currentFiles, setCurrentFiles] = useState<any>([])
    const [initFiles, setInitFiles] = useState()

    registerPlugin(
        FilePondPluginImageExifOrientation,
        FilePondPluginImagePreview,
        FilePondPluginFileValidateType
    );

    const isArraysEqual = (array1: any[], array2: any[]) => {
        return JSON.stringify(array1) === JSON.stringify(array2);
    }


    const blobToFile = (theBlob: Blob, fileName: string): File => {
        const b: any = theBlob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;

        //Cast to a File() type
        return theBlob as File;
    }

    const getFiles = async (urls: string[]) => {
        const files = await Promise.all(urls.map((url: string) => {
            return axios.get(url)
        }))
        console.log('async files')
        console.log(files)
        return files;
    }



    useEffect(() => {

        if (!!initUrls) {
            console.log('initUrls', initUrls)
         
            setCurrentFiles(initUrls)
            const filesResult = getFiles(initUrls);
            filesResult.then((responses: any) => {
                console.log('ответы с инит дата')
                console.log(responses)
                const files = responses.map((response: any) => {
                    const file = new File([response.data], 'photo.png')
                    return file
                })
                console.log(files)
                setInitFiles(files)
            }, (error:any)=>{
                console.log('ошибка')
                console.log(error)
            })
        }
    }, [initUrls])


    useEffect(() => {

        console.log('currentFiles')
        console.log(currentFiles)
        const fileObjects = currentFiles.map((item: any) => {
            console.log(item.fileType)
            const f = item.file;
            const fileObj = new File([f], 'photo.png')
            return fileObj
        })
        console.log(initFiles)
        if (initFiles) {
            console.log('инит дата', initFiles)
            console.log('добавленные файлы', fileObjects)
            console.log('равны ли они', isArraysEqual(initFiles, fileObjects))
            if (!isArraysEqual(initFiles, fileObjects)) {
                setValue(name, fileObjects, { shouldDirty: true });
            }
        }
    }, [name, currentFiles, setValue]);

    const handleChangeStatus = ({ meta, file }: any, status: any) => {
        console.log(status, meta, file);
        if (status == 'done') setCurrentFiles((state: any) => state = state.concat([file]))
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: any }) => (
                <Box sx={{
                    backgroundColor: '#D9D9D9',
                    borderRadius: '4px',
                    padding: '12px 11px 12px 11px',
                }}>
                    <FilePond

                        files={currentFiles}
                        allowReorder={true}
                        allowMultiple={true}

                        onupdatefiles={(files) => {
                            console.log('файлы при апдейте')
                            console.log(files)
                            setCurrentFiles(files)
                        }}
                        labelIdle='<div>
                        <p class="file-uploader-text" >Перетащите файлы или выберите на компьютере</p>
                        <div class="filepond--label-action">Добавить файл</div>
                        </div>'
                    />
                </Box>


            )}
        />
    )
}
