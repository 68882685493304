import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useFullScreenHandle } from "react-full-screen"
import { OnProgressProps } from "react-player/base"

export interface  useVideoPlayerProps {
    url?: string
    autoPlay?: boolean 
    muted?: boolean
    flipping?: boolean
    style?: React.CSSProperties

    start?: number
    end?: number
}

export const useVideoPlayer = ({url, autoPlay, muted, flipping, style, start = 0, end = 0 }:useVideoPlayerProps) => {
    
    const playerRef = useRef<any>()
    const screenHandle = useFullScreenHandle()

    const [playing, setPlaying] = useState<boolean>(autoPlay || false)
    const [isReady, setIsReady] = useState<boolean>(false)
    const [loadedSeconds, setLoadedSeconds] = useState<number>(0)
    const [playedSeconds, setPlayedSeconds] = useState<number>(0)    
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [mouseOver, setMouseOver] = useState<boolean>(false)

    const reportChange = useCallback((state:any, handle:any)=>{ 
        setFullScreen(state) 
    }, [screenHandle])

    const onReady = useCallback(()=>{
        if (!isReady) {
            playerRef.current.seekTo(start/1000)
            setIsReady(true)
        }
    },[isReady])

    useEffect(()=>{
        if (end && (playedSeconds >= Math.round(end/1000))) { setPlaying(false) }
    },[playedSeconds])

    const handlePlay = () => { 
        if (
            end && (playedSeconds >= Math.round(end/1000)) ||
            playedSeconds === Math.round(playerRef.current?.getDuration())
        ) {
            playerRef.current.seekTo(start/1000); 
            setPlayedSeconds(start/1000); 
            setPlaying(true)
        } else { 
            setPlaying((prev)=>!prev) 
        }
    }
        
    const handlePlayArea = (e:React.MouseEvent<HTMLInputElement>) => { 
        switch (e.detail) {case 1:handlePlay();break;case 2:fullScreen?screenHandle.exit():screenHandle.enter();break;}  
    }

    const handleChangeProgress = (event:OnProgressProps) => { 
        setLoadedSeconds(Math.round(event.loadedSeconds)); setPlayedSeconds(Math.round(event.playedSeconds)) 
    }
    const handleMoveProgress = (event: Event, newValue: number | number[]) => { 
        playerRef.current.seekTo(Math.round(Number(newValue)+start/1000))
        setPlayedSeconds(Math.round(Number(newValue)+start/1000))
    }

    return {
        url,
        muted,
        flipping,
        style,
        start,
        end,

        playerRef,
        screenHandle,
        playing,
        fullScreen,
        loadedSeconds,
        playedSeconds,
        duration: Math.round(playerRef.current?.getDuration()),

        handlePlay,
        handlePlayArea,
        setPlaying,
        setMouseOver,
        handleChangeProgress,
        handleMoveProgress,

        reportChange,
        onReady,
    }
}