export enum TimerStatus 
{
    RUNNING  = 'RUNNING',
    PAUSED   = 'PAUSED',
    FINISHED = 'FINISHED',

    OFFLINE = 'OFFLINE'
}

export interface TimeUnit {
    id?:     number | null
    start?:  string | null
    finish?: string | null

    difference?: number | null
    created_at?: string | null
    updated_at?: string | null
}

export interface Timer 
{
    id?:           number      | null
    status?:       TimerStatus | null
    sum_of_units?: number      | null
    units?:        TimeUnit[]  | null
    employeeId?:   number      | null
}