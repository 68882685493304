import { useEffect } from 'react';
import { useRegModel } from "./RegistrationModel";
import { RegPhoneMailForm } from "./Components/RegPhoneMailForm/RegPhoneMailForm";
import { RegPinCodeForm } from "./Components/RegPinCodeForm/RegPinCodeForm";
import { RegCreatePasswordForm } from "./Components/RegCreatePasswordForm/RegCreatePasswordForm";
import { RegStages } from "./reg-types";
import { Loader } from "../../../../ui/Loader/Loader";


export const Registration = ({ renderAuth }: any) => {
    const model = useRegModel()

    useEffect(() => {
        model.isRegistrationComplete
            && renderAuth({
                email: model.mail,
                phone: model.phone,
                password: model.password,
                currentTab:model.currentTabValue
            })
    }, [model.isRegistrationComplete])

    if (model.isLoading) return <Loader />

    switch (model.currentRegStage) {
        case RegStages.initReg: {
            return <RegPhoneMailForm
                mail={model.mail}
                phone={model.phone}
                handleTabChange={model.handleTabChange}
                handlePhoneChange={model.handlePhoneChange}
                handleMailChange={model.handleMailChange}
                currentTabValue={model.currentTabValue}
                error={model.LoginErrors.SEND_CONFIRM_ERROR}
                toggleErrors={model.toggleErrors}
                isErrorHidden={model.isErrorHidden}
                action={() => {
                    model.sendConfirmation()
                }} />
            break;
        }
        case RegStages.pinCode: {
            return <RegPinCodeForm
                type={model.currentTabValue}
                pinCode={model.pinCode}
                handlePinChange={model.handlePinChange}
                error={model.LoginErrors.PIN_CODE_ERROR}
                isValid={model.isFormValid}
                mail={model.mail}
                phone={model.phone}
                timeLeft={model.time}
                timer={model.timer}
                toggleErrors={model.toggleErrors}
                isErrorHidden={model.isErrorHidden}
                repeatConfirmationSend={model.sendConfirmation}
                isConfirmationSended={model.isConfirmationSended}
                isTimerStarted={model.isTimerStarted}
                isAgreementChecked ={model.isAgreementChecked}
                handleAgreementChecked = {model.handleAgreementChecked}
                action={() => {
                    model.register()
                }
                } />
            break;
        }
        case RegStages.passwordCreation: {
            return <RegCreatePasswordForm
                isErrorHidden={model.isErrorHidden}
                toggleErrors={model.toggleErrors}
                handlePasswordChange={model.handlePasswordChange}
                handleConfirmChange={model.handlePasswordConfirmChange}
                togglePasswordHide={model.togglePasswordHide}
                toggleConfirmHide={model.toggleConfirmHide}
                showPass={model.showPass}
                showConf={model.showConf}
                isValid={model.isFormValid}
                error={model.LoginErrors.PASSWORD_CONFIRM_ERROR}
                action={() => {
                    model.createPassword()
                }
                } />
            break;
        }
        default: {
            return null
            break;
        }
    }


}

export default Registration;