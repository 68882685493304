import { SvgIcon } from "@mui/material"

export const ReportsIcon = (props: any) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_5055_38361" 
            fill="currentColor"
        >
            <path d="M17 5.8C17.5523 5.8 18 6.24771 18 6.8V18C18 18.5523 17.5523 19 17 19H8.61538C8.0631 19 7.61538 18.5523 7.61538 18V16.6H6.30769C5.75541 16.6 5.30769 16.1523 5.30769 15.6V14.2H4C3.44771 14.2 3 13.7523 3 13.2V2C3 1.44771 3.44772 1 4 1H9.1638C9.43581 1 9.69609 1.11081 9.88463 1.30689L11.8972 3.4H14.6923C15.2446 3.4 15.6923 3.84772 15.6923 4.4V5.8H17ZM9.92308 4.6H11.4105L9.92308 3.05312V4.6ZM12.2308 13V5.8H8.76923V2.2H4.15385V13H12.2308ZM14.5385 15.4V4.6H13.3846V13.2C13.3846 13.7523 12.9369 14.2 12.3846 14.2H6.46154V15.4H14.5385ZM16.8552 7H15.6923V15.6C15.6923 16.1523 15.2446 16.6 14.6923 16.6H8.76923V17.8H16.8552V7Z"
            />
        </mask>
            <path d="M17 5.8C17.5523 5.8 18 6.24771 18 6.8V18C18 18.5523 17.5523 19 17 19H8.61538C8.0631 19 7.61538 18.5523 7.61538 18V16.6H6.30769C5.75541 16.6 5.30769 16.1523 5.30769 15.6V14.2H4C3.44771 14.2 3 13.7523 3 13.2V2C3 1.44771 3.44772 1 4 1H9.1638C9.43581 1 9.69609 1.11081 9.88463 1.30689L11.8972 3.4H14.6923C15.2446 3.4 15.6923 3.84772 15.6923 4.4V5.8H17ZM9.92308 4.6H11.4105L9.92308 3.05312V4.6ZM12.2308 13V5.8H8.76923V2.2H4.15385V13H12.2308ZM14.5385 15.4V4.6H13.3846V13.2C13.3846 13.7523 12.9369 14.2 12.3846 14.2H6.46154V15.4H14.5385ZM16.8552 7H15.6923V15.6C15.6923 16.1523 15.2446 16.6 14.6923 16.6H8.76923V17.8H16.8552V7Z" 
            fill="currentColor" stroke="currentColor" stroke-width="3" 
            mask="url(#path-1-inside-1_5055_38361)"
        />
        </SvgIcon>
    )
}