import { SvgIcon } from "@mui/material"

export const DocumentsIcon = () => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 5.5V14C16 14.5523 15.5523 15 15 15H6.5C5.94772 15 5.5 14.5523 5.5 14V3C5.5 2.44772 5.94772 2 6.5 2L12 2M16 5.5L12 2M16 5.5H12V2M16 5.5L13 4.5L12 2M8.5 10L10 11.5L13 8.5" 
                stroke="currentColor" 
                stroke-width="1.5" 
                stroke-linecap="round"
                fillOpacity="0"
            />
            <path d="M3 4.5V15.5C3 16.6046 3.89543 17.5 5 17.5H13" 
                stroke="currentColor" 
                stroke-width="1.5" 
                stroke-linecap="round"
                fillOpacity="0"
            />
        </SvgIcon>
    )
} 