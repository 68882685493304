import { Modal, Box, Button, Typography, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { IModalProps } from "./props"
import { useAppSelector } from "../../../../store/hooks/redux"
import { DOC_TYPES, IDocument } from "../../../../types/documentTypes"
// import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export const ModalDocumentNotFound = ({ open, handleClose }: IModalProps) => {

  const { medic } = useAppSelector(state => state.mainReducer)
  const { documents } = medic

  const navigate = useNavigate()
  return (
    <Modal
      open={open}
      sx={{
        backgroundColor: 'rgb(0,0,0, 0.1)',
        zIndex: 999999,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'background.paper',

          width: '488px',
          //maxHeight: '320px',
          borderRadius: '4px',
          border: 'none',
          padding: '24px',

          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '24px',

          '& h2': {
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '28px',
            color: '#3E3E3E',
            //whiteSpace: 'nowrap',
          },

          // '& p': {
          //   fontSize: '16px',
          //   fontWeight: '400',
          //   color: '#3E3E3E',
          //   lineHeight: '28px',
          // },
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <h2>
            Добро пожаловать в систему
          </h2>

          <Typography sx={{lineHeight: '1.3'}}>
            Чтобы приступить к выполнению работы, вам
            необходимо внести все необходимые документы или
            актуализировать их, список документов указан ниже:
          </Typography>

        </Box>


        <Stack gap={2} padding={'0px 24px'}>
        <Box sx={{ display: 'flex', /*maxWidth: '332px'*/ }}>
            {documents.find((document: IDocument) => document.type === DOC_TYPES.EDUCATION_CERIFICATE)
              ? <CheckCircleOutlinedIcon color="success" />
              : <CancelOutlinedIcon color="error" />}
            <Typography sx={{ fontSize: '16px', fontWeight: '500', marginLeft: 1 }}>Удостоверение основного образования</Typography>
          </Box>
          <Box sx={{ display: 'flex', maxWidth: '332px' }}>
            {documents.find((document: IDocument) => document.type === DOC_TYPES.EDUCATION_DIPLOMA)
              ? <CheckCircleOutlinedIcon color="success" />
              : <CancelOutlinedIcon color="error" />}
            <Typography sx={{ fontSize: '16px', fontWeight: '500', marginLeft: 1 }}>Диплом основного образования</Typography>
          </Box>
          <Box sx={{ display: 'flex', maxWidth: '332px' }}>
            {documents.find((document: IDocument) => document.type === DOC_TYPES.QUALIFICATION_CERTIFICATE)
              ? <CheckCircleOutlinedIcon color="success" />
              : <CancelOutlinedIcon color="error" />}
            <Typography sx={{ fontSize: '16px', fontWeight: '500', marginLeft: 1 }}>Удостоверение о повышении квалификации</Typography>
          </Box>
          <Box sx={{ display: 'flex', maxWidth: '332px' }}>
            {documents.find((document: IDocument) => document.type === DOC_TYPES.ACCREDIATAION_CERTIFICATE)
              ? <CheckCircleOutlinedIcon color="success" />
              : <CancelOutlinedIcon color="error" />}
            <Typography sx={{ fontSize: '16px', fontWeight: '500', marginLeft: 1 }}>Аккредитация специалиста</Typography>
          </Box>
        </Stack>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '1.3' }}>
            Для внесения документов, необходимо добавить их из нужной
            карточки или воспользоваться инструкцией по добавлению,
            нажав кнопку «Как добавить?»
          </Typography>
        </Box>

        <Button
          fullWidth
          onClick={() => {
            navigate('/documents')
            handleClose()
          }}
          sx={{
            // width: '279px',
            height: '36px',
            backgroundColor: '#009FDA',
            padding: '10px 12px',
            borderRadius: '4px',
            color: '#FFFFFF',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '16px',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: '#007FBA',
            }
          }}
        >
          К документам
        </Button>



      </Box>
    </Modal>
  )
}