// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.form_error__7BI7S{\n    margin-top: 24px;\n    margin-bottom: 24px;\n    color:red;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/Login/modules/Recovery/components/RecPhoneMailForm/form.module.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB","sourcesContent":["\n\n.error{\n    margin-top: 24px;\n    margin-bottom: 24px;\n    color:red;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "form_error__7BI7S"
};
export default ___CSS_LOADER_EXPORT___;
