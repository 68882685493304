export const NetworkStableIcon: React.FC = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3131 3.52152L3.35355 18.4811C3.03857 18.7961 3.26165 19.3346 3.70711 19.3346H18.6667C18.9428 19.3346 19.1667 19.1108 19.1667 18.8346V3.87508C19.1667 3.42962 18.6281 3.20654 18.3131 3.52152Z" stroke="#3E3E3E" stroke-width="1.5"/>
            <path d="M19 19V3L3 19H19Z" fill="#3E3E3E"/>
            <circle cx="19" cy="19" r="8" fill="#33C341"/>
            <path d="M17.75 21.75L23.25 16.25" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M17.7504 21.7494L15.0117 19.0117" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    )
}