import { Box, Chip, SxProps, Typography } from "@mui/material";
import { DOC_GENERAL_STATUSES, DOC_STATUSES } from "../../../types/documentTypes";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useAppSelector } from "../../../store/hooks/redux";

export const getDocumentStatusChip = (status: DOC_STATUSES | string | undefined) => {

    const chipStyle = {
        '&.MuiChip-root': {
            borderRadius: '4px',
            border: '1px solid',
            maxHeight: '22px',

            fontFamily: 'Roboto',
            fintWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
        },
        '& .MuiChip-label': {
            padding: '2px 8px',
        }
    }

    switch (status) {
        case DOC_STATUSES.CHECKED:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#EFFFEE', borderColor: '#33C341', color: '#33C341' }} variant="outlined" label={DOC_STATUSES.CHECKED} />
        case DOC_STATUSES.CHECKING:
            return <Chip sx={{ ...chipStyle, background: '#EAF8FF', borderColor: '#009FDA', color: '#009FDA' }} variant="outlined" label={DOC_STATUSES.CHECKING} />
        case DOC_STATUSES.ERROR:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={'Ошибка данных'/*DOC_STATUSES.ERROR*/} />
        case DOC_STATUSES.EXPIRED:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFEAEA', borderColor: '#FF4D4F', color: '#FF4D4F' }} variant="outlined" label={DOC_STATUSES.EXPIRED} />
        case DOC_STATUSES.EXPIRES:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={DOC_STATUSES.EXPIRES} />
        case DOC_STATUSES.NEW:
            return <Chip sx={{ ...chipStyle, background: '#F4EBFF', borderColor: '#9747FF', color: '#9747FF' }} variant="outlined" label={DOC_STATUSES.NEW} />
        case DOC_STATUSES.MISSED:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFEAEA', borderColor: '#FF4D4F', color: '#FF4D4F' }} variant="outlined" label={DOC_STATUSES.MISSED} />
        default:
            return <Chip color='default' sx={{ ...chipStyle }} variant="outlined" label={status} />
    }



}

export enum GENERAL_STATUSES {
    PROCESSING = 'В обработке',
    ACCEPTED = 'Допущен',
    NOT_ACCEPTED = 'Не допущен',
    ERROR = 'Ошибка в данных'
}


export const getGeneralStatusChip = (status: DOC_GENERAL_STATUSES | GENERAL_STATUSES | string | undefined | null,
    isUserValid: boolean) => {

    const chipStyle = {
        '&.MuiChip-root': {
            borderRadius: '4px',
            border: '1px solid',
            maxHeight: '22px',

            fontFamily: 'Roboto',
            fintWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
        },
        '& .MuiChip-label': {
            padding: '2px 8px',
        }
    }

    if (isUserValid) {
        switch (status) {

            case GENERAL_STATUSES.ACCEPTED:
                return <Chip sx={{ ...chipStyle, backgroundColor: '#EFFFEE', borderColor: '#33C341', color: '#33C341' }} variant="outlined" label={GENERAL_STATUSES.ACCEPTED} />
            case GENERAL_STATUSES.PROCESSING:
                return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={GENERAL_STATUSES.PROCESSING} />
            case GENERAL_STATUSES.NOT_ACCEPTED:
                return <Chip sx={{ ...chipStyle, backgroundColor: '#FFEAEA', borderColor: '#FF4D4F', color: '#FF4D4F' }} variant="outlined" label={GENERAL_STATUSES.NOT_ACCEPTED} />

            default:
                return <></>//<Chip color='default' sx={{ ...chipStyle }} variant="outlined" label={status} />
        }
    }
    //  else if (status = GENERAL_STATUSES.PROCESSING) {
    //     return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={GENERAL_STATUSES.PROCESSING} />

    // }
     else {
        return <Chip sx={{ ...chipStyle, backgroundColor: '#FFEAEA', borderColor: '#FF4D4F', color: '#FF4D4F' }} variant="outlined" label={GENERAL_STATUSES.NOT_ACCEPTED} />
    }


}

export const getRusYearLabel = (year?: number | null) => {
    if (year !== null && year !== undefined) {
        const str = year.toString()
        if (str.length > 1 && str[str.length - 2] === '1') {
            return 'лет'
        } else {
            const lastnum = Number(str[str.length - 1])
            if (lastnum === 1) {
                return 'год'
            } else if (lastnum >= 2 && lastnum <= 4) {
                return 'года'
            } else if (lastnum === 0 || lastnum >= 5 && lastnum <= 9) {
                return 'лет'
            }
        }
    } else {
        return ''
    }
}

export const getGeneralStatusComment = (status: DOC_GENERAL_STATUSES | GENERAL_STATUSES | string | undefined | null,
    isUserValid: boolean) => {
    const textStyle: SxProps = {
        fontWeight: 500,
        fontSize: '14px',
        marginLeft: '8px',
        maxWidth: '363px',
        lineHeight: '1.3'
    }
    const boxStyle: SxProps = {
        backgroundColor: '#EEEEEE', //backgroundColor: 'white',
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '4px'
    }

    if (isUserValid) {
        switch (status) {
            case GENERAL_STATUSES.NOT_ACCEPTED:
                return <Box sx={boxStyle}>
                    <ErrorOutlineOutlinedIcon sx={{ color: "#FF4D4F" }} />
                    <Typography
                        sx={{ ...textStyle, maxWidth: '454px' }}
                    >
                        Исправьте ошибки в указанных документах и они автоматически отправятся на проверку старшему медику
                    </Typography>
                </Box>
            case GENERAL_STATUSES.PROCESSING:
                return <Box sx={boxStyle}>
                    <CheckCircleOutlineOutlinedIcon color="success" />
                    <Typography
                        sx={textStyle}
                    >
                        Все документы отправлены на проверку старшему медику, ожидайте изменения статуса вашего профиля
                    </Typography>
                </Box>
            case GENERAL_STATUSES.ACCEPTED:
                return <Box sx={boxStyle}>
                    <CheckCircleOutlineOutlinedIcon color="success" />
                    <Typography
                        sx={textStyle}
                    >
                        Документы проверены, можете приступать к исполнению обязанностей медика
                    </Typography>
                </Box>
            case GENERAL_STATUSES.ERROR:
                return <Box sx={boxStyle}>
                    <ErrorOutlineOutlinedIcon sx={{ color: "#FF4D4F" }} />
                    <Typography
                        sx={{ ...textStyle, maxWidth: '454px' }}
                    >
                        Исправьте ошибки в указанных документах и они автоматически отправятся на проверку старшему медику
                    </Typography>
                </Box>
            default:
                return <></>
        }

    }
    //  else if (status == GENERAL_STATUSES.PROCESSING) {
    //     return <Box sx={boxStyle}>
    //         <CheckCircleOutlineOutlinedIcon color="success" />
    //         <Typography
    //             sx={textStyle}
    //         >
    //             Все документы отправлены на проверку старшему медику, ожидайте изменения статуса вашего профиля
    //         </Typography>
    //     </Box>
    // }
     else {
        return (
            <Box sx={boxStyle}>
                <ErrorOutlineOutlinedIcon sx={{ color: 'red' }} />
                <Typography sx={textStyle}>
                    Добавьте все документы и они автоматически отправятся на проверку старшему медику
                </Typography>
            </Box>
        )
    }


}