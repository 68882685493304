
interface ENV {
  NODE_ENV: string | undefined;
  PUBLIC_URI: string | undefined;
  REACT_APP_ORGANISATIONS_API_URL: string | undefined;
  REACT_APP_COMPANY_API_URL:string | undefined;
}

interface Config {
    NODE_ENV: string ;
    PUBLIC_URI: string;
    REACT_APP_ORGANISATIONS_API_URL: string;
    REACT_APP_COMPANY_API_URL:string;
}

const getConfig = (): ENV => {
  return {
    NODE_ENV: process.env.NODE_ENV,
    PUBLIC_URI: process.env.PUBLIC_URI,
    REACT_APP_ORGANISATIONS_API_URL: process.env.REACT_APP_ORGANISATIONS_API_URL,
    REACT_APP_COMPANY_API_URL: process.env.REACT_APP_COMPANY_API_URL
  };
};

const getSanitzedConfig = (config: ENV): Config => {
  for (const [key, value] of Object.entries(config)) {
    if (value === undefined) {
        console.log(`Missing key ${key} in .env`);
    }
  }
  return config as Config;
};

const config = getConfig();

const sanitizedConfig = getSanitzedConfig(config);


export default sanitizedConfig;

