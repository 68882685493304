import {$api} from "../api";


export const restoreRequest=(type:string, password:string, confirm:string, value:string, code:string)=>{
    return $api.post('/v1/web/passwords/restore',{type, password, confirm, value, code})
}


export const sendPasConfirmationRequest=(type:string,  value:string, )=>{
    return $api.post('/v1/web/passwords/send-confirmation',{type, value})
}

export const validateCodeRequest=(type:string,  value:string, code:string)=>{
    return $api.post('/v1/web/passwords/validate-code',{type, value, code})
}