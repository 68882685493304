import { useEffect, useState } from "react"
import { useModal } from "../layout/components/modals/useModal"
import { useAppDispatch } from "../../store/hooks/redux";
import { ModalNames, ModalType, setModal } from "../../slices/AppSlice";
import { ModalDocumentError } from "../layout/components/modals/ErrorDocsModal";
import { ExpiredDocsModal } from "../layout/components/modals/ExpiredDocsModal";
import { ExpiresDocsModal } from "../layout/components/modals/ExpiresDocsModal";
import { ModalEDSNotFound } from "../layout/components/modals/ModalEDSNotFound";
import { ModalDocumentProcessing } from "../layout/components/modals/ModalDocumentProcessing";
import { ModalDocumentNotFound } from "../layout/components/modals/NotAddedDocsModal";
import ModalSigningEDS from "../layout/components/modals/ModalSigningEDS";
import { SuccessModal } from "../layout/components/modals/SuccessModal";
import ModalECPSigningError from "../layout/components/modals/ModalECPSigningError";
import { SuccessIdentModal } from "../layout/components/modals/SuccessIdentModal";
import { ConclusionModal } from "../layout/components/modals/CertificateChooseModal";
import { ModalCheckingECP } from "../layout/components/modals/CheckingECP";



export const ModalContainer = () => {
    const dispatch = useAppDispatch();
    const { currentModal, modalQueue, removeModal } = useModal()

    const [modal, setInnerModal] = useState<ModalType>(null)
    const [isNext, setNext] = useState(true)
    console.log('isNext')
    console.log(isNext)

    useEffect(() => {

        if (isNext) {
            if (modalQueue.length > 0) {
                setNext(state => state = false)
                dispatch(setModal(modalQueue[modalQueue.length - 1]))
            }
            else {
                setNext(state => state = true)
                dispatch(setModal(null))
            }

        }


    }, [modalQueue])


    // useEffect(() => {
    //     console.log("isNext")
    //     console.log(isNext)
    // }, [isNext])

    // useEffect(() => {
    //     console.log('current modal')
    //     console.log(currentModal)
    //     if (isNext) {
    //         setInnerModal(state => state = currentModal)
    //     }

    // }, [currentModal])

    const getCurrentModalComponent = (modal: ModalType) => {
        console.log('her')
        console.log(modal)
        switch (modal) {
            case ModalNames.CHECKING_DOCUMENTS:
                return <ModalDocumentProcessing
                    handleClose={() => {
                        setNext(state => state = true)
                        removeModal(ModalNames.CHECKING_DOCUMENTS)
                    }}
                    open={currentModal === ModalNames.CHECKING_DOCUMENTS} />
            case ModalNames.ECP:
                return <ModalEDSNotFound
                    handleClose={() => {
                        setNext(state => state = true)
                        removeModal(ModalNames.ECP)
                    }}
                    open={currentModal === ModalNames.ECP} />
            case ModalNames.ERROR_DOCUMENTS:
                return <ModalDocumentError
                    handleClose={() => {
                        removeModal(ModalNames.ERROR_DOCUMENTS)
                        setNext(state => state = true)
                    }}
                    open={currentModal === ModalNames.ERROR_DOCUMENTS} />
            case ModalNames.EXPIRED_DOCUMENTS:
                return <ExpiredDocsModal
                    handleClose={() => {
                        removeModal(ModalNames.EXPIRED_DOCUMENTS)
                        setNext(state => state = true)
                    }}
                    open={currentModal === ModalNames.EXPIRED_DOCUMENTS} />
            case ModalNames.EXPIRES_DOCUMENTS:
                return <ExpiresDocsModal
                    handleClose={() => {
                        removeModal(ModalNames.EXPIRES_DOCUMENTS)
                        setNext(state => state = true)
                    }}
                    open={currentModal === ModalNames.EXPIRES_DOCUMENTS} />
            case ModalNames.NOT_ADDED_DOCUMENTS:
                return <ModalDocumentNotFound
                    handleClose={() => {
                        setNext(state => state = true)
                        removeModal(ModalNames.NOT_ADDED_DOCUMENTS)
                    }}
                    open={currentModal === ModalNames.NOT_ADDED_DOCUMENTS} />
            case ModalNames.ECP_SIGNING:
                return <ModalSigningEDS
                    handleClose={() => {
                        setNext(state => state = true)
                        removeModal(ModalNames.ECP_SIGNING)
                    }}
                    open={currentModal === ModalNames.ECP_SIGNING} />

            case ModalNames.ECP_ERROR:
                return <ModalECPSigningError handleClose={() => {
                    setNext(state => state = true)
                    removeModal(ModalNames.ECP_ERROR)
                }} open={currentModal === ModalNames.ECP_ERROR} />
            case ModalNames.SUCCESS:
                return <SuccessModal handleClose={() => {
                    setNext(state => state = true)
                    removeModal(ModalNames.SUCCESS)
                }} open={currentModal === ModalNames.SUCCESS} />
            case ModalNames.SUCCESSIDENT:
                return <SuccessIdentModal handleClose={() => {
                    setNext(state => state = true)
                    removeModal(ModalNames.SUCCESSIDENT)
                }} open={currentModal === ModalNames.SUCCESSIDENT} />
            case ModalNames.CERTIFICATES:
                return <ConclusionModal handleClose={() => {
                    setNext(state => state = true)
                    removeModal(ModalNames.CERTIFICATES)
                }} open={currentModal === ModalNames.CERTIFICATES} />
            case ModalNames.CHECKING_ECP:
                return <ModalCheckingECP
                    handleClose={() => {
                        setNext(state => state = true)
                        removeModal(ModalNames.CHECKING_ECP)
                    }}
                    open={currentModal === ModalNames.CHECKING_ECP} />
            default: return
        }

    }

    return (
        <>
            {getCurrentModalComponent(currentModal)}
        </>
    )

}