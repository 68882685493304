import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { alpha } from "@mui/material";
import { textFieldOutlineStyle } from "../../Meds/styles/styles";

export const TextInputControlled = ({ name, control, label, disabled, rules, type }: any) => {


    //  const inputStyle = {
    //     width: 314,
    //     color: '#8E8E8E',
    //     borderRadius: '4px',
    //     '& .MuiFilledInput-root': {
    //         height: '67px',
    //         overflow: 'hidden',
    //         borderRadius: '4px',
    //         backgroundColor: 'white',
    //         border: '1px solid #8E8E8E',
    //         color: '#000',
    //         fontSize: '16px',
    //         fontWeight: '400',
    //         transition: 'box-shadow linear 0.1s, border 0.1s ease-out',

    //         '& .MuiAutocomplete-popupIndicator': {
    //             color: '#787878',
    //             transform: 'rotate(-90deg)',
    //         },

    //         '&:hover': {
    //             boxShadow: `${alpha('#009FDA', 1)} 0 0 0 1px`,
    //             borderColor: '#009FDA',
    //             backgroundColor: 'white',
    //         },
    //         '&.Mui-focused': {
    //             boxShadow: `${alpha('#009FDA', 1)} 0 0 0 1px`,
    //             borderColor: '#009FDA',
    //             backgroundColor: 'white',
    //             '& input': {
    //                 transform: 'translate(0px, -9px)',
    //                 transition: '0.1s',
    //             },
    //             '& .MuiAutocomplete-popupIndicatorOpen': {
    //                 color: '#00000040',
    //                 transform: 'rotate(-180deg) scale(0.85)',
    //             },
    //         },
    //         '& input::placeholder': {
    //             backgroundColor: 'none',
    //             color: '#8e8e8e',
    //             opacity: 1,
    //         },
    //     },
    //     '& .MuiFormLabel-root': {
    //         color: '#8e8e8e',
    //         opacity: 1,
    //         marginLeft: '1px',
    //         '&.Mui-focused': {
    //             display: 'none',
    //         },
    //     },
    //     '& .Mui-focused': {
    //         backgroundColor: '#E6F7FF',
    //     },
    //     '& :not(focused)': {
    //         transition: 'all 0.1s ease-out',
    //     },
    // };


  return (
    <Controller
      name={name}
      control={control ? control : null}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
        sx={{...textFieldOutlineStyle, 
          '& .MuiFormLabel-root': {
            pointerEvents: 'none',
            userSelect: 'none',
        
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px',
        
            marginTop: '4px',
            marginLeft: '-2px',
        
            '&.Mui-focused': {
              color: '#787878'
            },
            '&.Mui-disabled': {
              color: '#787878'
            },
          },
        }}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          disabled={disabled}
          type={type ? type : 'text'}
          label={label}
          variant='outlined'   
          InputLabelProps={{shrink: false}}
        />
      )}
    />
  );
};
