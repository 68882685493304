export const ServerLostIcon: React.FC = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.75" y="15.75" width="16.5" height="5.5" rx="1.25" stroke="#3E3E3E" stroke-width="1.5"/>
            <circle cx="6.17188" cy="18.5" r="1" fill="#3E3E3E"/>
            <mask id="path-3-inside-1_14571_69455" fill="white">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.95801 13.9832C3.73893 13.7936 2 12.0267 2 9.875C2 7.64998 3.85949 5.83642 6.18653 5.753C6.86678 3.58284 8.98806 2 11.4996 2C14.0111 2 16.1324 3.58283 16.8126 5.75298C19.1399 5.83613 20.9997 7.6498 20.9997 9.875C20.9997 12.0268 19.2605 13.7939 17.0412 13.9832V14H16.6456H6.3541H5.95801V13.9832Z"/>
            </mask>
            <path d="M5.95801 13.9832H7.45801V12.6058L6.08569 12.4886L5.95801 13.9832ZM6.18653 5.753L6.24027 7.25204L7.30053 7.21403L7.61786 6.20166L6.18653 5.753ZM16.8126 5.75298L15.3813 6.20164L15.6987 7.21414L16.7591 7.25202L16.8126 5.75298ZM17.0412 13.9832L16.9137 12.4886L15.5412 12.6057V13.9832H17.0412ZM17.0412 14V15.5H18.5412V14H17.0412ZM5.95801 14H4.45801V15.5H5.95801V14ZM6.08569 12.4886C4.56799 12.3589 3.5 11.1749 3.5 9.875H0.5C0.5 12.8784 2.90987 15.2282 5.83033 15.4777L6.08569 12.4886ZM3.5 9.875C3.5 8.53184 4.64381 7.30927 6.24027 7.25204L6.13279 4.25396C3.07516 4.36357 0.5 6.76813 0.5 9.875H3.5ZM7.61786 6.20166C8.09392 4.68292 9.61617 3.5 11.4996 3.5V0.5C8.35995 0.5 5.63964 2.48276 4.7552 5.30434L7.61786 6.20166ZM11.4996 3.5C13.383 3.5 14.9052 4.68291 15.3813 6.20164L18.2439 5.30432C17.3595 2.48275 14.6392 0.5 11.4996 0.5V3.5ZM16.7591 7.25202C18.3557 7.30907 19.4997 8.53173 19.4997 9.875H22.4997C22.4997 6.76787 19.9241 4.36319 16.8662 4.25394L16.7591 7.25202ZM19.4997 9.875C19.4997 11.175 18.4315 12.3591 16.9137 12.4886L17.1687 15.4778C20.0895 15.2286 22.4997 12.8787 22.4997 9.875H19.4997ZM15.5412 13.9832V14H18.5412V13.9832H15.5412ZM17.0412 12.5H16.6456V15.5H17.0412V12.5ZM16.6456 12.5H6.3541V15.5H16.6456V12.5ZM6.3541 12.5H5.95801V15.5H6.3541V12.5ZM7.45801 14V13.9832H4.45801V14H7.45801Z" fill="#3E3E3E" mask="url(#path-3-inside-1_14571_69455)"/>
            <circle cx="19" cy="19" r="8" fill="#DC4C4C"/>
            <path d="M16 16L22 22M22 16L16 22" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    )
}