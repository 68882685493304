import { SvgIcon } from "@mui/material"

export const PauseIcon = (props: any) => {
    return (
        <SvgIcon width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.99805 1.00391C3.99805 0.451622 3.55033 0.00390625 2.99805 0.00390625H0.998047C0.445762 0.00390625 -0.00195312 0.451622 -0.00195312 1.00391V15.0039C-0.00195312 15.5562 0.445762 16.0039 0.998047 16.0039H2.99805C3.55033 16.0039 3.99805 15.5562 3.99805 15.0039V1.00391ZM7.99805 15.0039C7.99805 15.5562 8.44576 16.0039 8.99805 16.0039H10.998C11.5503 16.0039 11.998 15.5562 11.998 15.0039V1.00391C11.998 0.451622 11.5503 0.00390625 10.998 0.00390625H8.99805C8.44576 0.00390625 7.99805 0.451622 7.99805 1.00391V15.0039Z" 
                fill="white"
            />
        </SvgIcon>
    )
}