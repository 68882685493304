import { SvgIcon } from "@mui/material"

export const PlayIcon = (props: any) => {
    return (
        <SvgIcon width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12.6895 8.15882C13.3019 8.55246 13.3019 9.44754 12.6895 9.84118L1.53881 17.0095C0.873293 17.4373 -0.00195312 16.9595 -0.00195312 16.1683L-0.00195312 1.83167C-0.00195312 1.0405 0.873293 0.562658 1.53881 0.990487L12.6895 8.15882Z" 
                fill="white"
            />
        </SvgIcon>

    )
}