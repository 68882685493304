import ReactPlayer from "react-player"
import { videoControls, videoPlayer, videoWrapper, onFlipping } from "./styles"
import { Box, Button, Slider, Stack } from "@mui/material"
import { PlayButton } from "./components/PlayButton"
import { PlayButtonArea } from "./components/PlayButtonArea"
import { useVideoPlayer, useVideoPlayerProps } from "./VideoPlayerModel"
import { SeekSlider } from "./components/SeekSlider"
import { FullScreenButton } from "./components/FullScreenButton"
import { FullScreen } from "react-full-screen"

export const VideoPlayer = (props: useVideoPlayerProps) => {

    const {
        url, muted, flipping, style, start, end,
        playerRef, screenHandle, playing, fullScreen, loadedSeconds, playedSeconds, duration,
        handlePlay, handlePlayArea, setPlaying, setMouseOver, handleChangeProgress, handleMoveProgress, reportChange, onReady
    } = useVideoPlayer(props)

    return !url?null:(
        <Box 
            sx={{...videoWrapper, ...style}} 
            onMouseOver={()=>{setMouseOver(true)}} onMouseLeave={()=>{setMouseOver(false)}}
        >

            <FullScreen handle={screenHandle} onChange={reportChange}>
                
                <ReactPlayer
                    ref={playerRef}
                    controls={false}
                    style={{ ...videoPlayer, ...(flipping?onFlipping:{}) }}
                    width={'100%'} height={'100%'}

                    url={[{src: url, type: 'video/mp4'}]}
                    playsinline={true} type='video/mp4'
                    
                    playing={playing}
                    muted={muted}
                    //playbackRate={3}
                    
                    onReady={onReady}
                    onPlay={()=>setPlaying(true)}
                    onPause={()=>setPlaying(false)}
                    onProgress={handleChangeProgress}
                />

                <PlayButtonArea playing={playing} onClick={handlePlayArea}/>

                <Stack sx={videoControls}>
                    <PlayButton playing={playing} onClick={handlePlay}/>
                    <SeekSlider loadedSeconds={loadedSeconds} playedSeconds={playedSeconds} handleMoveProgress={handleMoveProgress} 
                        start={start/1000} end={end/1000} duration={duration}
                    />
                    <FullScreenButton fullScreen={fullScreen} handle={screenHandle}/>
                </Stack>

            </FullScreen>

        </Box>
    )
}