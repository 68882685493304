import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import { IconButton } from '@mui/material';
import { playerButton, icon } from '../styles';

interface FullScreenButtonProps {
    fullScreen: boolean
    handle: any
}

export const FullScreenButton = ({fullScreen, handle}: FullScreenButtonProps) => {
    return (
        <IconButton 
            sx={playerButton}
            onClick={()=>{fullScreen?handle.exit():handle.enter()}}
            //disableRipple
        >
            {fullScreen?<FullscreenExitRoundedIcon sx={icon}/>:<FullscreenRoundedIcon sx={icon}/>}
        </IconButton> 
    )
}