import { useEffect, useRef, useState } from "react"
import { Connection, ConnectionAgent, NetworkMessage, ServerMessage } from "./types"
import sanitizedConfig from "../../Config"


export const useConnectionIndicator = () => {

    const [networkConnection, setNetworkConnection] = useState<Connection>('STABLE')
    const [serverConnection, setServerConnection] = useState<Connection>('STABLE')
    //const [lastNetworkStatus, setLastNetworkStatus] = useState<Connection>('STABLE')
    //const [lastServerStatus, setLastServerStatus] = useState<Connection>('STABLE')

    const [networkTooltipOpen, setNetworkTooltipOpen] = useState<boolean>(false)
    const [serverTooltipOpen, setServerTooltipOpen] = useState<boolean>(false)

    const [queue, setQueue] = useState<ConnectionAgent[]>([])
    
    const pushUnit = (unit: ConnectionAgent) => { setQueue((prev)=>[...prev, unit] ) }

    const popUnit = (fromPriority?: boolean) => {
        let index = queue.length-1
        if (fromPriority) {let priority = 0; for (let i = queue.length-1; i >= 0; i--) { if (queue[i] > priority) { priority = queue[i]; index = i } } }
        const newQueue = [...queue]; newQueue.splice(index, 1)
        setQueue(newQueue)
        return queue[index]
    }

    const [next, setNext] = useState<boolean>(true)

    const handleOpenNetworkMessage = () => {setNetworkTooltipOpen(true); setNext(false)}
    const handleOpenServerMessage = () => {setServerTooltipOpen(true); setNext(false)}
    const handleCloseNetworkMessage = () => {setNetworkTooltipOpen(false); setNext(true)}
    const handleCloseServerMessage = () => {setServerTooltipOpen(false); setNext(true)}

    useEffect(()=>{
        if (queue.length > 0 && next) {
            switch (popUnit(true)) {
                case ConnectionAgent.NETWORK: 
                    handleOpenNetworkMessage()
                    break
                case ConnectionAgent.SERVER: 
                    handleOpenServerMessage()
                    break
            }
        }
    },[queue, next])

    useEffect(()=>{
        if (NetworkMessage[networkConnection] !== null /*&& (networkConnection !== lastNetworkStatus)*/) { 
            pushUnit(ConnectionAgent.NETWORK); //setLastNetworkStatus(networkConnection)
        } else { handleCloseNetworkMessage(); setQueue((prev)=>prev.filter((e)=>e===ConnectionAgent.SERVER)) }
        if (ServerMessage[serverConnection] !== null /*&& (serverConnection !== lastServerStatus)*/) { 
            pushUnit(ConnectionAgent.SERVER); //setLastServerStatus(serverConnection)
        } else { handleCloseServerMessage(); setQueue((prev)=>prev.filter((e)=>e===ConnectionAgent.NETWORK))}
    },[networkConnection, serverConnection])


    
    const intervalRef = useRef<any>()

    const testServerConnection = async () => {
        try {
            const response = await fetch(`${sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL}/v1/third-party/ciforka-adin`)
            if (response.status < 500) { setServerConnection('STABLE') } 
            else { setServerConnection('LOST'); clearInterval(intervalRef.current) }
        } catch (e) {}
    }

    useEffect(()=>{(async()=>{
        const connection = (navigator as any).connection
        const unstable = 1.5

        const updateNetworkInfo = () => {
            console.log(`(${connection.effectiveType}) ${connection.downlink} Мбит/с`)
            if (connection.downlink === 0 || connection.rtt === 0) { setNetworkConnection('LOST') }
            else if (connection.downlink < unstable) { setNetworkConnection('UNSTABLE') } 
            else { setNetworkConnection('STABLE') }
        }
        const setOnline = async () => { 
            if (connection) { updateNetworkInfo() } else { setNetworkConnection('STABLE') }
            await testServerConnection()
            intervalRef.current = setInterval(async()=>{ await testServerConnection() }, 10_000)
        }
        const setOffline = () => { 
            clearInterval(intervalRef.current)
            setNetworkConnection('LOST')
            setServerConnection('LOST')
        }

        setOnline()
        if (connection) { connection.addEventListener('change', updateNetworkInfo) }
        window.addEventListener('online', setOnline)
        window.addEventListener('offline', setOffline)
        
        return () => {
            clearInterval(intervalRef.current);
            if (connection) { connection.removeEventListener('change', updateNetworkInfo) }
            window.removeEventListener('online', setOnline)
            window.removeEventListener('offline', setOffline)
        }
    })()},[])



    return {
        networkConnection,
        serverConnection,

        networkTooltipOpen,
        setNetworkTooltipOpen,
        serverTooltipOpen,
        setServerTooltipOpen,

        handleOpenNetworkMessage,
        handleOpenServerMessage,
        handleCloseNetworkMessage,
        handleCloseServerMessage,
    }
}