
export enum LoginErrors{
    AUTH_ERROR= 'Неверные Логин или пароль',
    PIN_CODE_ERROR = 'Вы ввели неверный код. Повторите попытку',
    PASSWORD_CONFIRM_ERROR = 'Пароли не совпадают',
    SEND_CONFIRM_ERROR = 'Пользователь с таким телефоном или почтой уже существует',
    REC_SEND_CONFIRM_ERROR = 'Такого пользователся не существует'
}

export type TabValues =  'email' |'phone'
