import { createAttachedSignature, createDetachedSignature, getCertificate, getUserCertificates, createHash } from 'crypto-pro';
import { forEach } from 'lodash';



export const getAvailableCertificates = async () => {
  console.log('получаем сертификаты')
  console.log(window)  
  const certificates = await getUserCertificates();
  console.log('сертификаты')
  console.log(certificates)
  let certificatesWithPrivateKey: any[] = [];
  if (!!certificates) {
    certificatesWithPrivateKey = certificates.map(async (item) => {
      try {     
        const currentCertificate = await getCertificate(item.thumbprint);
        const privateKey = await currentCertificate?.getCadesProp('FindPrivateKey')
        console.log('приватный ключ')
        console.log(await privateKey())
        return item
      } catch (error: any) {
        console.log(error.message)
      }
    })
    console.log('доступные сертификаты')
    console.log(certificatesWithPrivateKey)
  }
  return Promise.all(certificatesWithPrivateKey).then((result:any)=>{
    console.log('результат сертификаты')
      return result.filter((item: any) => !!item)
  })
}



export const checkECPPrivateKey = async () => {

try {
  const availableCertificates = await getAvailableCertificates()
  console.log('чекаем ключ')
  if(availableCertificates.length>0){
    return true
  } else return false  
} catch (error) {
  console.log(error)
  return false
}


}



export interface ICertificate {
  value: string,
  owner?: string,
  active_from: string,
  active_to: string
}


export interface ISignObject {
  signature?: string,
  certificate: ICertificate
}

export const getUserCertificate = async () => {
  const certificates = await getUserCertificates();
  const thumbprint = certificates[1].thumbprint;
  const activeFrom = certificates[1].validFrom;
  const activeTo = certificates[1].validTo;
  const currentCertificate = certificates.length > 0 ? await getCertificate(certificates[1].thumbprint) : undefined
  const ownerInfo = await currentCertificate?.getOwnerInfo();
  const certificateObj: ICertificate = {
    value: thumbprint,
    owner: !!ownerInfo ? `${ownerInfo[0].description} ${ownerInfo[1].description}` : undefined,
    active_from: activeFrom,
    active_to: activeTo,
  }
  return certificateObj
}


export const createSign = async (thumbprint: string, message: string | any) => {

  try {
    const hash = await createHash(message);
    const signature = await createDetachedSignature(thumbprint, hash)
    console.log('подпись')
    console.log(signature)
    return signature
  } catch (error: any) {
    console.log(error.message)
    return null
  }

}

