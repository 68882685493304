
import classes from "./pinCode.module.css";
import ReactCodeInput from "react-code-input";
import React, { FC } from "react";
import { RecPinCodeProps, RecStages } from "../../rec-types";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";


export const RecPinCodeForm: FC<RecPinCodeProps> = (
    {
        pinCode,
        handlePinChange, action,
        mail,
        phone,
        type,
        error,
        isValid,
        timer,
        timeLeft,
        toggleErrors,
        isErrorHidden,
        repeatConfirmationSend,
        isConfirmationSended,
        isTimerStarted
    }) => {
        console.log('rec pin code')
        console.log(isErrorHidden)
    return (
        <div className={classes.main}>
            <h2>Восстановление пароля</h2>
            {
                type === 'email'
                    ? <Typography sx={{
                        marginTop: '30px',
                        marginBottom: '36px',
                    }}>Введите код из сообщения. Сообщение с кодом направлено на электронную почту {mail}</Typography>
                    :
                    <Typography
                        sx={{
                            marginTop: '30px',
                            marginBottom: '36px',
                        }}
                    >Введите код из сообщения. Сообщение с кодом направлено на номер {phone}</Typography>
            }

            <ReactCodeInput onChange={handlePinChange} value={pinCode} name={"pincode"} inputMode={'numeric'} fields={4} />
            <div className={classes.linkCont}>
                {isConfirmationSended
                    ? isTimerStarted
                        ? <Typography sx={{
                            marginTop: '30px',
                            marginBottom: '36px',
                            color:'#009FDA',
                        }}>Запросить код повторно через {timeLeft}</Typography>
                        : <Typography
                            sx={{
                                marginTop: '30px',
                                marginBottom: '36px',
                                cursor:'pointer',
                                color:'#009FDA',
                            }}
                            onClick={() => { repeatConfirmationSend() }}
                        >Запросить код повторно</Typography>
                    : null
                }
                {
                    !!isErrorHidden
                        ? null
                        : <p onClick={() => { toggleErrors() }} className={classes.error}>{error}</p>
                }
            </div>


            <Button
                fullWidth={true}

                sx={{
                    color: 'white',
                    textTransform: 'none',
                    fontSize: 16,
                    fontWeight: 700,
                    height: 50,
                    backgroundColor: '#1890FF',
                    // marginTop: '24px'
                }}
                onClick={() => { action() }}
                variant='contained'>Подтвердить</Button>

        </div>
    )
}